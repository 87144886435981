import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigation } from 'expo-router'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import useAutoSave from '../../../hooks/useAutoSave'
import OrganizationService from '../../../services/organizationService'
import UserService from '../../../services/userService'
import trpc from '../../../utils/trpc'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import OrganizationHeaderRight from '../OrganizationHeaderRight'
import File from './File'

const headerRight = () => <OrganizationHeaderRight />

const organizationFormSchema = z.object({
  name: z.string({ required_error: 'Name is required' }),
  microchipInfo: z.string().optional().nullable(),
  pdfFooter: z.string().optional().nullable(),
  nextHeartwormPreventionDueMinAgeWeeks: z.coerce
    .number()
    .int()
    .optional()
    .nullable(),
})

type OrganizationForm = z.infer<typeof organizationFormSchema>

interface Props {
  disabled: boolean
}

const OrganizationInfoCard = ({ disabled }: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const { currentOrganization, refreshOrganization, organizationQueryData } =
    OrganizationService.useCurrentOrganization()

  const { user } = UserService.useCurrentUser()

  const organizationMutation = trpc.organization.update.useMutation()

  useEffect(() => {
    navigation.setOptions({
      headerRight,
    })
  }, [navigation])

  const defaultValues = useMemo(() => {
    return {
      name: currentOrganization?.name || '',
      microchipInfo: organizationQueryData?.microchipInfo || '',
      pdfFooter: organizationQueryData?.pdfFooter || '',
      nextHeartwormPreventionDueMinAgeWeeks:
        organizationQueryData?.nextHeartwormPreventionDueMinAgeWeeks || null,
    }
  }, [
    currentOrganization?.name,
    organizationQueryData?.microchipInfo,
    organizationQueryData?.pdfFooter,
    organizationQueryData?.nextHeartwormPreventionDueMinAgeWeeks,
  ])

  const form = useForm<OrganizationForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(organizationFormSchema),
  })

  const { control, handleSubmit, formState, reset } = form

  const onSave = (data: OrganizationForm) => {
    if (!currentOrganization) {
      return null
    }

    organizationMutation.mutate(
      {
        organizationId: currentOrganization.id,
        name: data.name,
        microchipInfo: data.microchipInfo,
        pdfFooter: data.pdfFooter,
        nextHeartwormPreventionDueMinAgeWeeks:
          data.nextHeartwormPreventionDueMinAgeWeeks,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: handleSubmit(onSave),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  if (!user) {
    return null
  }

  const onUploadComplete = (
    fileIds: string[],
    fileType: 'logo' | 'qrCode' | 'adoptionContract'
  ) => {
    if (!currentOrganization) {
      return
    }

    organizationMutation.mutate(
      {
        name: currentOrganization.name,
        organizationId: currentOrganization.id,
        logoId: fileType === 'logo' ? fileIds[0] : undefined,
        qrCodeId: fileType === 'qrCode' ? fileIds[0] : undefined,
        adoptionContractId:
          fileType === 'adoptionContract' ? fileIds[0] : undefined,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  const onDelete = () => {
    if (!currentOrganization) {
      return
    }

    organizationMutation.mutate(
      {
        name: currentOrganization.name,
        organizationId: currentOrganization.id,
        logoId: currentOrganization.logo?.id || null,
        qrCodeId: currentOrganization.qrCode?.id || null,
        adoptionContractId: currentOrganization.adoptionContract?.id || null,
      },
      {
        onSuccess: () => {
          refreshOrganization()
        },
      }
    )
  }

  if (!currentOrganization) {
    return null
  }

  return (
    <Card style={styles.root} title="Organization Settings">
      <View style={styles.contentContainer}>
        <View style={styles.mainSection}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle} variant="titleMedium">
              Basic Info
            </Text>
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName="name"
              helperText="The name of your rescue organization"
              label="Name"
              required
            />
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName="microchipInfo"
              helperText="Information about your organization's microchipping process and requirements"
              label="Microchip Info"
              multiline
              required={false}
            />
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName="pdfFooter"
              helperText="Custom footer text to appear on generated PDF documents"
              label="PDF Footer"
              multiline
              required={false}
            />
            <FormFieldText
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName="nextHeartwormPreventionDueMinAgeWeeks"
              helperText="The minimum age (in weeks) at which a foster animal should start receiving heartworm prevention medication"
              keyboardType="numeric"
              label="Minimum Age for Heartworm Prevention (weeks)"
              required={false}
            />
            <View style={styles.fileContainer}>
              <Text style={styles.fileDescription}>
                Organization logo displayed across the app
              </Text>
              <File
                allowedFileTypes={['image/jpeg', 'image/png']}
                fileId={currentOrganization?.logo?.id || ''}
                fileName={currentOrganization?.logo?.name}
                mimeType={currentOrganization?.logo?.mimeType}
                name="Logo"
                onDelete={() => {
                  currentOrganization.logo = null
                  onDelete()
                }}
                onUploadComplete={(fileIds) =>
                  onUploadComplete(fileIds, 'logo')
                }
              />
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle} variant="titleMedium">
              Documents
            </Text>
            <View style={styles.documentsContainer}>
              <View style={styles.fileContainer}>
                <Text style={styles.fileDescription}>
                  Standard adoption contract for new pet parents
                </Text>
                <File
                  allowedFileTypes={['application/pdf']}
                  fileId={currentOrganization?.adoptionContract?.id || ''}
                  fileName={currentOrganization?.adoptionContract?.name}
                  mimeType={currentOrganization?.adoptionContract?.mimeType}
                  name="Adoption Contract"
                  onDelete={() => {
                    currentOrganization.adoptionContract = null
                    onDelete()
                  }}
                  onUploadComplete={(fileIds) =>
                    onUploadComplete(fileIds, 'adoptionContract')
                  }
                />
              </View>
              <View style={styles.fileContainer}>
                <Text style={styles.fileDescription}>
                  QR code for quick access to organization info
                </Text>
                <File
                  allowedFileTypes={['image/jpeg', 'image/png']}
                  fileId={currentOrganization?.qrCode?.id || ''}
                  fileName={currentOrganization?.qrCode?.name}
                  mimeType={currentOrganization?.qrCode?.mimeType}
                  name="QR Code"
                  onDelete={() => {
                    currentOrganization.qrCode = null
                    onDelete()
                  }}
                  onUploadComplete={(fileIds) =>
                    onUploadComplete(fileIds, 'qrCode')
                  }
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Card>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  contentContainer: {
    flexDirection: {
      xs: 'column',
      lg: 'row',
    },
    gap: theme.tokens.spacing[6],
    width: '100%',
  },
  documentsContainer: {
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  fileContainer: {
    gap: theme.tokens.spacing[2],
    minWidth: 250,
  },
  fileDescription: {
    color: theme.colors.subtitle,
    fontSize: 14,
  },
  mainSection: {
    width: '100%',
  },
  root: {
    alignSelf: 'stretch',
    gap: theme.tokens.spacing[4],
  },
  section: {
    gap: theme.tokens.spacing[4],
    marginTop: theme.tokens.spacing[4],
  },
  sectionTitle: {
    color: theme.colors.onSurface,
    fontWeight: '600',
    marginBottom: theme.tokens.spacing[2],
    opacity: 0.8,
  },
}))

export default OrganizationInfoCard
