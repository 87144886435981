import { Image } from 'expo-image'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { ApiRoot } from '../../../constants'
import { useAppStore } from '../../store/useAppStore'
import InitialsAvatar from './InitialsAvatar'

const OrganizationAvatar = ({
  logoId,
  name,
  organizationId,
  size,
}: {
  logoId?: string
  name: string
  organizationId: string
  size: number
}) => {
  const { styles } = useStyles(stylesheet)

  const url = `${ApiRoot()}/${organizationId}/${logoId}/download?size=200`

  const accessToken = useAppStore.use.auth().accessToken

  if (!logoId) {
    return <InitialsAvatar size={size} string={name} style={styles.avatar} />
  } else {
    return (
      <Image
        cachePolicy="disk"
        contentFit="cover"
        source={{
          uri: url,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }}
        style={[
          styles.avatar,
          { width: size, height: size, borderRadius: size / 2 },
        ]}
      />
    )
  }
}

const stylesheet = createStyleSheet(() => ({
  avatar: {
    backgroundColor: 'white',
    marginRight: 10,
  },
}))

export default OrganizationAvatar
