import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import UserService from '../../../services/userService'
import NotFound from '../../shared/NotFound'
import UserEmail from './settings/UserEmail'
import UserNotifications from './settings/UserNotifications'
import UserProfile from './settings/UserProfile'

const UserProfileSettings = () => {
  const { styles } = useStyles(stylesheet)

  const { user, isLoading } = UserService.useCurrentUser()

  if (!isLoading && !user) return <NotFound />

  return (
    <View style={styles.cardContainer}>
      <UserProfile
        buttonMode="contained"
        buttonStyle={styles.button}
        formContainerStyle={styles.formContainer}
        titleStyle={styles.title}
      />
      <UserEmail
        buttonMode="contained"
        buttonStyle={styles.button}
        formContainerStyle={styles.formContainer}
        titleStyle={styles.title}
      />
      <UserNotifications
        buttonMode="contained"
        buttonStyle={styles.button}
        formContainerStyle={styles.formContainer}
        titleStyle={styles.title}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  button: {
    marginTop: theme.tokens.spacing[2],
    width: 200,
  },
  cardContainer: {
    gap: theme.tokens.spacing[8],
  },
  formContainer: {
    flexDirection: {
      md: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  title: {
    marginBottom: theme.tokens.spacing[4],
  },
}))

export default UserProfileSettings
