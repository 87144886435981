import { useActionSheet } from '@expo/react-native-action-sheet'
import { Image } from 'expo-image'
import { createRef, useCallback, useState } from 'react'
import { Alert, findNodeHandle, View } from 'react-native'
import {
  ActivityIndicator,
  Icon,
  Text,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { ApiRoot } from '../../../../constants'
import useFile from '../../../hooks/useFile'
import { useAppStore } from '../../../store/useAppStore'
import log from '../../../utils/datadog/log/log'
import { logError } from '../../../utils/log'

const MAX_HEIGHT = 200
const MAX_WIDTH = 160

interface Props {
  allowedFileTypes: (
    | 'application/pdf'
    | 'image/*'
    | 'image/jpeg'
    | 'image/png'
  )[]
  createdAt?: Date
  fileName?: string
  fileId: string
  mimeType?: string
  name: string
  onDelete: () => void
  onUploadComplete: (fileIds: string[]) => void
}

const File = ({
  allowedFileTypes,
  createdAt,
  fileId,
  fileName,
  mimeType,
  name,
  onDelete,
  onUploadComplete,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const { showActionSheetWithOptions } = useActionSheet()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isWidescreen, setIsWidescreen] = useState(false)

  const accessToken = useAppStore.use.auth().accessToken

  const _anchorRef = createRef<any>()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const url = `${ApiRoot()}/${currentOrganization?.id}/${fileId}/download?size=400`

  const { openFile, pickFilesAndUpload } = useFile()

  const pickFiles = async () => {
    const fileIds = await pickFilesAndUpload({
      allowedFileTypes,
      multiple: false,
    })

    if (fileIds?.length) {
      onUploadComplete(fileIds)
    }
  }

  const open = useCallback(async () => {
    if (!currentOrganization?.id) {
      return
    }

    setIsDownloading(true)

    try {
      await openFile({
        fileName,
        fileId,
        mimeType,
      })
    } catch (error: any) {
      log.error('There was a problem opening the file')
      logError(error)
    }

    setIsDownloading(false)
  }, [currentOrganization?.id, fileId, fileName, mimeType, openFile])

  const onLongPress = () => {
    const options = ['Upload', 'Delete', 'Cancel']
    const uploadNew = 0
    const destructiveButtonIndex = 1
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        anchor: findNodeHandle(_anchorRef.current) || undefined,
        useModal: false,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case uploadNew:
            pickFilesAndUpload({
              allowedFileTypes,
              multiple: true,
            })
            break
          case destructiveButtonIndex:
            Alert.alert(
              'Delete',
              'Are you sure you want to delete this document?',
              [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  isPreferred: false,
                  text: 'Delete',
                  onPress: () => onDelete(),
                  style: 'destructive',
                },
              ]
            )
            break

          case cancelButtonIndex:
            break
        }
      }
    )
  }

  let iconSource = 'file'
  if (allowedFileTypes.length === 1) {
    switch (allowedFileTypes[0]) {
      case 'application/pdf':
        iconSource = 'file-pdf-box'
        break
      case 'image/*':
        iconSource = 'image'
        break
    }
  }

  return (
    <View style={styles.root}>
      <TouchableRipple
        accessibilityRole="button"
        borderless
        disabled={isDownloading}
        onLongPress={onLongPress}
        onPress={mimeType ? open : pickFiles}
        ref={_anchorRef}
        style={[
          styles.fileContainer,
          mimeType?.startsWith('image') && {
            width: isWidescreen ? MAX_HEIGHT : MAX_WIDTH,
            height: isWidescreen ? MAX_WIDTH : MAX_HEIGHT,
          },
          !mimeType && styles.emptyContainer,
        ]}
      >
        <>
          {isDownloading && (
            <ActivityIndicator
              animating={true}
              style={{
                position: 'absolute',
                zIndex: 1,
                top: (isWidescreen ? MAX_WIDTH : MAX_HEIGHT) / 2 - 3,
              }}
            />
          )}
          {(mimeType?.startsWith('image/') || mimeType === 'application/pdf') &&
          url ? (
            <Image
              accessibilityIgnoresInvertColors={true}
              cachePolicy="disk"
              contentFit="contain"
              onLoad={({ source }) => {
                setIsWidescreen(source.width > source.height)
              }}
              source={{
                uri: url,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }}
              style={{
                width: isWidescreen ? MAX_HEIGHT : MAX_WIDTH,
                height: isWidescreen ? MAX_WIDTH : MAX_HEIGHT,
              }}
            />
          ) : (
            <View style={styles.emptyStateContent}>
              <Icon
                color={!mimeType ? styles.uploadText.color : undefined}
                size={30}
                source={!mimeType ? 'cloud-upload-outline' : iconSource}
              />
              {!mimeType && (
                <Text style={styles.uploadText}>
                  Upload{'\n'}
                  {name.toLowerCase()}
                </Text>
              )}
            </View>
          )}
        </>
      </TouchableRipple>
      <Text
        numberOfLines={1}
        style={[styles.fileName, !mimeType && styles.emptyText]}
      >
        {fileName || (!mimeType ? 'No file selected' : name)}
      </Text>
      {createdAt && (
        <Text style={styles.fileNameDate}>
          {createdAt.toLocaleDateString()}
        </Text>
      )}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    emptyContainer: {
      borderStyle: 'dashed',
    },
    emptyStateContent: {
      alignItems: 'center',
      gap: theme.tokens.spacing[2],
    },
    emptyText: {
      color: theme.colors.onSurface,
    },
    fileContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.elevation.level1,
      borderColor: theme.colors.elevation.level3,
      borderRadius: 12,
      borderWidth: 1,
      height: MAX_HEIGHT,
      justifyContent: 'center',
      width: MAX_WIDTH,
    },
    fileName: {
      alignSelf: 'center',
      fontSize: 14,
      fontWeight: '600',
      marginTop: 8,
    },
    fileNameDate: {
      color: theme.colors.subtitle,
      fontSize: 12,
      marginTop: 2,
    },
    root: {
      alignItems: 'center',
      margin: theme.tokens.spacing[3],
      width: MAX_WIDTH + 16,
    },
    uploadText: {
      color: theme.colors.primary,
      fontSize: 12,
      marginTop: theme.tokens.spacing[2],
      textAlign: 'center',
    },
  }
})

export default File
