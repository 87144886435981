import { FlashList } from '@shopify/flash-list'
import { Fragment, useCallback } from 'react'
import { View } from 'react-native'
import { Divider, Icon, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import SearchItem from './SearchItem'

interface Props {
  searchInput: string
}

const SearchResults = (props: Props) => {
  const { searchInput } = props
  const { styles, theme } = useStyles(stylesheet)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const searchQuery = trpc.search.useInfiniteQuery(
    {
      organizationId: currentOrganization?.id || '',
      query: searchInput || '',
      limit: 10,
    },
    {
      enabled: !!searchInput && !!currentOrganization,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  )

  const hasNoResults =
    searchQuery.data?.pages[0] &&
    searchQuery.data?.pages[0].users.length === 0 &&
    searchQuery.data?.pages[0].fosters.length === 0 &&
    !searchQuery.isLoading

  const allUsers = searchQuery.data?.pages.flatMap((page) => page.users) || []
  const allFosters =
    searchQuery.data?.pages.flatMap((page) => page.fosters) || []

  const renderItem = useCallback(
    ({ item, index }: { item: any; index: number }) => {
      const isUser = 'email' in item
      const itemsBeforeUser = isUser ? 0 : allUsers.length
      const showDivider =
        index < allUsers.length - 1 || // Between users
        (isUser && index === allUsers.length - 1 && allFosters.length > 0) || // Between users and fosters
        (!isUser && index < itemsBeforeUser + allFosters.length - 1) // Between fosters

      return (
        <Fragment key={item.id + '-view'}>
          <SearchItem
            extraData={[
              ...[
                item.microchipId
                  ? `Microchip : ${item.microchipId}`
                  : undefined,
              ],
            ]}
            id={item.id}
            key={item.id}
            path={isUser ? `users/${item.id}` : `fosters/${item.id}`}
            primaryTitle={
              isUser ? `${item.firstName} ${item.lastName}` : item.name
            }
            secondaryTitle={!isUser ? item.userFullName : undefined}
            thumbUri={
              isUser
                ? `https://www.gravatar.com/avatar/${item.emailHash}?d=identicon`
                : item.blurhash
                  ? item.thumbUri
                  : item.species === 'Cat'
                    ? require('../../../assets/cat.png')
                    : require('../../../assets/dog.png')
            }
          />
          {showDivider && <Divider />}
        </Fragment>
      )
    },
    [allUsers.length, allFosters.length]
  )

  if (hasNoResults) {
    return (
      <View style={styles.emptyState}>
        <View style={styles.emptyIconContainer}>
          <Icon color={theme.colors.primary} size={48} source="magnify-close" />
        </View>
        <Text style={styles.emptyTitle} variant="headlineSmall">
          No results found
        </Text>
        <Text style={styles.emptyText} variant="bodyMedium">
          Try adjusting your search terms or using a different query
        </Text>
      </View>
    )
  }

  const allItems = [...allUsers, ...allFosters]

  return (
    <FlashList
      data={allItems}
      estimatedItemSize={80}
      ListEmptyComponent={
        searchInput && searchQuery.isLoading ? (
          <View style={styles.emptyState}>
            <Text>Loading...</Text>
          </View>
        ) : null
      }
      onEndReached={() => {
        if (searchQuery.hasNextPage) {
          searchQuery.fetchNextPage()
        }
      }}
      onEndReachedThreshold={0.8}
      renderItem={renderItem}
    />
  )
}

const stylesheet = createStyleSheet((theme) => ({
  emptyIconContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.secondaryContainer,
    borderRadius: 60,
    height: 120,
    justifyContent: 'center',
    marginBottom: theme.tokens.spacing[6],
    marginTop: theme.tokens.spacing[4],
    width: 120,
  },
  emptyState: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: theme.tokens.spacing[8],
  },
  emptyText: {
    color: theme.colors.onSurfaceVariant,
    marginTop: theme.tokens.spacing[2],
    textAlign: 'center',
  },
  emptyTitle: {
    color: theme.colors.onSurface,
    fontWeight: 'bold',
    marginBottom: theme.tokens.spacing[2],
  },
}))

export default SearchResults
