import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useRef } from 'react'

const useRefetchOnFocus = async <T>(refetch: () => Promise<T>) => {
  const firstTimeRef = useRef(true)

  useFocusEffect(
    useCallback(() => {
      if (firstTimeRef.current) {
        firstTimeRef.current = false
        return
      }

      refetch()
    }, [refetch])
  )
}

export default useRefetchOnFocus
