import { useRouter } from 'expo-router'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Page from '../../components/shared/Page'
import trpc from '../../utils/trpc'

const Welcome = () => {
  const router = useRouter()
  const { styles } = useStyles(stylesheet)
  const currentUser = trpc.user.current.useQuery({}).data

  return (
    <Page requiresSubscription={false} viewId="welcome">
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerContainer}>
            <Text variant="headlineMedium">
              Welcome
              {currentUser?.firstName ? `, ${currentUser.firstName}` : ''}!
            </Text>
            <Text style={styles.subtitle} variant="titleMedium">
              Let's get you connected with your rescue organization
            </Text>
          </View>

          <View style={styles.buttonContainer}>
            <Text style={styles.label} variant="titleMedium">
              Choose your role:
            </Text>

            <Button
              contentStyle={styles.buttonContent}
              mode="contained"
              onPress={() => router.push('/getting-started/waiting')}
            >
              I volunteer for a rescue
            </Button>

            <Button
              contentStyle={styles.buttonContent}
              mode="outlined"
              onPress={() => router.push('/getting-started/setup')}
            >
              I operate an animal rescue
            </Button>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoText} variant="bodyMedium">
              Rescue operators will be guided through creating their
              organization. Volunteers will need an invitation from their rescue
              organization.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  buttonContainer: {
    alignItems: 'stretch',
    gap: theme.tokens.spacing[3],
    width: '100%',
  },
  buttonContent: {
    paddingVertical: theme.tokens.spacing[3],
  },
  container: {
    flex: 1,
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    gap: theme.tokens.spacing[6],
  },
  headerContainer: {
    alignItems: 'center',
    gap: theme.tokens.spacing[2],
  },
  infoContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    padding: theme.tokens.spacing[4],
  },
  infoText: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
  label: {
    marginBottom: theme.tokens.spacing[2],
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
}))

export default Welcome
