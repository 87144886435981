import { LinearGradient } from 'expo-linear-gradient'
import { router } from 'expo-router'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Icon, Text, TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../enums/species'
import { useAppStore } from '../../store/useAppStore'
import { addAction, RumActionType } from '../../utils/datadog/datadog'
import { triggerHapticClick } from '../../utils/haptics'
import FosterThumbnail from './FosterThumbnail'

interface Props {
  foster: {
    id: string
    blurhash: string | null
    deceasedDate: Date | null
    thumbUri: string | null
    species: 'Dog' | 'Cat' | null
    name: string
    user: {
      firstName: string
      lastName: string
    }
  }
  isSelected: boolean
  onSelect(id: string): void
}

const FosterGridItem = ({ foster, isSelected, onSelect }: Props) => {
  const { styles } = useStyles(stylesheet)

  const bulkSelect = useAppStore.use.bulkSelect()

  const advancedFilters = useAppStore.use.advancedFilters()
  const basicFilters = useAppStore.use.basicFilters()

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless
      onLongPress={() => {
        addAction({
          type: RumActionType.TAP,
          name: `Long Press - Foster`,
        })
        onSelect(foster.id)
        triggerHapticClick()
      }}
      onPress={() => {
        if (isSelected) {
          addAction({
            type: RumActionType.TAP,
            name: `Deselect - Foster`,
          })
          onSelect(foster.id)
          triggerHapticClick()
          return
        }

        if (bulkSelect.selection.items.length > 0) {
          addAction({
            type: RumActionType.TAP,
            name: `Select - Foster`,
          })
          onSelect(foster.id)
          triggerHapticClick()
        } else {
          addAction({
            type: RumActionType.TAP,
            name: `View - Foster`,
          })
          router.push({
            pathname: `/fosters/[id]`,
            params: { id: foster.id },
          })
        }
      }}
      style={[styles.touchableContainer, { aspectRatio: 1 }]}
      testID={`foster-link-${foster.id}`}
    >
      <View style={[styles.imageContainer, { height: '100%' }]}>
        {isSelected && <View style={styles.selectedBorder} />}

        {isSelected && (
          <View style={styles.selectedCheck}>
            <Icon color="white" size={20} source="check" />
          </View>
        )}
        <FosterThumbnail
          blurhash={foster.blurhash}
          species={foster.species as Species}
          style={StyleSheet.absoluteFill}
          thumbUri={foster.thumbUri}
          transition={200}
        />
        {foster.deceasedDate && <View style={styles.blackAndWhiteOverlay} />}
        <LinearGradient
          colors={[
            'transparent',
            'rgba(0,0,0,0.5)',
            'rgba(0,0,0,0.7)',
            'rgba(0,0,0,0.9)',
          ]}
          style={styles.gradientContainer}
        />
        <View style={styles.cardLabelContainer}>
          <Text
            numberOfLines={1}
            style={[styles.text, { opacity: 1 }]}
            testID={`foster-name-${foster.id}`}
            variant="titleLarge"
          >
            {foster.name}
          </Text>
          {(basicFilters.filters.viewAll || advancedFilters.isActive) && (
            <Text
              style={styles.text}
              testID={`user-name-${foster.id}`}
              variant="bodyMedium"
            >
              {foster.user.firstName} {foster.user.lastName}
            </Text>
          )}
        </View>
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    blackAndWhiteOverlay: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
    cardLabelContainer: {
      bottom: 0,
      padding: theme.tokens.spacing[3],
      position: 'absolute',
      width: '100%',
    },
    gradientContainer: {
      bottom: 0,
      height: '35%',
      position: 'absolute',
      width: '100%',
    },
    imageContainer: {
      borderColor: theme.colors.outlineVariant,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: 0.5,
      overflow: 'hidden',
      width: '100%',
    },
    selectedBorder: {
      backgroundColor: 'transparent',
      borderColor: theme.colors.primary,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: theme.tokens.spacing[2],
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 2,
    },
    selectedCheck: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 100,
      height: 30,
      justifyContent: 'center',
      margin: 6,
      position: 'absolute',
      width: 30,
      zIndex: 2,
    },
    text: {
      color: 'white',
    },
    touchableContainer: {
      borderRadius: theme.tokens.containerBorderRadius,
      flex: 1,
      overflow: 'hidden',
      width: '100%',
    },
  }
})

export default memo(FosterGridItem)
