import {
  MD3DarkTheme as DefaultDarkTheme,
  MD3LightTheme as DefaultLightTheme,
  MD3Theme,
} from 'react-native-paper'
import { MD3Colors } from 'react-native-paper/lib/typescript/types'
import tokens from './tokens'

export interface Theme extends MD3Theme {
  colors: MD3Colors & {
    cardBorder: string
    disabledRow: string
    subtitle: string
  }
}

// Generated with https://callstack.github.io/react-native-paper/docs/guides/theming#creating-dynamic-theme-colors
// Primary: #13868C
// Secondary: #4BC493
// Tertiary: #4E5F7D
export const lightTheme = {
  ...DefaultLightTheme,
  colors: {
    primary: 'rgb(0, 104, 117)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(157, 239, 255)',
    onPrimaryContainer: 'rgb(0, 31, 36)',
    secondary: 'rgb(74, 98, 103)',
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: '#e3f6f0',
    onSecondaryContainer: 'rgb(5, 31, 35)',
    tertiary: 'rgb(83, 93, 126)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(219, 225, 255)',
    onTertiaryContainer: 'rgb(15, 26, 55)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(255, 218, 214)',
    onErrorContainer: 'rgb(65, 0, 2)',
    // when updating the background color, don't forget to update the splash screen in app.config.ts
    background: '#fafafa',
    onBackground: 'rgb(25, 28, 29)',
    surface: '#ffffff',
    onSurface: 'rgb(25, 28, 29)',
    surfaceVariant: 'rgb(219, 228, 230)',
    onSurfaceVariant: 'rgb(63, 72, 74)',
    outline: '#ececec',
    outlineVariant: 'rgb(191, 200, 202)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(46, 49, 50)',
    inverseOnSurface: 'rgb(239, 241, 242)',
    inversePrimary: 'rgb(61, 217, 240)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(238, 245, 246)',
      level2: 'rgb(231, 240, 242)',
      level3: 'rgb(223, 236, 238)',
      level4: 'rgb(221, 234, 237)',
      level5: 'rgb(216, 231, 234)',
    },
    surfaceDisabled: 'rgba(25, 28, 29, 0.12)',
    onSurfaceDisabled: 'rgba(25, 28, 29, 0.38)',
    backdrop: 'rgba(41, 50, 52, 0.4)',
    cardBorder: '#ececec',
    disabledRow: '#f4f4f4',
    subtitle: 'rgba(0, 0, 0, 0.54)',
  },
  tokens,
}

export const darkTheme = {
  ...DefaultDarkTheme,
  colors: {
    primary: 'rgb(61, 217, 240)',
    onPrimary: 'rgb(0, 54, 62)',
    primaryContainer: 'rgb(0, 79, 89)',
    onPrimaryContainer: 'rgb(157, 239, 255)',
    secondary: 'rgb(177, 203, 208)',
    onSecondary: 'rgb(28, 52, 56)',
    secondaryContainer: 'rgb(51, 75, 79)',
    onSecondaryContainer: 'rgb(205, 231, 237)',
    tertiary: 'rgb(187, 197, 234)',
    onTertiary: 'rgb(37, 47, 77)',
    tertiaryContainer: 'rgb(60, 70, 101)',
    onTertiaryContainer: 'rgb(219, 225, 255)',
    error: 'rgb(255, 180, 171)',
    onError: 'rgb(105, 0, 5)',
    errorContainer: 'rgb(147, 0, 10)',
    onErrorContainer: 'rgb(255, 180, 171)',
    // when updating the background color, don't forget to update the splash screen in app.config.ts
    background: 'rgb(25, 28, 29)',
    onBackground: 'rgb(225, 227, 227)',
    surface: '#1d2122',
    onSurface: 'rgb(225, 227, 227)',
    surfaceVariant: 'rgb(63, 72, 74)',
    onSurfaceVariant: 'rgb(191, 200, 202)',
    outline: 'rgb(63, 72, 74)',
    outlineVariant: 'rgb(63, 72, 74)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(225, 227, 227)',
    inverseOnSurface: 'rgb(46, 49, 50)',
    inversePrimary: 'rgb(0, 104, 117)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(27, 37, 40)',
      level2: 'rgb(28, 43, 46)',
      level3: 'rgb(29, 49, 52)',
      level4: 'rgb(29, 51, 54)',
      level5: 'rgb(30, 55, 59)',
    },
    surfaceDisabled: 'rgba(225, 227, 227, 0.12)',
    onSurfaceDisabled: 'rgba(225, 227, 227, 0.38)',
    backdrop: 'rgba(41, 50, 52, 0.4)',
    cardBorder: 'rgb(30, 55, 59)',
    disabledRow: 'rgba(225, 227, 227, 0.12)',
    subtitle: 'rgba(225, 227, 227, 0.54)',
  },
  tokens,
}
