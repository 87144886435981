import { Image } from 'expo-image'
import { router } from 'expo-router'
import { View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { isBold, stripMarkers } from '../../utils/strings'
import InitialsAvatar from '../navigation/InitialsAvatar'
import StyledSearchTitle from './SearchStyledTitle'

interface Props {
  id: string
  extraData?: (string | undefined)[]
  primaryTitle: string
  path: string
  secondaryTitle?: string | null
  thumbUri?: string | null
}

const avatarSize = 50

const SearchItem = ({
  id,
  primaryTitle,
  path,
  thumbUri,
  secondaryTitle,
  extraData,
}: Props) => {
  const { styles, theme } = useStyles(stylesheet)

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless={true}
      key={id + '-touchable'}
      onPress={() => {
        router.push(path)
      }}
      style={{
        borderRadius: theme.tokens.containerBorderRadius,
      }}
      testID={`search-item-${primaryTitle.replaceAll('!', '')}`}
    >
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <StyledSearchTitle id={id} text={primaryTitle} variant="labelLarge" />
          {secondaryTitle && (
            <StyledSearchTitle
              id={id}
              style={{ color: theme.colors.secondary }}
              text={secondaryTitle || ''}
              variant="labelSmall"
            />
          )}
          {extraData
            ?.filter((data) => data && isBold(data)) // Filter to include only data wrapped in <b></b>
            ?.map((data) => {
              return (
                <StyledSearchTitle
                  id={id}
                  style={{ color: theme.colors.secondary }}
                  text={data || ''}
                  variant="labelSmall"
                />
              )
            })}
        </View>
        {thumbUri ? (
          <View
            style={{
              height: avatarSize,
              width: avatarSize,
            }}
          >
            <Image
              accessibilityIgnoresInvertColors={true}
              cachePolicy={'disk'}
              contentFit="cover"
              source={thumbUri}
              style={styles.avatar}
              transition={0}
            />
          </View>
        ) : (
          <View>
            <InitialsAvatar
              size={avatarSize}
              string={stripMarkers(primaryTitle)}
              style={styles.avatar}
            />
          </View>
        )}
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      alignSelf: 'flex-end',
      borderColor: theme.colors.cardBorder,
      borderRadius: avatarSize / 2,
      borderWidth: 2,
      height: avatarSize,
      marginRight: 10,
      width: avatarSize,
    },
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.tokens.spacing[3],
    },
    textContainer: {
      flex: 1,
    },
  }
})

export default SearchItem
