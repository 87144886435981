import { BottomSheetScrollView } from '@gorhom/bottom-sheet'
import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Text, View } from 'react-native'
import { Button, Divider } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'
import useAutoSave from '../../hooks/useAutoSave'
import { useAppStore } from '../../store/useAppStore'
import AdvancedFilterRow from './advanced/AdvancedFilterRow'
import {
  AdvancedFilterFormValues,
  AdvancedFilterSchema,
} from './advanced/AdvancedFilterTypes'

function setNestedValue(obj: any, path: string[], value: any) {
  let current = obj
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i]
    if (!(key in current)) {
      current[key] = {}
    } else if (typeof current[key] !== 'object' || current[key] === null) {
      current[key] = {}
    }
    current = current[key]
  }
  current[path[path.length - 1]] = value
}

function flattenFilters(
  filters: { [key: string]: any },
  prefix = ''
): { field: string; operator: string; value: any }[] {
  let result: { field: string; operator: string; value: any }[] = []
  for (const key in filters) {
    const value = filters[key]
    const fieldPath = prefix ? `${prefix}.${key}` : key
    if (
      value &&
      typeof value === 'object' &&
      ('operator' in value || 'value' in value || 'type' in value)
    ) {
      result.push({
        field: fieldPath,
        operator: value.operator,
        value: value.value,
      })
    } else if (value && typeof value === 'object') {
      result = result.concat(flattenFilters(value, fieldPath))
    }
  }
  return result
}

const AdvancedFilterView = () => {
  const { styles } = useStyles(stylesheet)

  const basicFilters = useAppStore.use.basicFilters()
  const setBasicFilters = useAppStore.use.setBasicFilters()

  const advancedFilters = useAppStore.use.advancedFilters()
  const setAdvancedFilters = useAppStore.use.setAdvancedFilters()
  const resetAdvancedFilters = useAppStore.use.resetAdvancedFilters()

  const defaultFormValues = useMemo(() => {
    if (!advancedFilters.filters) {
      return {
        filters: [
          {
            field: '',
            operator: '',
            value: '',
          },
        ],
      }
    }

    return {
      filters: flattenFilters(advancedFilters.filters),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDeepCompareMemoize(advancedFilters.filters)])

  const form = useForm<AdvancedFilterFormValues>({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  })

  const { control, formState, handleSubmit, setValue, reset } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  })

  const resetFormDefault = useCallback(() => {
    reset({
      filters: [],
    })

    for (const [index, _] of fields.entries()) {
      remove(index)
    }
  }, [fields, remove, reset])

  useEffect(() => {
    if (fields.length === 0) {
      resetFormDefault()
      resetAdvancedFilters()

      append({ field: '', operator: '', value: '' })
    }
  }, [fields.length, resetFormDefault, resetAdvancedFilters, append])

  const onSubmit = (data: AdvancedFilterFormValues) => {
    const filtersObj = {} as {
      [key: string]: any
    }

    data.filters.forEach((f) => {
      const fieldPath = f.field.split('.')
      const filterValue = {
        operator: f.operator,
        value: f.value,
        type: AdvancedFilterSchema.find((fs) => fs.field === f.field)?.type as
          | 'string'
          | 'boolean'
          | 'date',
      }
      setNestedValue(filtersObj, fieldPath, filterValue)
    })

    setAdvancedFilters({
      ...advancedFilters,
      filters: filtersObj,
      isDirty: true,
    })
  }

  useAutoSave({
    defaultValues: defaultFormValues,
    form,
    interval: 100,
    onSave: handleSubmit(onSubmit),
  })

  return (
    <BottomSheetScrollView contentContainerStyle={styles.root}>
      <View style={styles.resetButtonRow}>
        <Button
          disabled={!advancedFilters.isDirty}
          icon="restore"
          onPress={() => {
            resetAdvancedFilters()
            resetFormDefault()
          }}
        >
          Reset Filters
        </Button>
        <Button
          icon="filter-outline"
          onPress={() => {
            setBasicFilters({
              ...basicFilters,
              isActive: true,
            })
            setAdvancedFilters({
              ...advancedFilters,
              isActive: false,
            })
          }}
        >
          Basic Filters
        </Button>
      </View>
      {fields.map((field, index) => (
        <View key={`${field.id}-view`}>
          <AdvancedFilterRow
            control={control}
            errors={formState.errors}
            fields={AdvancedFilterSchema}
            id={field.id}
            index={index}
            key={field.id}
            remove={remove}
            setValue={setValue}
          />
          {index !== fields.length - 1 && (
            <Divider
              bold={true}
              style={{
                marginVertical: 20,
              }}
            />
          )}
        </View>
      ))}
      <View style={styles.buttonContainer}>
        <Button
          mode="contained-tonal"
          onPress={() => append({ field: '', operator: '', value: '' })}
          style={{ width: '100%', flex: 1 }}
        >
          <Text>Add Another Filter</Text>
        </Button>
      </View>
    </BottomSheetScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonContainer: {
      marginTop: theme.tokens.spacing[4],
      paddingBottom: theme.tokens.spacing[4],
    },
    resetButtonRow: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.tokens.spacing[4],
    },
    root: {
      backgroundColor: theme.colors.surface,
      paddingBottom: theme.tokens.spacing[6],
      paddingHorizontal: theme.tokens.spacing[6],
    },
  }
})

export default AdvancedFilterView
