import FosterHealthCard from '../../../../components/fosters/cards/FosterHealthCard'
import { useFosterFormContext } from '../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../components/fosters/FosterTab'
import useFosterOwner from '../../../../hooks/useFosterOwner'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'

const FosterMedicalTab = () => {
  const { control, formState, setValue } = useFosterFormContext()
  const { fosterId } = FosterService.useCurrentFoster()
  const isFosterOwner = useFosterOwner(fosterId)

  const currentOrganization = useAppStore.use.currentOrganization().organization
  const isDisabled = !currentOrganization?.subscriptionActive || !isFosterOwner

  return (
    <FosterTab viewId="foster-health-tab">
      <FosterHealthCard
        control={control}
        disabled={isDisabled}
        formState={formState}
        setValue={setValue}
      />
    </FosterTab>
  )
}

export default FosterMedicalTab
