import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { addWeeks, endOfToday, isAfter, isBefore } from 'date-fns'
import { router } from 'expo-router'
import { useEffect } from 'react'
import Purchases, { CustomerInfo } from 'react-native-purchases'
import MedicalInfoType from '../enums/medicalInfoType'
import Species from '../enums/species'
import { SubscriptionTier } from '../store/organizationStore'
import { useAppStore } from '../store/useAppStore'
import { getTierLevel } from '../utils/purchases/billing'
import purchases from '../utils/purchases/purchases'
import trpc from '../utils/trpc'

const OrganizationService = {
  useCurrentOrganization: () => {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization
    const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

    const queryClient = useQueryClient()

    const organizationQuery = trpc.organization.byId.useQuery(
      {
        organizationId: currentOrganization?.id || '',
      },
      {
        enabled: !!currentOrganization?.id,
      }
    )

    const currentUserQuery = trpc.user.current.useQuery({
      organizationId: currentOrganization?.id,
    })

    const fosterCountQuery = trpc.foster.currentMonthFosterCount.useQuery(
      {
        organizationId: currentOrganization?.id || '',
      },
      {
        enabled: !!currentOrganization?.id,
      }
    )

    const currentUser = currentUserQuery.data

    const organizations = currentUser?.organizations.map(
      (org) => org.organization
    )

    if (organizations?.length && !currentOrganization) {
      setCurrentOrganization({
        organization: {
          id: organizations?.[0].id,
          adoptionContract: organizations?.[0].adoptionContract || null,
          logo: organizations?.[0].logo || null,
          qrCode: organizations?.[0].qrCode || null,
          name: organizations?.[0].name,
        },
      })
    }

    // Handle the case where the organization was deleted, and the user needs to be redirected to the home screen
    // This will trigger a re-render of the stack, which will trigger a new default organization to be set
    useEffect(() => {
      if (
        currentOrganization?.id &&
        organizationQuery.failureReason?.data?.code === 'NOT_FOUND'
      ) {
        organizationQuery.remove()
        currentUserQuery.remove()
        setCurrentOrganization({ organization: null })
        router.dismissTo('/')
      }
    }, [
      currentOrganization,
      currentUserQuery,
      organizationQuery,
      organizationQuery.failureReason,
      organizations,
      setCurrentOrganization,
    ])

    useEffect(() => {
      if (currentOrganization && organizationQuery.data) {
        const newOrgData = organizationQuery.data
        if (
          currentOrganization.id !== newOrgData.id ||
          currentOrganization.name !== newOrgData.name ||
          currentOrganization.logo !== newOrgData.logo ||
          currentOrganization.qrCode !== newOrgData.qrCode ||
          currentOrganization.adoptionContract !== newOrgData.adoptionContract
        ) {
          setCurrentOrganization({
            organization: {
              ...currentOrganization,
              id: newOrgData.id,
              name: newOrgData.name,
              logo: newOrgData.logo,
              qrCode: newOrgData.qrCode,
              adoptionContract: newOrgData.adoptionContract,
            },
          })
        }
      } else if (organizationQuery.data && !currentOrganization) {
        // This is a true first load - no AsyncStorage state exists
        setCurrentOrganization({
          organization: organizationQuery.data,
        })
      }
    }, [currentOrganization, organizationQuery.data, setCurrentOrganization])

    useEffect(() => {
      if (!currentOrganization?.id) {
        return
      }

      // Helper function to update subscription status
      const updateSubscriptionStatus = async (customerInfo: CustomerInfo) => {
        const fosterCount = fosterCountQuery.data?.count ?? 0

        // If count is 5 or less, they're on free tier
        if (fosterCount <= 5) {
          if (
            currentOrganization.subscriptionActive !== true ||
            currentOrganization.subscriptionTier !== undefined
          ) {
            setCurrentOrganization({
              organization: {
                ...currentOrganization,
                subscriptionTier: undefined,
                subscriptionActive: true,
              },
            })
          }
          return
        }

        // Determine required tier based on foster count
        let requiredTier: SubscriptionTier
        if (fosterCount <= 10) {
          requiredTier = 'small-rescue'
        } else if (fosterCount <= 100) {
          requiredTier = 'medium-rescue'
        } else {
          requiredTier = 'large-rescue'
        }

        const activeTier = [
          'small-rescue',
          'medium-rescue',
          'large-rescue',
        ].find((tier) => customerInfo?.entitlements.all[tier]?.isActive) as
          | SubscriptionTier
          | undefined

        // Only update if the subscription status or tier has changed
        const newSubscriptionActive = activeTier
          ? getTierLevel(activeTier) >= getTierLevel(requiredTier)
          : false

        if (
          currentOrganization.subscriptionTier !== activeTier ||
          currentOrganization.subscriptionActive !== newSubscriptionActive
        ) {
          setCurrentOrganization({
            organization: {
              ...currentOrganization,
              subscriptionTier: activeTier,
              subscriptionActive: newSubscriptionActive,
            },
          })
        }
      }

      // Check subscription status immediately
      async function checkInitialStatus() {
        const customerInfo = await purchases.getCustomerInfo()
        await updateSubscriptionStatus(customerInfo)
      }
      checkInitialStatus()

      // Set up listener for future changes
      const listener = updateSubscriptionStatus

      // no-op if there are no listeners already
      Purchases.removeCustomerInfoUpdateListener(listener)
      Purchases.addCustomerInfoUpdateListener(listener)

      return () => {
        Purchases.removeCustomerInfoUpdateListener(listener)
      }
    }, [
      currentOrganization,
      currentOrganization?.id,
      fosterCountQuery.data,
      setCurrentOrganization,
    ])

    if (
      currentUser &&
      !currentUser?.organizations.length &&
      currentOrganization
    ) {
      setCurrentOrganization({ organization: null })
    }

    return {
      currentOrganization,
      refreshOrganization: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.organization.byId))
        queryClient.invalidateQueries(getQueryKey(trpc.user.current))

        return organizationQuery.refetch()
      },
      organizationQueryData: organizationQuery.data,
    }
  },
  useIsSaving() {
    const mutationKey = getQueryKey(trpc.organization.update)
    return useIsMutating({ mutationKey }) > 0
  },
  useMedicalInfo: (
    type: MedicalInfoType,
    birthDate?: Date | null,
    species?: Species
  ) => {
    const currentOrganization =
      useAppStore.use.currentOrganization().organization

    const organization = trpc.organization.byId.useQuery(
      {
        organizationId: currentOrganization?.id || '',
      },
      {
        enabled: !!currentOrganization,
      }
    ).data

    if (!organization?.medicalInfos) {
      return []
    }

    const filteredMedicalInfos = organization.medicalInfos
      .filter(
        (m) =>
          m.type === type &&
          (!m.species || m.species === species) &&
          !!birthDate &&
          (!m.minAgeWeeks ||
            isAfter(endOfToday(), addWeeks(birthDate, m.minAgeWeeks || 0))) &&
          (!m.maxAgeWeeks ||
            isBefore(endOfToday(), addWeeks(birthDate, m.maxAgeWeeks || 0)))
      )
      .sort((a, b) => {
        if (a.minAgeWeeks && b.minAgeWeeks) {
          return a.minAgeWeeks - b.minAgeWeeks
        }

        if (a.minAgeWeeks && !b.minAgeWeeks) {
          return -1
        }

        if (!a.minAgeWeeks && b.minAgeWeeks) {
          return 1
        }

        return 0
      })

    return filteredMedicalInfos
  },
}

export default OrganizationService
