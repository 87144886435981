import { useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Card from '../../../components/shared/Card'
import Page from '../../../components/shared/Page'
import UserProfileSettings from '../../../components/users/detail/UserProfileSettings'

const UserSettings = () => {
  const { styles } = useStyles(stylesheet)

  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      title: 'Settings',
    })
  }, [navigation])

  return (
    <Page requiresSubscription={false} viewId="user-settings-page">
      <Card style={styles.card} title="Profile">
        <UserProfileSettings />
      </Card>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  card: {
    padding: theme.tokens.spacing[4],
  },
}))

export default UserSettings
