import AsyncStorage from '@react-native-async-storage/async-storage'
import { useGateValue } from '@statsig/expo-bindings'
import { router } from 'expo-router'
import { View } from 'moti'
import { memo } from 'react'
import { Platform } from 'react-native'
import {
  Button,
  Divider,
  Icon,
  Modal,
  Portal,
  Text,
  Tooltip,
  TouchableRipple,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { AppVersions, Feature } from '../../../constants'
import useQueryClient from '../../hooks/useQueryClient'
import useUserRoles from '../../hooks/useUserRoles'
import { useAppStore } from '../../store/useAppStore'
import { signOut } from '../../utils/auth/auth'
import trpc from '../../utils/trpc'
import Logo from './Logo'
import OrganizationButton from './OrganizationButton'
import UserAvatar from './UserAvatar'

interface Props {
  visible: boolean
  hideModal: () => void
}

const SettingsModal = ({ visible, hideModal }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  const isEmployee = useGateValue(Feature.Employee)
  const { isUserAdmin, isUserOwner } = useUserRoles()

  const currentOrganization = useAppStore.use.currentOrganization().organization
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  const setAuth = useAppStore.use.setAuth()

  const queryClient = useQueryClient()

  const currentUserQuery = trpc.user.current.useQuery(
    {
      organizationId: currentOrganization?.id,
    },
    {
      enabled: !!currentOrganization?.id,
    }
  )

  const organizations = currentUserQuery.data?.organizations
    .map((org) => org.organization)
    .filter((org) => org.id !== currentOrganization?.id)

  const userFullName = `${currentUserQuery.data?.firstName} ${currentUserQuery?.data?.lastName}`

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.modalContent}
        onDismiss={hideModal}
        style={{ flex: 1 }}
        visible={visible}
      >
        <View style={styles.root}>
          <View style={styles.logoContainer}>
            <Logo style={styles.logo} />
          </View>
          <View style={styles.innerContainer}>
            <View style={styles.rowContainer}>
              <UserAvatar
                size={40}
                style={styles.avatar}
                user={{
                  emailHash: currentUserQuery.data?.emailHash ?? undefined,
                  firstName: currentUserQuery.data?.firstName ?? '',
                  lastName: currentUserQuery.data?.lastName ?? '',
                }}
              />
              <Text style={styles.title}>{userFullName}</Text>
            </View>
            <View style={styles.buttonRow}>
              <Button
                icon="account-edit"
                mode="outlined"
                onPress={() => {
                  router.push(`/users/settings`)
                  hideModal()
                }}
                style={styles.button}
              >
                Settings
              </Button>
              <Button
                icon="logout"
                mode="outlined"
                onPress={async () => {
                  await signOut()
                  setAuth({
                    accessToken: undefined,
                    isLoggedIn: false,
                  })

                  // This clears all state used by supertokens, zustand, etc.
                  await AsyncStorage.clear()

                  // Clear all react-query state
                  queryClient.clear()

                  router.replace('/')
                }}
                style={styles.button}
              >
                Sign Out
              </Button>
            </View>
            <OrganizationButton
              logoId={currentOrganization?.logo?.id}
              name={currentOrganization?.name || ''}
              organizationId={currentOrganization?.id || ''}
            />
            {(isUserAdmin || isUserOwner) && (
              <View style={styles.buttonRow}>
                <Button
                  icon="account-cog"
                  mode="outlined"
                  onPress={() => {
                    router.push(
                      `/organization/${currentOrganization?.id}/settings`
                    )
                    hideModal()
                  }}
                  style={styles.button}
                  testID="manage-org-button"
                >
                  Manage Org
                </Button>
                {currentOrganization?.subscriptionActive ? (
                  <Button
                    icon="account-group"
                    mode="outlined"
                    onPress={() => {
                      router.push('/users')
                      hideModal()
                    }}
                    style={styles.button}
                  >
                    My Team
                  </Button>
                ) : (
                  <Tooltip title="Requires active subscription">
                    <Button
                      disabled
                      icon="account-multiple"
                      mode="outlined"
                      onPress={() => {
                        router.push('/users')
                        hideModal()
                      }}
                    >
                      My Team
                    </Button>
                  </Tooltip>
                )}
              </View>
            )}
            <Divider
              style={{
                marginTop: theme.tokens.spacing[4],
              }}
            />
            {organizations?.map((org) => (
              <OrganizationButton
                key={org.id}
                logoId={org.logo?.id}
                name={org.name}
                onPress={() => {
                  if (currentOrganization?.id !== org.id) {
                    setCurrentOrganization({
                      organization: {
                        id: org.id,
                        adoptionContract: org.adoptionContract,
                        logo: org.logo,
                        qrCode: org.qrCode,
                        name: org.name,
                      },
                    })
                  }
                  hideModal()
                }}
                organizationId={org.id}
              />
            ))}
            <TouchableRipple
              accessibilityRole="button"
              borderless={true}
              onPress={() => {
                router.push('/getting-started/setup')
                hideModal()
              }}
              style={styles.newRescueButton}
              testID="create-organization-button"
            >
              <View style={styles.newRescueContent}>
                <Icon
                  color={theme.colors.onSurfaceVariant}
                  size={24}
                  source="plus-circle-outline"
                />
                <Text style={styles.newRescueText}>New Rescue</Text>
              </View>
            </TouchableRipple>
          </View>
          <View
            style={{
              marginTop: theme.tokens.spacing[4],
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text style={styles.versionText}>
              {Platform.OS === 'web'
                ? AppVersions.expoVersion
                : AppVersions.nativeVersion}
              -{AppVersions.gitSha}
            </Text>
            {isEmployee && (
              <>
                <Text style={styles.versionText}>
                  Git Sha: {AppVersions.gitSha}
                </Text>
                <Text style={styles.versionText}>
                  Native Version: {AppVersions.nativeVersion}
                </Text>
                <Text style={styles.versionText}>
                  JS Bundle Version: {AppVersions.expoVersion}
                </Text>
                {Platform.OS !== 'web' && (
                  <Text style={styles.versionText}>
                    Runtime Version: {AppVersions.runtimeVersion?.slice(0, 9)}
                  </Text>
                )}
              </>
            )}
          </View>
        </View>
      </Modal>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      marginRight: theme.tokens.spacing[3],
    },
    button: {
      flex: 1,
      minWidth: 150,
    },
    buttonRow: {
      flexDirection: 'row',
      gap: theme.tokens.spacing[2],
      justifyContent: 'space-evenly',
      marginTop: theme.tokens.spacing[2],
    },
    innerContainer: {
      backgroundColor: theme.colors.surface,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      // We've disabled this for android for now, because the shadow blips on screen yet after the modal is dismissed
      // elevation: 2,
      gap: theme.tokens.spacing[2],
      padding: theme.tokens.spacing[4],
      shadowColor: theme.colors.shadow,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 8,
    },
    logo: {
      marginBottom: theme.tokens.spacing[4],
      opacity: 0.9,
    },
    logoContainer: {
      alignItems: 'center',
      marginBottom: theme.tokens.spacing[2],
    },
    modalContent: {
      alignSelf: 'center',
      backgroundColor: theme.colors.surfaceVariant,
      borderRadius: theme.tokens.containerBorderRadius * 2,
      elevation: 4,
      paddingHorizontal: theme.tokens.spacing[3],
      paddingVertical: theme.tokens.spacing[4],
      shadowColor: theme.colors.shadow,
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.15,
      shadowRadius: 12,
    },
    newRescueButton: {
      backgroundColor: theme.colors.surface,
      borderRadius: theme.tokens.containerBorderRadius,
      marginTop: theme.tokens.spacing[2],
      padding: theme.tokens.spacing[2],
    },
    newRescueContent: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.tokens.spacing[2],
      justifyContent: 'center',
    },
    newRescueText: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 14,
      fontWeight: '500',
    },
    root: {
      maxWidth: 400,
      minWidth: '95%',
    },
    rowContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.surface,
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      marginVertical: theme.tokens.spacing[1],
      padding: theme.tokens.spacing[3],
    },
    title: {
      color: theme.colors.onSurface,
      fontSize: 16,
      fontWeight: 'bold',
    },
    versionText: {
      color: theme.colors.onSurfaceDisabled,
      fontSize: 12,
      marginTop: theme.tokens.spacing[1],
    },
  }
})

export default memo(SettingsModal)
