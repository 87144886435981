import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'expo-router'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import FormFieldText from '../../components/form/FormFieldText'
import Page from '../../components/shared/Page'
import UserService from '../../services/userService'

const formSchema = z.object({
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
})

type FormData = z.infer<typeof formSchema>

const FirstRun = () => {
  const router = useRouter()
  const { styles } = useStyles(stylesheet)
  const updateMutation = UserService.useUpdateMutation()
  const { user: currentUser } = UserService.useCurrentUser()

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form

  const onSubmit = async (data: FormData) => {
    await updateMutation.mutateAsync({
      ...data,
      id: currentUser?.id || '',
      isFirstRunRequired: false,
    })
    router.dismissTo('/')
  }

  return (
    <Page requiresSubscription={false} viewId="first-run">
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerContainer}>
            <Text variant="headlineMedium">Welcome to Rescuebase!</Text>
            <Text style={styles.subtitle} variant="titleMedium">
              Let's get to know you better
            </Text>
          </View>

          <View style={styles.formContainer}>
            <FormFieldText
              control={control}
              errors={errors}
              fieldName="firstName"
              label="First Name"
              required={true}
            />
            <FormFieldText
              control={control}
              errors={errors}
              fieldName="lastName"
              label="Last Name"
              required={true}
            />
            <Button
              contentStyle={styles.buttonContent}
              disabled={isSubmitting}
              loading={isSubmitting}
              mode="contained"
              onPress={handleSubmit(onSubmit)}
            >
              Continue
            </Button>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoText} variant="bodyMedium">
              This information will be used to personalize your experience and
              help your team identify you.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  buttonContent: {
    paddingVertical: theme.tokens.spacing[3],
  },
  container: {
    flex: 1,
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    gap: theme.tokens.spacing[6],
  },
  formContainer: {
    gap: theme.tokens.spacing[4],
  },
  headerContainer: {
    alignItems: 'center',
    gap: theme.tokens.spacing[2],
  },
  infoContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    padding: theme.tokens.spacing[4],
  },
  infoText: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
}))

export default FirstRun
