import { Link } from 'expo-router'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import useUserRoles from '../../hooks/useUserRoles'
import { useAppStore } from '../../store/useAppStore'
import {
  formatTierName,
  getTierLimit,
  isAtOrExceedingTierLimit,
} from '../../utils/purchases/billing'
import trpc from '../../utils/trpc'

interface Props {
  requiresSubscription?: boolean
}

const BillingMessage = ({ requiresSubscription = true }: Props) => {
  const { styles } = useStyles(stylesheet)
  const { isUserAdmin } = useUserRoles()
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const fosterCountQuery = trpc.foster.currentMonthFosterCount.useQuery(
    {
      organizationId: currentOrganization?.id ?? '',
    },
    {
      enabled: !!currentOrganization?.id,
    }
  )

  // This could be undefined for several reasons, but the most common is that the organization data is refreshing due to app load
  // or the user switching organizations. In either case, we don't want to show the billing message until the organization data is refreshed
  // and we know we have fresh subscription data.
  if (
    currentOrganization?.subscriptionTier === undefined &&
    !currentOrganization?.subscriptionActive === false
  ) {
    return null
  }

  const currentCount = fosterCountQuery.data?.count ?? 0
  const tierLimit = getTierLimit(currentOrganization?.subscriptionTier)
  const isExactlyAtLimit = currentCount === tierLimit

  if (!currentOrganization) {
    return null
  }

  // Only show if we're at or above the limit
  if (
    !requiresSubscription ||
    !isAtOrExceedingTierLimit(
      currentCount,
      currentOrganization.subscriptionActive ?? false,
      currentOrganization.subscriptionTier
    )
  ) {
    return null
  }

  return (
    <View
      style={
        isExactlyAtLimit
          ? { ...styles.banner, ...styles.warningBanner }
          : styles.banner
      }
      testID="deactivated-banner"
    >
      <View style={styles.content}>
        <Text
          style={
            isExactlyAtLimit
              ? { ...styles.title, ...styles.warningTitle }
              : styles.title
          }
          variant="titleMedium"
        >
          {!isExactlyAtLimit
            ? 'Foster Quota Exceeded'
            : 'Foster Quota Limit Reached'}
        </Text>
        {isUserAdmin ? (
          <Text
            style={
              isExactlyAtLimit
                ? { ...styles.message, ...styles.warningMessage }
                : styles.message
            }
          >
            {!isExactlyAtLimit ? (
              <>
                Your organization has {currentCount} fosters this month,
                exceeding the
                {!currentOrganization.subscriptionTier
                  ? ` free tier limit of ${getTierLimit()} fosters`
                  : currentOrganization.subscriptionTier === 'small-rescue'
                    ? ` ${formatTierName('small-rescue')} limit of ${getTierLimit('small-rescue')} fosters`
                    : ` ${formatTierName('medium-rescue')} limit of ${getTierLimit('medium-rescue')} fosters`}
                . Please upgrade your subscription to continue managing fosters.
              </>
            ) : (
              <>
                Your organization has reached {currentCount} fosters this month,
                which is your
                {!currentOrganization.subscriptionTier
                  ? ' free tier limit'
                  : ` ${formatTierName(currentOrganization.subscriptionTier)} limit`}
                . Adding more fosters will require a subscription upgrade.
              </>
            )}
          </Text>
        ) : (
          <Text
            style={
              isExactlyAtLimit
                ? { ...styles.message, ...styles.warningMessage }
                : styles.message
            }
          >
            {!isExactlyAtLimit
              ? 'Your organization requires a subscription upgrade to continue. Please contact your administrator.'
              : 'Your organization has reached its foster limit. Please contact your administrator about upgrading the subscription.'}
          </Text>
        )}

        {isUserAdmin && (
          <View style={styles.buttonContainer}>
            <Link
              asChild
              href={`/organization/${currentOrganization.id}/settings`}
            >
              <Button
                contentStyle={styles.buttonContent}
                icon="arrow-right"
                mode="contained"
                style={
                  isExactlyAtLimit
                    ? { ...styles.button, ...styles.warningButton }
                    : styles.button
                }
              >
                View Subscription Options
              </Button>
            </Link>
          </View>
        )}
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  banner: {
    backgroundColor: theme.colors.errorContainer,
    borderRadius: theme.tokens.containerBorderRadius,
    marginHorizontal: 20,
    marginVertical: 20,
  },
  button: {
    backgroundColor: theme.colors.error,
  },
  buttonContainer: {
    marginTop: theme.tokens.spacing[4],
  },
  buttonContent: {
    flexDirection: 'row-reverse',
  },
  content: {
    padding: theme.tokens.spacing[4],
  },
  message: {
    color: theme.colors.onErrorContainer,
    fontSize: 15,
    marginTop: theme.tokens.spacing[2],
  },
  title: {
    color: theme.colors.error,
    fontWeight: '600',
  },
  warningBanner: {
    backgroundColor: theme.colors.primaryContainer,
  },
  warningButton: {
    backgroundColor: theme.colors.primary,
  },
  warningMessage: {
    color: theme.colors.onPrimaryContainer,
  },
  warningTitle: {
    color: theme.colors.primary,
  },
}))

export default BillingMessage
