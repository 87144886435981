import { useLocalSearchParams, useRouter } from 'expo-router'
import { useEffect } from 'react'
import { View } from 'react-native'
import { ActivityIndicator, Text } from 'react-native-paper'
import Snackbar from '../../components/snackbar/Snackbar'
import { verifyEmail } from '../../utils/auth/auth'

const Verify = () => {
  const router = useRouter()

  // This doesn't work on web because the hash is not included in the URL
  const routeParams = useLocalSearchParams<{
    rid: string
    token: string
    tenantId: string
    '#': string
  }>()

  useEffect(() => {
    async function verify() {
      const response = await verifyEmail(routeParams.token)

      if (response.status === 'OK') {
        router.navigate('/')
      } else {
        Snackbar.error('There was a problem verifying your email')
      }
    }

    verify()
  }, [routeParams.token, router])

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActivityIndicator animating={true} size="large" />
      <Text style={{ marginTop: 10 }}>
        Validating your login credentials...
      </Text>
    </View>
  )
}

export default Verify
