import { useGlobalSearchParams } from 'expo-router'
import FosterService from '../../../services/fosterService'
import SaveStatus from '../../shared/SaveStatus'

const FosterDetailHeaderContent = () => {
  // This header lives outside of the FosterDetail component, and useLocalSearchParams won't re-render on navigation changes up here.
  // So instead of using FosterService.useCurrentFoster() we use useGlobalSearchParams to get the fosterId from the URL.
  const { id: fosterId } = useGlobalSearchParams<{ id: string }>()

  const { foster } = FosterService.useFoster(fosterId)
  const isSaving = FosterService.useIsSaving()

  return (
    <SaveStatus
      isSaving={isSaving}
      updatedAt={foster?.updatedAt}
      updatedBy={`${foster?.updatedBy?.firstName} ${foster?.updatedBy?.lastName}`}
    />
  )
}

export default FosterDetailHeaderContent
