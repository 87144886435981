import * as Application from 'expo-application'
import Constants from 'expo-constants'
import * as Updates from 'expo-updates'
import DocumentType from './src/enums/documentType'

const { expoGoConfig } = Constants

export function ApiRoot() {
  const ip = expoGoConfig?.debuggerHost?.split(':')?.[0]

  if (ip) {
    return `http://${ip}:8080`
  } else {
    return process.env.EXPO_PUBLIC_API_ROOT || 'http://localhost:8080'
  }
}

export enum Feature {
  DatadogRUM = 'datadog_rum',
  Employee = 'employee',
  PasswordAuth = 'password_auth',
  WebApp = 'web_app',
}

export const AppVersions = {
  expoVersion: Constants.expoConfig?.version,
  gitSha: Constants.expoConfig?.extra?.eas?.gitSha,
  nativeVersion: Application.nativeApplicationVersion,
  runtimeVersion: Updates.runtimeVersion,
}

export const DocumentSelectorTypes: { label: string; value: DocumentType }[] = [
  { label: 'Adoption Contract', value: DocumentType.AdoptionContract },
  { label: 'Medical Record', value: DocumentType.MedicalRecord },
  { label: 'Intake Document', value: DocumentType.IntakeDocument },
  { label: 'Owner Surrender Form', value: DocumentType.OwnerSurrenderForm },
  { label: 'Trial Run Agreement', value: DocumentType.TrialRunAgreement },
]

export const KeyboardOffset = 62
