import {
  Control,
  Controller,
  FieldValues,
  FormState,
  Path,
} from 'react-hook-form'
import { View } from 'react-native'
import { Checkbox, CheckboxItemProps } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../shared/Skeleton'

interface Props<C extends FieldValues> {
  fieldName: Path<C>
  formState: FormState<C>
  required: boolean
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldCheckbox = <C extends FieldValues>({
  fieldName,
  formState,
  required,
  control,
  isLoading,
  style,
  onBlur,
  ...props
}: Props<C> & CheckboxItemProps) => {
  const { styles } = useStyles(stylesheet)

  return (
    <View style={style}>
      <Skeleton isLoading={isLoading}>
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, onBlur: formOnBlur, value } }) => (
            <View {...props}>
              <Checkbox.Item
                mode="android"
                position="leading"
                {...props}
                labelStyle={styles.label}
                onPress={() => {
                  formState.isDirty = true
                  onChange(!value)
                  formOnBlur()
                  onBlur?.()
                }}
                status={value ? 'checked' : 'unchecked'}
                style={styles.container}
                testID={`${fieldName}-checkboxInput`}
              />
            </View>
          )}
          rules={{
            required: required,
          }}
        />
      </Skeleton>
    </View>
  )
}

export default FormFieldCheckbox

const stylesheet = createStyleSheet(() => {
  return {
    container: {
      marginLeft: -8,
    },
    label: {
      paddingLeft: 4,
      textAlign: 'left',
    },
  }
})
