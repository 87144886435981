import { StateCreator } from 'zustand'

interface File {
  id: string
  name: string
  mimeType: string
  url: string | null
}

export type SubscriptionTier = 'small-rescue' | 'medium-rescue' | 'large-rescue'

export interface OrganizationSlice {
  currentOrganization: {
    organization?: {
      id: string
      adoptionContract: File | null
      logo: File | null
      qrCode: File | null
      name: string
      subscriptionTier?: SubscriptionTier
      subscriptionActive?: boolean
    } | null
  }
  setCurrentOrganization: (currentOrganization: {
    organization: {
      id: string
      adoptionContract: File | null
      logo: File | null
      qrCode: File | null
      name: string
      subscriptionTier?: SubscriptionTier
      subscriptionActive?: boolean
    } | null
  }) => void
}

export const createOrganizationSlice: StateCreator<OrganizationSlice> = (
  set
) => ({
  currentOrganization: {
    organization: undefined,
  },
  setCurrentOrganization: (
    currentOrganization: OrganizationSlice['currentOrganization']
  ) => {
    set({
      currentOrganization: {
        organization: currentOrganization?.organization || null,
      },
    })
  },
})
