import { ReactNode, useCallback, useState } from 'react'
import { RefreshControl } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { KeyboardOffset } from '../../../constants'
import SettingsModal from '../navigation/SettingsModal'
import BillingMessage from './BillingMessage'

interface Props {
  children: ReactNode
  onRefresh?: () => Promise<void>
  viewId: string
  requiresSubscription?: boolean
}

const Page = ({
  children,
  onRefresh,
  viewId,
  requiresSubscription = true,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const [refreshing, setRefreshing] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const hideModal = () => setModalVisible(false)

  const doRefresh = useCallback(() => {
    setRefreshing(true)
    onRefresh?.()
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false))
  }, [onRefresh])

  return (
    <KeyboardAwareScrollView
      bottomOffset={KeyboardOffset}
      contentContainerStyle={styles.container}
      contentInsetAdjustmentBehavior="automatic"
      nativeID={viewId}
      refreshControl={
        onRefresh ? (
          <RefreshControl onRefresh={doRefresh} refreshing={refreshing} />
        ) : undefined
      }
    >
      <BillingMessage requiresSubscription={requiresSubscription} />
      {children}
      <SettingsModal hideModal={hideModal} visible={modalVisible} />
    </KeyboardAwareScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  container: {
    flexDirection: 'column',
    gap: theme.tokens.spacing[4],
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
}))

export default Page
