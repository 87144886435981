import { differenceInMonths } from 'date-fns'
import { Control, FormState, UseFormSetValue } from 'react-hook-form'
import { View } from 'react-native'
import { useStyles } from 'react-native-unistyles'
import Species from '../../../enums/species'
import FosterService from '../../../services/fosterService'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import { FosterForm } from '../fosterFormSchema'
import FosterMedicalForm, {
  fosterMedicalFormStylesheet,
} from './FosterMedicalForm'
import FosterVaccinations from './vaccines/FosterVaccinations'

interface Props {
  control: Control<FosterForm, any>
  disabled: boolean
  formState: FormState<FosterForm>
  setValue: UseFormSetValue<FosterForm>
}

const FosterHealthCard = ({
  control,
  disabled,
  formState,
  setValue,
}: Props) => {
  const { styles: fosterMedicalFormStyles } = useStyles(
    fosterMedicalFormStylesheet
  )

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, isLoading } = FosterService.useFoster(fosterId)

  const showHeartwormTestFields =
    foster?.birthDate &&
    foster?.birthDate instanceof Date &&
    differenceInMonths(new Date(), foster?.birthDate) >= 6

  return (
    <View style={{ gap: 16 }}>
      <View>
        <FosterMedicalForm
          control={control}
          disabled={disabled}
          formState={formState}
          isLoading={isLoading}
          setValue={setValue}
          showHeartwormTestFields={showHeartwormTestFields ?? false}
          species={foster?.species as Species | undefined}
        >
          <FormFieldText
            control={control}
            disabled={disabled}
            errors={formState.errors}
            fieldName="microchipId"
            inputMode="numeric"
            isLoading={isLoading}
            label="🏷️ Microchip ID"
            required={false}
            style={fosterMedicalFormStyles.medicalInfoInput}
          />
          {foster?.deceasedDate && (
            <FormFieldDate
              control={control}
              disabled={disabled}
              errors={formState.errors}
              fieldName={'deceasedDate'}
              isLoading={isLoading}
              label="🪦 Date of Death"
              required={false}
              style={fosterMedicalFormStyles.medicalInfoInput}
            />
          )}
        </FosterMedicalForm>
      </View>
      <View>
        <Card title="Vaccinations">
          <FosterVaccinations
            control={control}
            disabled={disabled}
            formState={formState}
          />
        </Card>
      </View>
    </View>
  )
}

export default FosterHealthCard
