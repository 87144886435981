import { format } from 'date-fns'

const formatDate = (
  date: Date | null | undefined,
  dateFormat: string = 'M/dd/yy'
) => {
  if (!date) return date

  return format(date, dateFormat)
}

export const formatFriendlyDate = (
  date: Date | string | number | null | undefined
) => {
  if (!date) return null

  const d = new Date(date)
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  // If it's today
  if (d.toDateString() === today.toDateString()) {
    return 'Today'
  }

  // If it's yesterday
  if (d.toDateString() === yesterday.toDateString()) {
    return 'Yesterday'
  }

  // If it's within this year, don't show the year
  if (d.getFullYear() === today.getFullYear()) {
    return d.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
  }

  // For older dates, show the full date
  return d.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export default formatDate
