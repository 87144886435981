import { Purchases } from '@revenuecat/purchases-js'

async function configure(userId: string) {
  Purchases.configure(
    process.env.EXPO_PUBLIC_REVENUE_CAT_WEB_API_KEY || '',
    userId
  )
}

async function getCustomerInfo() {
  if (!Purchases.isConfigured()) {
    return
  }

  return Purchases.getSharedInstance().getCustomerInfo()
}

async function isConfigured() {
  return Purchases.isConfigured()
}

async function logIn(userId: string) {
  return Purchases.getSharedInstance().changeUser(userId)
}

async function showPaywall() {
  const customerInfo = await Purchases.getSharedInstance().getCustomerInfo()
  window.location.href = `${process.env.EXPO_PUBLIC_REVENUE_CAT_WEB_PAYWALL_LINK}/${customerInfo.originalAppUserId}`
}

export default {
  configure,
  getCustomerInfo,
  isConfigured,
  logIn,
  showPaywall,
}
