import { useActionSheet } from '@expo/react-native-action-sheet'
import { createRef, useState } from 'react'
import { findNodeHandle, View, ViewProps } from 'react-native'
import { IconButton, Text, TouchableRipple } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import PaymentMethod from '../../../enums/paymentMethod'
import trpc from '../../../utils/trpc'
import ReturnAdoptionAction from '../actions/ReturnAdoptionAction'
import AdoptionForm from './AdoptionForm'

interface Props {
  adoption: {
    id: string
    adoptedByName: string | null
    adoptedByEmail: string | null
    adoptedName: string | null
    adoptionDate: Date | null
    returnDate: Date | null
    returnReason: string | null
    paymentMethod: PaymentMethod | null
    userId: string
  }
  disabled: boolean
  fosterName: string
}

const AdoptionListItem = ({
  adoption,
  disabled,
  fosterName,
  ...props
}: Props & ViewProps) => {
  const { styles } = useStyles(stylesheet)
  const { showActionSheetWithOptions } = useActionSheet()
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false)
  const [isReturnAdoptionModalOpen, setIsReturnAdoptionModalOpen] =
    useState(false)

  const _anchorRef = createRef<any>()

  const editAdoptionMutation = trpc.foster.adoptions.update.useMutation()

  const onPress = (returned: boolean) => {
    const options = ['Edit', 'Cancel']

    if (!returned) {
      options.unshift('Mark as Returned')
    }

    const editButtonIndex = options.findIndex((option) => option === 'Edit')
    const markReturnedButton = options.findIndex(
      (option) => option === 'Mark as Returned'
    )
    const cancelButtonIndex = options.findIndex((option) => option === 'Cancel')

    showActionSheetWithOptions(
      {
        anchor: findNodeHandle(_anchorRef.current) || undefined,
        useModal: false,
        options,
        cancelButtonIndex,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case editButtonIndex:
            setIsEditingModalOpen(true)
            break

          case markReturnedButton:
            setIsReturnAdoptionModalOpen(true)
            break
        }
      }
    )
  }

  return (
    <>
      <AdoptionForm
        adoption={adoption}
        mutation={editAdoptionMutation}
        onClose={() => setIsEditingModalOpen(false)}
        open={isEditingModalOpen}
      />
      <ReturnAdoptionAction
        onClose={() => setIsReturnAdoptionModalOpen(false)}
        open={isReturnAdoptionModalOpen}
      />
      <TouchableRipple
        accessibilityRole="button"
        borderless
        disabled={disabled}
        onPress={() => onPress(!!adoption.returnDate)}
        ref={_anchorRef}
        style={styles.pressable}
        {...props}
      >
        <View style={styles.documentContainer}>
          {UnistylesRuntime.breakpoint !== 'xs' ? (
            <>
              <Text>
                Adopted by {adoption.adoptedByName} on{' '}
                {adoption.adoptionDate?.toDateString()}
              </Text>

              <Text style={[styles.nameColumnFlex]} testID="adoptedBy-text">
                {adoption.adoptedByName}
              </Text>
              <Text
                style={[styles.columnFlex, styles.columnContent]}
                testID="adoptedName-text"
              >
                {adoption.adoptedName}
              </Text>
              <Text
                style={[styles.columnFlex, styles.columnContent]}
                testID="adoptionDate-text"
              >
                {adoption.adoptionDate?.toDateString()}
              </Text>
              <Text
                style={[styles.columnFlex, styles.columnContent]}
                testID="returnDate-text"
              >
                {adoption.returnDate?.toDateString()}
              </Text>
            </>
          ) : (
            <View style={styles.adoptionRow}>
              <View style={styles.flex1}>
                <Text style={styles.adoptedByName} testID="adoptedByName-text">
                  Adopted by {adoption.adoptedByName}
                </Text>
                <Text testID="adoptionDate-text">
                  Adoption Date: {adoption.adoptionDate?.toLocaleDateString()}
                </Text>
                {!!adoption.adoptedName && (
                  <Text
                    style={[styles.nameColumnFlex]}
                    testID="adoptedName-text"
                  >
                    Renamed to {adoption.adoptedName}
                  </Text>
                )}
                <Text style={styles.columnContent}>
                  Adopter’s Email: {adoption.adoptedByEmail}
                </Text>
                {adoption.returnDate && (
                  <Text
                    style={[styles.columnFlex, styles.columnContent]}
                    testID="returnDate-text"
                  >
                    Return Date: {adoption.returnDate?.toLocaleDateString()}
                  </Text>
                )}
                {adoption.returnReason && (
                  <Text style={styles.columnContent}>
                    Return Reason: {adoption.returnReason}
                  </Text>
                )}
              </View>
              <IconButton
                disabled={disabled}
                icon="dots-vertical"
                onPress={() => onPress(!!adoption.returnDate)}
                ref={_anchorRef}
                selected
              />
            </View>
          )}
        </View>
      </TouchableRipple>
    </>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    adoptedByName: {
      fontWeight: 'bold',
    },
    adoptionRow: {
      alignItems: 'flex-start',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      gap: 3,
    },
    columnContent: {
      color: {
        xs: theme.colors.subtitle,
        sm: theme.colors.onBackground,
      },
      fontSize: {
        xs: 12,
        sm: 14,
      },
    },
    columnFlex: {
      flex: {
        xs: 1,
        sm: 0.4,
      },
    },
    documentContainer: {
      alignItems: {
        xs: 'flex-start',
        sm: 'center',
      },
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      gap: {
        xs: theme.tokens.spacing[1],
        sm: 0,
      },
      height: {
        sm: 40,
      },
      padding: 8,
    },
    flex1: {
      flex: 1,
    },
    nameColumnFlex: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    pressable: {
      borderRadius: theme.tokens.containerBorderRadius,
    },
  }
})

export default AdoptionListItem
