import { useState } from 'react'
import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import PaymentMethod from '../../../enums/paymentMethod'
import FosterService from '../../../services/fosterService'
import sharedStyles from '../../../styles/styles'
import FormFieldCheckbox from '../../form/FormFieldCheckbox'
import Card from '../../shared/Card'
import NewAdoptionAction from '../actions/NewAdoptionAction'
import AdoptionListItem from '../adoptions/AdoptionListItem'
import { FosterForm } from '../fosterFormSchema'

interface Props {
  control: Control<FosterForm, any>
  disabled: boolean
  formState: FormState<FosterForm>
}

const FosterAdoptionsCard = ({ control, disabled, formState }: Props) => {
  const { styles } = useStyles(stylesheet)
  const [newAdoptionRecordOpen, setNewAdoptionRecordOpen] = useState(false)

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster } = FosterService.useFoster(fosterId)

  const hasAdoptions = !!foster?.adoptions?.length

  return (
    <View>
      <Card title="Adoption Info">
        <View style={styles.togglesContainer}>
          <FormFieldCheckbox
            control={control}
            disabled={disabled}
            fieldName="readyForAdoption"
            formState={formState}
            label="Ready For Adoption"
            required={false}
            status="checked"
            style={styles.toggles}
          />
          <FormFieldCheckbox
            control={control}
            disabled={disabled}
            fieldName="isAdopted"
            formState={formState}
            label="Adopted"
            required={false}
            status="checked"
            style={styles.toggles}
          />
        </View>

        {!foster?.adoptions.filter((adoption) => !adoption.returnDate)
          .length && (
          <View style={styles.buttonRow}>
            <Button
              disabled={disabled}
              mode="contained"
              onPress={() => setNewAdoptionRecordOpen(true)}
            >
              Add Adoption Info
            </Button>
          </View>
        )}
        {hasAdoptions && UnistylesRuntime.breakpoint !== 'xs' && (
          <View style={styles.headerContainer}>
            <Text style={[styles.nameColumnFlex, styles.headerColumn]}>
              Adopted By
            </Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>
              Pet's Adopted Name
            </Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>
              Adoption Date
            </Text>
            <Text style={[styles.columnFlex, styles.headerColumn]}>
              Returned On
            </Text>
          </View>
        )}
        {hasAdoptions &&
          foster?.adoptions.map((adoption, index) => {
            return (
              <AdoptionListItem
                adoption={{
                  ...adoption,
                  paymentMethod: adoption.paymentMethod as PaymentMethod,
                }}
                disabled={disabled}
                fosterName={foster.name}
                key={`${adoption.updatedAt}-${adoption.id}`}
                testID={`adoption-list-item-${index}`}
              />
            )
          })}
        <NewAdoptionAction
          onClose={() => setNewAdoptionRecordOpen(false)}
          open={newAdoptionRecordOpen}
        />
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonRow: {
      ...sharedStyles(theme).buttonRow,
      marginBottom: theme.tokens.spacing[4],
    },
    columnFlex: {
      flex: {
        xs: 1,
        sm: 0.4,
      },
    },
    headerColumn: {
      color: theme.colors.primary,
      fontWeight: 'bold',
    },
    headerContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.tokens.spacing[3],
    },
    nameColumnFlex: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    toggles: {
      flexBasis: {
        xs: '40%',
        md: '30%',
      },
      // flexGrow: 1,
    },
    togglesContainer: {
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      flex: 0.8,
    },
  }
})

export default FosterAdoptionsCard
