import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationEventMap,
  MaterialTopTabNavigationOptions,
} from '@react-navigation/material-top-tabs'
import { ParamListBase, TabNavigationState } from '@react-navigation/native'
import { withLayoutContext } from 'expo-router'
import { Dimensions } from 'react-native'
import { SystemBars } from 'react-native-edge-to-edge'
import { Icon } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterActionButton from '../../../../components/fosters/FosterActionButton'
import { FosterFormProvider } from '../../../../components/fosters/FosterFormContext'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'

const { Navigator } = createMaterialTopTabNavigator()

const emptyComponent = () => <></>

export const MaterialTopTabs = withLayoutContext<
  MaterialTopTabNavigationOptions,
  typeof Navigator,
  TabNavigationState<ParamListBase>,
  MaterialTopTabNavigationEventMap
>(Navigator)

const renderTabBarIcon = (source: string) => {
  const TabBarIcon = (props: { focused: boolean; color: string }) => (
    <Icon color={props.color} size={24} source={source} />
  )
  TabBarIcon.displayName = `TabBarIcon(${source})`
  return TabBarIcon
}

const TabLayout = () => {
  const { styles, theme } = useStyles(stylesheet)

  const tabBarBottomInset = useSafeAreaInsets().bottom - 10

  const { fosterId } = FosterService.useCurrentFoster()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  if (!currentOrganization?.id) {
    return null
  }

  return (
    <FosterFormProvider>
      <MaterialTopTabs
        backBehavior="none"
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
        screenOptions={{
          tabBarContentContainerStyle: {
            paddingTop: 5,
            paddingBottom: tabBarBottomInset,
          },
          animationEnabled: true,
          lazy: true,
          swipeEnabled: true,
          tabBarStyle: [
            styles.tabBarStyle,
            {
              elevation: 10,
            },
          ],
          tabBarIndicator: emptyComponent,
          sceneStyle: { backgroundColor: theme.colors.background },
        }}
        style={{ backgroundColor: theme.colors.background }}
        tabBarPosition="bottom"
      >
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="index"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarLabel: 'Profile',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: renderTabBarIcon('information-outline'),
            tabBarButtonTestID: 'profile-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="health"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarLabel: 'Health',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: renderTabBarIcon('needle'),
            tabBarButtonTestID: 'health-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="adoptions"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarLabel: 'Adoption',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: renderTabBarIcon('heart-outline'),
            tabBarButtonTestID: 'adoptions-tab',
          }}
        />
        <MaterialTopTabs.Screen
          initialParams={{ id: fosterId }}
          name="documents"
          options={{
            tabBarActiveTintColor: theme.colors.primary,
            tabBarInactiveTintColor: theme.colors.secondary,
            tabBarLabel: 'Documents',
            tabBarLabelStyle: styles.tabBarLabelStyle,
            tabBarIcon: renderTabBarIcon('file-document-multiple-outline'),
            tabBarButtonTestID: 'documents-tab',
          }}
        />
      </MaterialTopTabs>
      <FosterActionButton
        currentOrganizationId={currentOrganization.id}
        fosterId={fosterId}
        style={{
          paddingRight: 10,
          paddingBottom: 76 + tabBarBottomInset,
        }}
      />
      <SystemBars style="auto" />
    </FosterFormProvider>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    tabBarLabelStyle: {
      fontSize: 10,
    },
    tabBarStyle: {
      backgroundColor: theme.colors.surface,
      elevation: 8,
      shadowColor: theme.colors.shadow,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: theme.tokens.spacing[2],
    },
  }
})

export default TabLayout
