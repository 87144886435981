import React from 'react'

const DatadogProviderWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <>{children}</>
}

export default DatadogProviderWrapper
