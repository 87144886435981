import { useEffect, useState } from 'react'
import { Snackbar as PaperSnackbar, Portal, useTheme } from 'react-native-paper'
import SnackbarManager from './Snackbar'

type State = {
  visible: boolean
  title?: string
  error?: boolean
}

const SnackbarComponent = () => {
  const theme = useTheme()
  const [state, setState] = useState<State>({ visible: false, error: false })

  useEffect(() => {
    SnackbarManager.setListener((title, error) =>
      setState({ visible: true, title, error })
    )
    return () => SnackbarManager.setListener(null)
  }, [])

  return (
    <Portal>
      <PaperSnackbar
        action={{
          label: 'Dismiss',
          textColor: state.error
            ? theme.colors.error
            : theme.colors.inversePrimary,
        }}
        duration={6000}
        onDismiss={() => setState({ ...state, visible: false })}
        testID="snackbar"
        visible={state.visible}
      >
        {state.title}
      </PaperSnackbar>
    </Portal>
  )
}

export default SnackbarComponent
