import { useCallback, useState } from 'react'
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form'
import {
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { HelperText, Menu } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import WeightUnit from '../../../../enums/weightUnit'
import TextInput from '../../../form/TextInput'
import Skeleton from '../../../shared/Skeleton'

interface Props<C extends FieldValues, E extends FieldErrors> {
  control: Control<C>
  disabled?: boolean
  errors?: E
  fieldName?: Path<C>
  weightUnitFieldName?: Path<C>
  isLoading?: boolean
  submit?: () => void
  weightDate?: Date | null
  style?: StyleProp<ViewStyle>
}

const getUnitLabel = (unit: WeightUnit) => {
  switch (unit) {
    case WeightUnit.Pounds:
      return 'lb'
    case WeightUnit.Ounces:
      return 'oz'
    case WeightUnit.Grams:
      return 'g'
    default:
      return 'lb'
  }
}

const getUnitMenuTitle = (unit: WeightUnit) => {
  switch (unit) {
    case WeightUnit.Pounds:
      return 'Pounds (lb)'
    case WeightUnit.Ounces:
      return 'Ounces (oz)'
    case WeightUnit.Grams:
      return 'Grams (g)'
    default:
      return 'Pounds (lb)'
  }
}

const FormFieldWeight = <C extends FieldValues, E extends FieldErrors>({
  control,
  disabled,
  errors,
  fieldName = 'weight' as Path<C>,
  weightUnitFieldName = 'weightUnit' as Path<C>,
  isLoading,
  submit,
  weightDate,
  style,
  ..._props
}: Props<C, E>) => {
  const { styles, theme } = useStyles(stylesheet)
  const [menuVisible, setMenuVisible] = useState(false)
  const dateString = weightDate?.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })

  const { field } = useController({
    name: fieldName,
    control,
  })

  const { field: weightUnitField } = useController({
    name: weightUnitFieldName,
    control,
  })

  const openMenu = () => setMenuVisible(true)
  const closeMenu = () => setMenuVisible(false)

  const selectUnit = (unit: WeightUnit) => {
    weightUnitField.onChange(unit)
    closeMenu()
  }

  const currentUnit = weightUnitField.value || WeightUnit.Pounds
  const hasError = errors?.[fieldName]

  const handleChange = useCallback(
    (value: string) => {
      field.onChange(value)
      if (submit) submit()
    },
    [field, submit]
  )

  const unitButton = (
    <Menu
      anchor={
        <TouchableOpacity
          accessibilityRole="button"
          disabled={disabled}
          onPress={openMenu}
          style={styles.unitButton}
        >
          <Text
            style={[
              styles.unitText,
              disabled && { color: theme.colors.onSurfaceDisabled },
            ]}
          >
            {getUnitLabel(currentUnit)} ▾
          </Text>
        </TouchableOpacity>
      }
      onDismiss={closeMenu}
      visible={menuVisible}
    >
      <Menu.Item
        onPress={() => selectUnit(WeightUnit.Pounds)}
        testID="weight-unit-pounds"
        title={getUnitMenuTitle(WeightUnit.Pounds)}
      />
      <Menu.Item
        onPress={() => selectUnit(WeightUnit.Ounces)}
        testID="weight-unit-ounces"
        title={getUnitMenuTitle(WeightUnit.Ounces)}
      />
      <Menu.Item
        onPress={() => selectUnit(WeightUnit.Grams)}
        testID="weight-unit-grams"
        title={getUnitMenuTitle(WeightUnit.Grams)}
      />
    </Menu>
  )

  return (
    <View style={[style, styles.root]}>
      <Skeleton isLoading={isLoading}>
        <TextInput
          accessibilityHint={`Enter the weight in ${getUnitMenuTitle(currentUnit).toLowerCase()}`}
          accessibilityLabel="Weight input"
          editable={!disabled}
          error={!!hasError}
          keyboardType="numeric"
          label="Weight"
          onBlur={field.onBlur}
          onChangeText={handleChange}
          rightIcon={unitButton}
          rightIconRight={8}
          rightIconTop={22}
          testID={`${fieldName}-textInput`}
          value={field.value?.toString() || ''}
        />
        {hasError && (
          <HelperText style={styles.errorText} type="error">
            {errors?.[fieldName]?.message as string}
          </HelperText>
        )}
        {dateString && (
          <Text style={styles.helperText}>Last Updated: {dateString}</Text>
        )}
      </Skeleton>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  errorText: {
    color: theme.colors.error,
    fontSize: 12,
    marginTop: 4,
  },
  helperText: {
    color: theme.colors.onSurfaceVariant,
    fontSize: 12,
    marginTop: 4,
  },
  root: {
    marginBottom: theme.tokens.spacing[2],
    marginTop: 4,
  },
  unitButton: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    paddingHorizontal: 2,
  },
  unitText: {
    color: theme.colors.primary,
    fontSize: 14,
    fontWeight: '500',
  },
}))

export default FormFieldWeight
