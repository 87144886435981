import { router } from 'expo-router'
import { Platform, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

const UserListHeaderContent = () => {
  const { theme, styles } = useStyles(stylesheet)

  return (
    <View style={styles.root}>
      {Platform.OS !== 'web' && (
        <IconButton
          icon="magnify"
          onPress={() => {
            router.push(`/search`)
          }}
          testID="search-icon-button"
        />
      )}
      <IconButton
        icon="account-plus"
        iconColor={theme.colors.primary}
        onPress={() => {
          router.push(`/users/invite`)
        }}
      />
    </View>
  )
}

const stylesheet = createStyleSheet(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
}))

export default UserListHeaderContent
