import { useGlobalSearchParams } from 'expo-router'
import OrganizationNewVaccine from '../../../../../components/organization/cards/forms/OrganizationEditVaccine'

const NewVaccine = () => {
  const { vaccineId } = useGlobalSearchParams<{ vaccineId: 'string' }>()

  return <OrganizationNewVaccine vaccineId={vaccineId} />
}

export default NewVaccine
