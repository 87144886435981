import { useMemo } from 'react'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'
import OrganizationSettingTable from './OrganizationSettingTable'

interface Props {
  disabled: boolean
}

const OrganizationMedicalInfoCard = ({ disabled }: Props) => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const medicalInfoQuery = trpc.medicalInfo.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  const mappedData = useMemo(() => {
    return (
      medicalInfoQuery.data?.map((medicalInfo) => ({
        id: medicalInfo.id,
        name: medicalInfo.type,
        species: medicalInfo.species || '',
        minAgeWeeks: medicalInfo.minAgeWeeks,
      })) || []
    )
  }, [medicalInfoQuery.data])

  return (
    <Card title="Medical Info">
      <OrganizationSettingTable
        addButtonTestID="add-medical-info-button"
        data={mappedData}
        disabled={disabled}
        editRoute={`/organization/${currentOrganization?.id}/medical-info`}
      />
    </Card>
  )
}

export default OrganizationMedicalInfoCard
