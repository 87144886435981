import { router } from 'expo-router'
import { useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { FAB } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import useFile from '../../hooks/useFile'
import useFosterOwner from '../../hooks/useFosterOwner'
import FosterService from '../../services/fosterService'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import NewAdoptionAction from './actions/NewAdoptionAction'
import ReturnAdoptionAction from './actions/ReturnAdoptionAction'
import useDeleteAction from './actions/useDeleteAction'
import useMarkDeceased from './actions/useMarkDeceased'
import usePrintPdfAction from './actions/usePrintPdfAction'

type FosterActionButtonProps = {
  currentOrganizationId: string
  fosterId: string
  style?: StyleProp<ViewStyle>
}

const FosterActionButton = ({
  currentOrganizationId,
  fosterId,
  style,
}: FosterActionButtonProps) => {
  const { theme, styles } = useStyles(stylesheet)

  const { foster } = FosterService.useFoster(fosterId)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const [state, setState] = useState({ open: false })
  const [newAdoptionRecordOpen, setNewAdoptionRecordOpen] = useState(false)
  const [returnAdoptionOpen, setReturnAdoptionOpen] = useState(false)
  const onStateChange = ({ open }: { open: boolean }) => setState({ open })

  const fosterOwner = useFosterOwner(fosterId)

  const pdfAction = usePrintPdfAction(fosterId, currentOrganizationId)
  const { deleteFoster } = useDeleteAction()
  const { markDeceased } = useMarkDeceased()

  const copyFosterMutation = trpc.foster.copy.useMutation()

  const { openFile, scanFiles } = useFile()

  const { open } = state

  return (
    <>
      <FAB.Group
        actions={[
          ...(fosterOwner
            ? [
                {
                  icon: 'delete',
                  label: 'Delete',
                  style: styles.iconButton,
                  color: theme.colors.error,
                  onPress: () => {
                    deleteFoster()
                  },
                  testID: 'delete-action',
                },
                ...(!foster?.deceasedDate
                  ? [
                      {
                        icon: 'grave-stone',
                        label: 'Mark Deceased',
                        style: styles.iconButton,
                        onPress: () => {
                          markDeceased()
                        },
                        testID: 'mark-deceased-action',
                      },
                    ]
                  : []),
                {
                  icon: 'content-copy',
                  label: 'Copy Foster',
                  style: styles.iconButton,
                  onPress: async () => {
                    await copyFosterMutation.mutateAsync(
                      {
                        id: fosterId,
                        organizationId: currentOrganizationId,
                      },
                      {
                        onSuccess: (newFoster) => {
                          router.back()
                          router.push(`/fosters/${newFoster.id}`)
                        },
                      }
                    )
                  },
                  testID: 'copy-foster-action',
                },
              ]
            : []),
          ...(currentOrganization?.adoptionContract?.id
            ? [
                {
                  icon: 'clipboard-flow',
                  label: 'Print Adoption Contract',
                  style: styles.iconButton,
                  onPress: async () => {
                    await openFile({
                      fileId: currentOrganization?.adoptionContract?.id,
                      fileName: currentOrganization?.adoptionContract?.name,
                      mimeType: currentOrganization?.adoptionContract?.mimeType,
                    })
                  },
                  testID: 'print-adoption-contract-action',
                },
              ]
            : []),
          {
            icon: 'newspaper-variant',
            label: 'Print Cover Sheet',
            style: styles.iconButton,
            onPress: async () => {
              await pdfAction.openPDF()
            },
            testID: 'print-cover-sheet-action',
          },
          ...(fosterOwner
            ? [
                foster?.isAdopted
                  ? {
                      icon: 'clipboard-minus',
                      label: 'Mark Returned',
                      style: styles.iconButton,
                      onPress: () => {
                        setReturnAdoptionOpen(true)
                      },
                      testID: 'adoption-return-action',
                    }
                  : {
                      icon: 'clipboard-plus',
                      label: 'New Adoption Record',
                      style: styles.iconButton,
                      onPress: () => {
                        setNewAdoptionRecordOpen(true)
                      },
                      testID: 'new-adoption-record-action',
                    },
                {
                  icon: 'scanner',
                  label: 'Scan Documents',
                  style: styles.iconButton,
                  onPress: async () => {
                    const uris = await scanFiles()
                    console.log(uris)
                    router.push(
                      `/fosters/${fosterId}/documents?scanUris=${uris?.join(',')}`
                    )
                  },
                  testID: 'scan-documents-action',
                },
              ]
            : []),
        ]}
        icon={open ? 'close' : 'menu'}
        onStateChange={onStateChange}
        open={open}
        style={style}
        testID="foster-action-button"
        visible={currentOrganization?.subscriptionActive === true}
      />
      <NewAdoptionAction
        onClose={() => setNewAdoptionRecordOpen(false)}
        open={newAdoptionRecordOpen}
      />
      <ReturnAdoptionAction
        onClose={() => setReturnAdoptionOpen(false)}
        open={returnAdoptionOpen}
      />
    </>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  iconButton: {
    backgroundColor: theme.colors.surface,
  },
}))

export default FosterActionButton
