import { SubscriptionTier } from '../../store/organizationStore'

export const getTierName = (tier?: SubscriptionTier | string) => {
  switch (tier) {
    case 'small-rescue':
      return 'Small Rescue'
    case 'medium-rescue':
      return 'Medium Rescue'
    case 'large-rescue':
      return 'Large Rescue'
    default:
      return 'Free Tier'
  }
}

export const getTierLimit = (tier?: SubscriptionTier | string) => {
  switch (tier) {
    case 'small-rescue':
      return 10
    case 'medium-rescue':
      return 100
    case 'large-rescue':
      return Infinity
    default:
      return 5 // Free tier
  }
}

export const getTierLimitText = (tier?: SubscriptionTier | string) => {
  switch (tier) {
    case 'small-rescue':
      return 'Up to 10 fosters per month'
    case 'medium-rescue':
      return 'Up to 100 fosters per month'
    case 'large-rescue':
      return 'Unlimited fosters'
    default:
      return 'Up to 5 fosters per month'
  }
}

export const getTierLevel = (tier?: SubscriptionTier | string): number => {
  switch (tier) {
    case 'small-rescue':
      return 1
    case 'medium-rescue':
      return 2
    case 'large-rescue':
      return 3
    default:
      return 0
  }
}

export const getRequiredTierForCount = (
  fosterCount: number
): SubscriptionTier | undefined => {
  if (fosterCount <= 5) return undefined // Free tier
  if (fosterCount <= 10) return 'small-rescue'
  if (fosterCount <= 100) return 'medium-rescue'
  return 'large-rescue'
}

export const formatTierName = (tier?: SubscriptionTier | string) => {
  return tier ? tier.replace('-', ' ') : 'free tier'
}

export const isWithinTierLimit = (
  count: number,
  tier?: SubscriptionTier | string
) => {
  if (!tier && count <= 5) return true
  if (tier === 'small-rescue' && count <= 10) return true
  if (tier === 'medium-rescue' && count <= 100) return true
  if (tier === 'large-rescue') return true
  return false
}

export const isAtOrExceedingTierLimit = (
  currentCount: number,
  subscriptionActive: boolean,
  tier?: SubscriptionTier | string
) => {
  return !subscriptionActive || currentCount > getTierLimit(tier)
}
