import { useNavigation } from 'expo-router'
import { useCallback, useEffect } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Portal, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterService from '../../services/fosterService'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import FosterDetailHeaderContent from '../navigation/header/FosterDetailHeaderContent'
import Page from '../shared/Page'

const headerRight = () => <FosterDetailHeaderContent />

interface Props {
  children: React.ReactNode
  viewId: string
}

const FosterTab = (props: Props) => {
  const navigation = useNavigation()

  const { styles, theme } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()
  const { refreshFoster } = FosterService.useFoster(fosterId)

  const fosterName = FosterService.useCurrentFosterName()

  const currentOrganizationId =
    useAppStore.use.currentOrganization().organization?.id

  const pdfQuery = trpc.foster.pdf.useQuery(
    {
      id: fosterId,
      organizationId: currentOrganizationId || '',
    },
    {
      enabled: false,
    }
  )

  useEffect(() => {
    navigation.getParent()?.setOptions({
      title: fosterName || 'Add Foster',
      showSearchBar: false,
      headerRight,
    })
  }, [fosterName, navigation])

  const onRefresh = useCallback(async () => {
    await refreshFoster()
  }, [refreshFoster])

  return (
    <>
      {pdfQuery.isFetching && (
        <Portal>
          <View style={styles.loadingContainer}>
            <View style={styles.loadingContent}>
              <Text>Generating a Cover Sheet</Text>
              <ActivityIndicator color={theme.colors.primary} size="large" />
            </View>
          </View>
        </Portal>
      )}
      <Page onRefresh={onRefresh} viewId={props.viewId}>
        {props.children}
      </Page>
    </>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flex: 1,
    justifyContent: 'center',
  },
  loadingContent: {
    backgroundColor: theme.colors.surface,
    borderRadius: theme.tokens.containerBorderRadius,
    gap: theme.tokens.spacing[4],
    padding: theme.tokens.spacing[4],
  },
}))

export default FosterTab
