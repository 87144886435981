import { zodResolver } from '@hookform/resolvers/zod'
import { router, useNavigation } from 'expo-router'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Divider, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import Species from '../../../../enums/species'
import useAutoSave from '../../../../hooks/useAutoSave'
import OrganizationService from '../../../../services/organizationService'
import trpc from '../../../../utils/trpc'
import FormFieldCheckbox from '../../../form/FormFieldCheckbox'
import FormFieldMultiSelect from '../../../form/FormFieldMultiSelect'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldSpecies from '../../../fosters/cards/formFields/FormFieldSpecies'
import Card from '../../../shared/Card'
import Page from '../../../shared/Page'

const vaccineFormSchema = z.object({
  autoSchedule: z.boolean().default(false),
  delayFromVaccineId: z.string().uuid().nullable().optional(),
  initialBoosterCount: z.coerce.number().int().nullable().optional(),
  initialBoosterCadenceMaxWeeks: z.coerce.number().int().nullable().optional(),
  initialBoosterCadenceMinWeeks: z.coerce.number().int().nullable().optional(),
  initialBoosterRecurringAgeWeeks: z.coerce
    .number()
    .int()
    .nullable()
    .optional(),
  longTermBoosterCadence: z.coerce.number().int().max(3).nullable().optional(),
  minAgeWeeks: z.coerce.number().int().positive(),
  name: z.string(),
  species: z.nativeEnum(Species),
  supersededByVaccineIds: z.array(z.string().uuid()).optional(),
})

type VaccineForm = z.infer<typeof vaccineFormSchema>

interface Props {
  vaccineId: string
}

const OrganizationEditVaccine = ({ vaccineId }: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()
  const utils = trpc.useContext()

  const isNewVaccine = vaccineId === 'new'

  const { currentOrganization } = OrganizationService.useCurrentOrganization()

  const vaccineQuery = trpc.vaccine.byId.useQuery(
    { id: vaccineId },
    {
      enabled: !isNewVaccine,
    }
  )

  const vaccineListQuery = trpc.vaccine.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  const vaccine =
    vaccineListQuery.data?.find((v) => v.id === vaccineId) || vaccineQuery.data

  const createVaccineMutation = trpc.vaccine.create.useMutation()
  const updateVaccineMutation = trpc.vaccine.update.useMutation()
  const deleteVaccineMutation = trpc.vaccine.delete.useMutation()

  const defaultValues = useMemo(() => {
    return {
      autoSchedule: vaccine?.autoSchedule || false,
      delayFromVaccineId: vaccine?.delayFromVaccineId || null,
      initialBoosterCount: vaccine?.initialBoosterCount || null,
      initialBoosterCadenceMaxWeeks:
        vaccine?.initialBoosterCadenceMaxWeeks || null,
      initialBoosterCadenceMinWeeks:
        vaccine?.initialBoosterCadenceMinWeeks || null,
      initialBoosterRecurringAgeWeeks:
        vaccine?.initialBoosterRecurringAgeWeeks || null,
      longTermBoosterCadence: vaccine?.longTermBoosterCadence || null,
      minAgeWeeks: vaccine?.minAgeWeeks || 0,
      name: vaccine?.name || '',
      species: (vaccine?.species || null) as Species,
      supersededByVaccineIds: vaccine?.supersededByVaccineIds || [],
    }
  }, [vaccine])

  const form = useForm<VaccineForm>({
    defaultValues,
    resolver: zodResolver(vaccineFormSchema),
  })

  const { control, formState, handleSubmit, reset } = form

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSave = (data: VaccineForm) => {
    if (isNewVaccine && createVaccineMutation.isLoading) {
      return
    }

    const payload = {
      ...data,
      delayFromVaccineId: data.delayFromVaccineId || null,
      initialBoosterCount: data.initialBoosterCount || null,
      initialBoosterCadenceMaxWeeks: data.initialBoosterCadenceMaxWeeks || null,
      initialBoosterCadenceMinWeeks: data.initialBoosterCadenceMinWeeks || null,
      initialBoosterRecurringAgeWeeks:
        data.initialBoosterRecurringAgeWeeks || null,
      longTermBoosterCadence: data.longTermBoosterCadence || null,
      minAgeWeeks: data.minAgeWeeks,
      organizationId: currentOrganization?.id || '',
      species: (data.species || null) as 'Dog' | 'Cat',
      supersededByVaccineIds: data.supersededByVaccineIds || [],
    }

    if (isNewVaccine) {
      createVaccineMutation.mutate(payload, {
        onSuccess: (newVaccine) => {
          utils.vaccine.list.invalidate()
          router.replace(
            `/organization/${currentOrganization?.id}/vaccines/${newVaccine.id}`
          )
        },
      })
    } else {
      updateVaccineMutation.mutate({ id: vaccineId, ...payload })
    }
  }

  useEffect(() => {
    if (!isNewVaccine && !vaccine?.name) {
      return
    }

    navigation.setOptions({
      title: isNewVaccine ? 'New Vaccine' : `${vaccine?.name} Settings` || '',
    })
  })

  const wrappedOnSave = () => {
    if (isNewVaccine && createVaccineMutation.isLoading) {
      return
    }
    handleSubmit(onSave)()
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: wrappedOnSave,
  })

  if (!vaccineListQuery.isSuccess) {
    return null
  }

  const vaccineList =
    vaccineListQuery?.data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((v) => ({
        label: v.name,
        value: v.id,
      })) || []

  const isFormDisabled =
    !currentOrganization?.subscriptionActive ||
    (isNewVaccine && createVaccineMutation.isLoading)

  return (
    <Page viewId="organization-new-vaccine">
      <Card title="Vaccine Info">
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Basic Information</Text>
          <View style={styles.vaccineRow}>
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="name"
              label="Name"
              required={true}
              style={styles.flexContainer}
            />
            <FormFieldSpecies
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              style={styles.flexContainer}
            />
          </View>
          <Text>
            Set the minimum age (in weeks) when an animal can receive this
            vaccine. This will be used to calculate the initial due date when
            adding the vaccine to a foster profile.
          </Text>
          <FormFieldText
            control={control}
            disabled={isFormDisabled}
            errors={formState.errors}
            fieldName="minAgeWeeks"
            keyboardType="numeric"
            label="Minimum Age in weeks"
            required={true}
          />
          <Text>
            Optional: Enable automatic scheduling to include this vaccine in new
            foster profiles when no previous vaccination history exists.
          </Text>
          <FormFieldCheckbox
            control={control}
            disabled={isFormDisabled}
            fieldName="autoSchedule"
            formState={formState}
            label="Auto Schedule"
            required={false}
            status="checked"
          />
          <Divider />
        </View>
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Booster Schedule</Text>
          <Text>
            Optional: If this vaccine requires follow-up boosters, specify the
            valid time range (in weeks) between shots.
          </Text>
          <View style={styles.vaccineRow}>
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="initialBoosterCadenceMinWeeks"
              keyboardType="numeric"
              label="Min Weeks"
              required={true}
              style={styles.flexContainer}
            />
            <Text>-</Text>
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="initialBoosterCadenceMaxWeeks"
              keyboardType="numeric"
              label="Max Weeks"
              required={true}
              style={styles.flexContainer}
            />
          </View>
          <Text>Optional: Number of follow-up booster shots needed.</Text>
          <FormFieldText
            control={control}
            disabled={isFormDisabled}
            errors={formState.errors}
            fieldName="initialBoosterCount"
            keyboardType="numeric"
            label="Initial Booster count"
            required={true}
          />
          <Text>
            Optional: After completing any initial boosters, specify how often
            (in years) this vaccine needs to be readministered.
          </Text>
          <FormFieldText
            control={control}
            disabled={isFormDisabled}
            errors={formState.errors}
            fieldName="longTermBoosterCadence"
            keyboardType="numeric"
            label="Yearly cadence"
            required={true}
          />
          <Text>Optional: Age-based Boosters</Text>
          <Text>
            Some vaccines need to be given repeatedly until the animal reaches a
            certain age, regardless of how many shots they've received. For
            example, puppies need DHPP vaccines until they're 16 weeks old.
            Enter the target age (in weeks) if this applies.
          </Text>
          <Text>
            When left blank, the system will only use the booster count settings
            above if specified.
          </Text>
          <FormFieldText
            control={control}
            disabled={isFormDisabled}
            errors={formState.errors}
            fieldName="initialBoosterRecurringAgeWeeks"
            keyboardType="numeric"
            label="Repeat Boosters until age"
            required={true}
          />
          <Divider />
        </View>
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Alternative Vaccines</Text>
          <Text>
            Optional: Link equivalent vaccines that can be used as substitutes.
            For example, DHPP/Lepto can be used in place of DHLPP. This helps
            the scheduling system recognize valid alternative vaccinations.
          </Text>
          <FormFieldMultiSelect
            control={control}
            data={vaccineList}
            disable={isFormDisabled}
            errors={formState.errors}
            fieldName="supersededByVaccineIds"
            label="Alternative Vaccines"
            required={false}
            search={true}
            searchField="label"
            searchPlaceholder="Search for a vaccine"
          />
        </View>
        <Button
          disabled={isFormDisabled}
          mode="contained"
          onPress={async () =>
            deleteVaccineMutation.mutate(
              {
                id: vaccineId,
              },
              {
                onSuccess: async () => {
                  await utils.vaccine.list.invalidate()
                  router.dismissTo(
                    `/organization/${currentOrganization?.id}/settings`
                  )
                },
              }
            )
          }
          testID="delete-vaccine-button"
        >
          Delete
        </Button>
      </Card>
    </Page>
  )
}

export default OrganizationEditVaccine

const stylesheet = createStyleSheet((theme) => {
  return {
    flexContainer: {
      flex: 1,
    },
    vaccineRow: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.tokens.spacing[4],
    },
    vaccineSection: {
      gap: theme.tokens.spacing[4],
      marginBottom: theme.tokens.spacing[4],
    },
  }
})
