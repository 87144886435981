import FontAwesome from '@expo/vector-icons/FontAwesome'
import { isSameDay } from 'date-fns'
import { Platform, View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { DashboardDay } from './DashboardTypes'

interface Props {
  day: DashboardDay
}

const DashboardTimeline = ({ day }: Props) => {
  const { styles, theme } = useStyles(stylesheet)
  return (
    <View style={styles.root}>
      <View style={styles.dateLabelContainer}>
        {day.isOverdue ? (
          <View style={styles.overdueLabelContainer}>
            <Text style={styles.dateLabelSmallFont}>Past{'\n'}Due</Text>
            <FontAwesome
              color={theme.colors.error}
              name="exclamation-circle"
              size={40}
            />
          </View>
        ) : (
          <>
            <Text style={styles.dateLabelSmallFont}>
              {day.dueDate.toLocaleDateString('en-US', {
                weekday: 'short',
              })}
            </Text>
            <View
              style={
                isSameDay(day.dueDate, new Date())
                  ? styles.dateLabelTodayContainer
                  : {}
              }
            >
              <Text
                style={
                  isSameDay(day.dueDate, new Date())
                    ? styles.dateLabelToday
                    : styles.dateLabelMediumFont
                }
              >
                {(day.dueDate || new Date()).toLocaleDateString('en-US', {
                  day: 'numeric',
                })}
              </Text>
            </View>
          </>
        )}
      </View>

      {day.fosters.length > 1 && (
        <View
          style={[
            styles.timelineBar,
            day.isOverdue
              ? styles.timelineBarOverdueColor
              : styles.timelineBarColor,
          ]}
        />
      )}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    dateLabelContainer: {
      alignItems: 'center',
      height: 70,
      justifyContent: 'center',
      width: 58,
    },
    dateLabelMediumFont: {
      color: theme.colors.onSurface,
      fontSize: 24,
      ...Platform.select({
        ios: {
          fontWeight: '600',
        },
        android: {
          fontWeight: '700',
        },
      }),
    },
    dateLabelSmallFont: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 14,
      textAlign: 'center',
      ...Platform.select({
        ios: {
          fontWeight: '500',
        },
        android: {
          fontWeight: '600',
        },
      }),
    },
    dateLabelToday: {
      color: theme.colors.onPrimary,
      fontSize: 24,
      ...Platform.select({
        ios: {
          fontWeight: '600',
        },
        android: {
          fontWeight: '700',
        },
      }),
    },
    dateLabelTodayContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 100,
      height: 40,
      justifyContent: 'center',
      marginTop: 3,
      width: 40,
    },
    overdueLabelContainer: {
      alignItems: 'center',
      flex: 1,
      gap: theme.tokens.spacing[2],
    },
    root: {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: -3,
    },
    timelineBar: {
      alignSelf: 'center',
      borderRadius: 100,
      flex: 1,
      marginBottom: 8,
      marginTop: 20,
      width: 4,
    },
    timelineBarColor: {
      backgroundColor: theme.colors.secondary,
      opacity: 0.5,
    },
    timelineBarOverdueColor: {
      backgroundColor: theme.colors.error,
      opacity: 0.7,
    },
  }
})

export default DashboardTimeline
