import FontAwesome from '@expo/vector-icons/FontAwesome'
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import { Tabs, useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { UnistylesRuntime, useStyles } from 'react-native-unistyles'
import useNotificationObserver from '../../../hooks/useNotificationObserver'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'

const RootTabLayout = () => {
  useNotificationObserver()

  const { theme } = useStyles()

  const navigation = useNavigation()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const tabBarBottomInset = useSafeAreaInsets().bottom

  const events = trpc.dashboard.events.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  ).data

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  const screenOptions: BottomTabNavigationOptions = {
    tabBarActiveTintColor: theme.colors.primary,
    animation: 'shift',
    headerShown: false,
    tabBarPosition: 'bottom',
    tabBarBadgeStyle: {
      backgroundColor: theme.colors.error,
      fontSize: 10,
    },
    tabBarItemStyle: {
      padding: 4,
    },
    tabBarStyle: {
      backgroundColor: theme.colors.surface,
      borderTopColor: theme.colors.cardBorder,
      height: tabBarBottomInset + 54,
      paddingTop: 4,
    },
  }

  if (['lg', 'xl'].includes(UnistylesRuntime.breakpoint)) {
    screenOptions.tabBarPosition = 'left'
    screenOptions.tabBarVariant = 'material'
    screenOptions.tabBarStyle = {
      ...(typeof screenOptions.tabBarStyle === 'object'
        ? screenOptions.tabBarStyle
        : {}),
      minWidth: 120,
      maxWidth: 180,
      backgroundColor: theme.colors.background,
      borderRightWidth: 1,
      borderRightColor: theme.colors.cardBorder,
      borderTopWidth: 0,
    }

    screenOptions.tabBarLabelStyle = {
      fontSize: 13,
      marginLeft: 10,
    }

    screenOptions.tabBarIconStyle = {
      width: 28,
      height: 28,
    }

    screenOptions.tabBarActiveBackgroundColor = theme.colors.surfaceVariant
    screenOptions.tabBarActiveTintColor = theme.colors.primary
    screenOptions.tabBarInactiveTintColor = theme.colors.onSurfaceVariant
  }

  return (
    <Tabs
      backBehavior="history"
      initialRouteName="dashboard"
      screenOptions={screenOptions}
    >
      <Tabs.Screen
        name="dashboard"
        options={{
          title: 'Dashboard',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="home" size={28} />
          ),
          tabBarBadge: events?.[0]?.overdueCount || undefined,
          tabBarButtonTestID: 'dashboard-tab',
          tabBarAccessibilityLabel: 'Dashboard Tab',
        }}
      />
      <Tabs.Screen
        name="fosters"
        options={{
          lazy: false,
          title: 'Fosters',
          tabBarIcon: ({ color }) => (
            <FontAwesome color={color} name="paw" size={28} />
          ),
          tabBarButtonTestID: 'fosters-tab',
          tabBarAccessibilityLabel: 'Fosters Tab',
        }}
      />

      <Tabs.Screen name="index" redirect />
    </Tabs>
  )
}

export default RootTabLayout
