import { useRouter } from 'expo-router'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Page from '../../components/shared/Page'

const Volunteer = () => {
  const router = useRouter()
  const { styles } = useStyles(stylesheet)

  return (
    <Page requiresSubscription={false} viewId="welcome-volunteer">
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerContainer}>
            <Text variant="headlineMedium">Thanks for volunteering!</Text>
            <Text style={styles.subtitle} variant="titleMedium">
              To get started with Rescuebase, you'll need an invitation from
              your rescue organization.
            </Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle} variant="titleMedium">
              Please ask your rescue coordinator to:
            </Text>
            <View style={styles.listContainer}>
              <Text style={styles.listItem} variant="bodyMedium">
                • Add you as a volunteer in Rescuebase
              </Text>
              <Text style={styles.listItem} variant="bodyMedium">
                • Send you an invitation to join their organization
              </Text>
            </View>
          </View>

          <Button
            contentStyle={styles.buttonContent}
            mode="outlined"
            onPress={() => router.back()}
          >
            Go Back
          </Button>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  buttonContent: {
    paddingVertical: theme.tokens.spacing[3],
  },
  container: {
    flex: 1,
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    gap: theme.tokens.spacing[6],
  },
  headerContainer: {
    alignItems: 'center',
    gap: theme.tokens.spacing[2],
  },
  infoContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    padding: theme.tokens.spacing[4],
  },
  infoTitle: {
    color: theme.colors.onSurfaceVariant,
    marginBottom: theme.tokens.spacing[2],
  },
  listContainer: {
    gap: theme.tokens.spacing[2],
  },
  listItem: {
    color: theme.colors.onSurfaceVariant,
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
}))

export default Volunteer
