import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton } from 'moti/skeleton'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Linking, StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { Avatar, Button, ButtonProps, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import UserService from '../../../../services/userService'
import FormFieldText from '../../../form/FormFieldText'
import NotFound from '../../../shared/NotFound'

interface Props {
  buttonStyle?: StyleProp<ViewStyle>
  buttonMode?: ButtonProps['mode']
  formContainerStyle: StyleProp<ViewStyle>
  titleStyle: StyleProp<TextStyle>
}

const UserAvatar = () => {
  const { user, isLoading } = UserService.useCurrentUser()

  if (isLoading || !user) {
    return <Skeleton height={100} radius="round" width={100} />
  }

  return (
    <Avatar.Image
      size={80}
      source={{
        uri: `https://www.gravatar.com/avatar/${user.emailHash}?s=200`,
      }}
    />
  )
}

const UserProfile = ({
  buttonStyle,
  buttonMode,
  formContainerStyle,
  titleStyle,
}: Props) => {
  const { styles } = useStyles(stylesheet)

  const { user, isLoading, currentOrganization } = UserService.useCurrentUser()

  const updateMutation = UserService.useUpdateMutation()

  const defaultValues = { ...user }

  const form = useForm<FormData>({
    // mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  const onSubmit = async (data: FormData) => {
    if (!currentOrganization?.id) {
      return
    }

    updateMutation.mutate(data, {
      onSuccess: (updatedData) => {
        reset({ ...updatedData })
      },
    })
  }

  if (!isLoading && !user) return <NotFound />

  return (
    <View>
      <Text style={titleStyle} variant="titleLarge">
        Basic Profile
      </Text>
      <View>
        <View style={styles.avatarContainer}>
          <UserAvatar />
          <View style={styles.avatarTextContainer}>
            <Text style={styles.gravatarText} variant="bodySmall">
              Profile pictures are powered by Gravatar
            </Text>
            <Button
              mode="text"
              onPress={() => Linking.openURL('https://gravatar.com/profile')}
            >
              Change Photo
            </Button>
          </View>
        </View>
        <View style={formContainerStyle}>
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={errors}
            fieldName="firstName"
            isLoading={isLoading}
            label="First Name"
            required={true}
            style={{ flex: 2 }}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={errors}
            fieldName="lastName"
            isLoading={isLoading}
            label="Last Name"
            required={true}
            style={{ flex: 2 }}
          />
        </View>
        <Button
          disabled={isSubmitting || !isDirty}
          mode={buttonMode}
          onPress={handleSubmit(onSubmit)}
          style={buttonStyle}
        >
          Save
        </Button>
      </View>
    </View>
  )
}

const formSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
})

type FormData = z.infer<typeof formSchema>

const stylesheet = createStyleSheet((theme) => ({
  avatarContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[4],
  },
  avatarTextContainer: {
    flex: 1,
  },
  gravatarText: {
    color: theme.colors.subtitle,
    marginBottom: theme.tokens.spacing[2],
  },
}))

export default UserProfile
