import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { Button, ButtonProps, Text } from 'react-native-paper'
import { z } from 'zod'
import { ApiRoot } from '../../../../../constants'
import UserService from '../../../../services/userService'
import FormFieldText from '../../../form/FormFieldText'
import NotFound from '../../../shared/NotFound'
import Snackbar from '../../../snackbar/Snackbar'

interface Props {
  buttonStyle?: StyleProp<ViewStyle>
  buttonMode?: ButtonProps['mode']
  formContainerStyle: StyleProp<ViewStyle>
  titleStyle: StyleProp<TextStyle>
}

const UserEmail = ({
  buttonStyle,
  buttonMode,
  formContainerStyle,
  titleStyle,
}: Props) => {
  const { user, isLoading, currentOrganization } = UserService.useCurrentUser()

  const defaultValues = { ...user }

  const form = useForm<FormData>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  const onSubmit = async (data: FormData) => {
    if (!currentOrganization?.id) {
      return
    }

    const response = await fetch(`${ApiRoot()}/change-email`, {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const json = await response.json()

    if (response.ok && json.status === 'EMAIL_VERIFICATION_SENT') {
      Snackbar.info(
        'A verification email has been sent to your new email address.'
      )
    } else if (response.ok) {
      Snackbar.info('Your email address has been changed.')
    } else if (json.status === 'EMAIL_ALREADY_EXISTS_ERROR') {
      Snackbar.error(
        'This email is already in use by another user. Please try a different email.'
      )
    } else {
      Snackbar.error('There was an error changing your email address.')
    }
  }

  if (!isLoading && !user) return <NotFound />

  return (
    <View>
      <Text style={titleStyle} variant="titleLarge">
        Email Address
      </Text>
      <View>
        <View style={formContainerStyle}>
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={errors}
            fieldName="email"
            isLoading={isLoading}
            keyboardType="email-address"
            label="Email"
            required={false}
            style={{ flex: 3 }}
          />
        </View>
        <Button
          disabled={isSubmitting || !isDirty}
          mode={buttonMode}
          onPress={handleSubmit(onSubmit)}
          style={buttonStyle}
        >
          Change Email
        </Button>
      </View>
    </View>
  )
}

const formSchema = z.object({
  id: z.string().uuid(),
  email: z.string().email().toLowerCase(),
})

type FormData = z.infer<typeof formSchema>

export default UserEmail
