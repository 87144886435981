import * as ExpoUpdates from 'expo-updates'
import { useEffect, useState } from 'react'

const useExpoUpdateAtBoot = () => {
  const [isCheckingForUpdate, setIsCheckingForUpdate] = useState(true)

  useEffect(() => {
    if (__DEV__) {
      setIsCheckingForUpdate(false)
      return
    }

    const update = async () => {
      try {
        const updateCheck = await ExpoUpdates.checkForUpdateAsync()
        if (updateCheck.isAvailable) {
          await ExpoUpdates.fetchUpdateAsync()
          await ExpoUpdates.reloadAsync()
        }
      } catch (error) {
        console.error('Error during update process:', error)
      }

      setIsCheckingForUpdate(false)
    }

    update()
  }, [])

  return { isCheckingForUpdate }
}

export default useExpoUpdateAtBoot
