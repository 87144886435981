import { zodResolver } from '@hookform/resolvers/zod'
import * as Linking from 'expo-linking'
import { useRouter } from 'expo-router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Card, Text } from 'react-native-paper'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import FormFieldText from '../components/form/FormFieldText'
import Logo from '../components/navigation/Logo'
import Snackbar from '../components/snackbar/Snackbar'
import { useAppStore } from '../store/useAppStore'
import {
  clearLoginAttemptInfo,
  getCode,
  getLoginAttemptInfo,
  passwordSignIn,
  validateOtp,
} from '../utils/auth/auth'
import log from '../utils/datadog/log/log'
import { logError } from '../utils/log'

const REVIEWER_EMAILS = [
  'appstore@rescuebase.com',
  'playstore@rescuebase.com',
  'justinvdhooft+e2e@gmail.com',
]

const loginFormSchema = z.discriminatedUnion('state', [
  z.object({
    state: z.literal('email'),
    email: z.string().email('Please enter a valid email address'),
    password: z.string().optional(),
    otp: z.string().optional(),
  }),
  z.object({
    state: z.literal('otp'),
    email: z.string().optional(),
    password: z.string().optional(),
    otp: z.string(),
  }),
])

type LoginForm = z.infer<typeof loginFormSchema>

const Login = () => {
  const [showPasswordAuth, setShowPasswordAuth] = useState(false)
  const [otpError, setOtpError] = useState('')
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [isValidatingOtp, setIsValidatingOtp] = useState(false)
  const translateX = useSharedValue(0)

  const router = useRouter()
  const setAuth = useAppStore.use.setAuth()
  const { styles } = useStyles(stylesheet)

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: { state: 'email', email: '', password: '', otp: '' },
  })

  const email = watch('email')

  const firstPageStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
    position: 'absolute',
    width: '100%',
  }))

  const secondPageStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value + 400 }],
    position: 'absolute',
    width: '100%',
  }))

  const animateToPage = (page: 'email' | 'otp') => {
    translateX.value = withSpring(page === 'email' ? 0 : -400, {
      damping: 20,
      stiffness: 90,
    })
  }

  const handleAuth = async (success: boolean) => {
    if (success) {
      setAuth({ accessToken: undefined, isLoggedIn: true })
      await clearLoginAttemptInfo()
      router.replace('/')
    }
  }

  const onSubmitEmail = handleSubmit(async () => {
    setIsSigningIn(true)
    try {
      const { email, password } = getValues()

      if (!email) {
        Snackbar.error('Please enter your email')
        return
      }

      if (REVIEWER_EMAILS.includes(email.toLowerCase())) {
        if (!password) {
          Snackbar.error('Please enter your password')
          return
        }
        const response = await passwordSignIn(email, password)
        if (response.status === 'WRONG_CREDENTIALS_ERROR') {
          Snackbar.error('Email password combination is incorrect.')
        } else {
          await handleAuth(
            response.status !== 'FIELD_ERROR' &&
              response.status !== 'SIGN_IN_NOT_ALLOWED'
          )
        }
      } else {
        const response = await getCode(email)
        if (response.status !== 'SIGN_IN_UP_NOT_ALLOWED') {
          animateToPage('otp')
          reset({ state: 'otp', email, password: '', otp: '' })
        }
      }
    } catch (err: any) {
      log.error(err.message, err)
      Snackbar.error('Oops! Something went wrong.')
    } finally {
      setIsSigningIn(false)
    }
  })

  const onSubmitOtp = handleSubmit(
    async () => {
      setIsValidatingOtp(true)
      try {
        const { otp } = getValues()
        if (!otp) {
          setOtpError('Please enter the code sent to your email')
          return
        }

        const response = await validateOtp(otp)
        switch (response.status) {
          case 'OK':
            await handleAuth(true)
            break
          case 'INCORRECT_USER_INPUT_CODE_ERROR':
            setOtpError('The code you entered is invalid.')
            break
          case 'EXPIRED_USER_INPUT_CODE_ERROR':
            setOtpError('The code you entered is expired. Please log in again.')
            break
          default:
            Snackbar.error('Login failed. Please try again')
            await handleAuth(false)
        }
      } catch (err: any) {
        log.error(err.message, err)
        Snackbar.error('Oops! Something went wrong.')
      } finally {
        setIsValidatingOtp(false)
      }
    },
    (err) => {
      console.log('err ', err)
    }
  )

  useEffect(() => {
    getLoginAttemptInfo()
      .then((info) => {
        if (info) {
          animateToPage('otp')
          reset({
            state: 'otp',
            email: getValues('email'),
            password: '',
            otp: '',
          })
        }
      })
      .catch(logError)
  }, [])

  const openPrivacyPolicy = () => {
    Linking.openURL('https://www.rescuebase.com/privacy.html')
  }

  const openTermsOfService = () => {
    Linking.openURL('https://www.rescuebase.com/terms.html')
  }

  useEffect(() => {
    setShowPasswordAuth(REVIEWER_EMAILS.includes((email || '').toLowerCase()))
  }, [email])

  const renderEmailPage = () => (
    <Animated.View style={firstPageStyle}>
      <View style={styles.pageContent}>
        <Text style={styles.headingText} variant="titleLarge">
          Sign In
        </Text>

        <FormFieldText
          accessibilityHint="Enter your email address"
          accessibilityLabel="Email Address"
          autoCapitalize="none"
          autoComplete="email"
          control={control}
          errors={errors}
          fieldName="email"
          inputMode="email"
          label="Email Address"
          onSubmitEditing={onSubmitEmail}
          required={true}
          returnKeyType="go"
          testID="magic-link-input"
          textContentType="emailAddress"
        />

        {showPasswordAuth ? (
          <>
            <FormFieldText
              accessibilityHint="Enter your password"
              accessibilityLabel="Password"
              control={control}
              errors={errors}
              fieldName="password"
              label="Password"
              onSubmitEditing={onSubmitEmail}
              required={true}
              returnKeyType="go"
              secureTextEntry
              style={{ marginTop: 16 }}
              testID="password-input"
            />
            <Button
              disabled={isSigningIn}
              loading={isSigningIn}
              mode="contained"
              onPress={onSubmitEmail}
              style={styles.button}
              testID="password-login-button"
            >
              Login
            </Button>
          </>
        ) : (
          <Button
            disabled={isSigningIn}
            loading={isSigningIn}
            mode="contained"
            onPress={onSubmitEmail}
            style={styles.button}
            testID="magic-link-button"
          >
            Send magic link
          </Button>
        )}
      </View>
    </Animated.View>
  )

  const renderOtpPage = () => (
    <Animated.View style={secondPageStyle}>
      <View style={styles.pageContent}>
        <Text style={styles.otpText} variant="titleSmall">
          If the email you provided matches an existing account, we'll send a
          temporary code to that address.
        </Text>
        <Text
          style={styles.otpErrorText}
          testID="otp-error-message"
          variant="bodyMedium"
        >
          {otpError || ' '}
        </Text>

        <FormFieldText
          accessibilityHint="Enter the login code sent to your email"
          accessibilityLabel="Login Code"
          control={control}
          fieldName="otp"
          inputMode="numeric"
          label="Enter your login code"
          onSubmitEditing={onSubmitOtp}
          required={true}
          returnKeyType="go"
          testID="otp-input"
          textContentType="oneTimeCode"
        />

        <Button
          disabled={isValidatingOtp}
          loading={isValidatingOtp}
          mode="contained"
          onPress={onSubmitOtp}
          style={styles.button}
          testID="otp-button"
        >
          Sign in
        </Button>

        <Button
          mode="outlined"
          onPress={() => {
            animateToPage('email')
            clearLoginAttemptInfo().catch(logError)
          }}
          style={styles.button}
          testID="otp-back-button"
        >
          Back
        </Button>
      </View>
    </Animated.View>
  )

  return (
    <View style={styles.container}>
      <Logo style={styles.logoStyle} />
      <Card style={styles.card}>
        <View style={styles.cardContainer}>
          <View style={styles.contentContainer}>
            <View style={styles.pagerContainer}>
              {renderEmailPage()}
              {renderOtpPage()}
            </View>
            <Text style={styles.legalText}>
              By continuing, you agree to our{' '}
              <Text onPress={openTermsOfService} style={styles.legalTextLink}>
                Terms of Service
              </Text>{' '}
              and{' '}
              <Text onPress={openPrivacyPolicy} style={styles.legalTextLink}>
                Privacy Policy
              </Text>
            </Text>
          </View>
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  button: {
    marginTop: 16,
  },
  card: {
    backgroundColor: theme.colors.surface,
    elevation: 4,
    shadowColor: theme.colors.shadow,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  cardContainer: {
    alignContent: 'center',
    maxWidth: 400,
    minWidth: 325,
    padding: 30,
  },
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    flex: 1,
    justifyContent: 'center',
    padding: 16,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    marginTop: 16,
  },
  headingText: {
    color: theme.colors.primary,
    marginBottom: theme.tokens.spacing[4],
  },
  legalText: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
  legalTextLink: {
    color: theme.colors.primary,
    textDecorationLine: 'underline',
  },
  logoStyle: {
    alignContent: 'center',
    flexBasis: 100,
    flexShrink: 1,
    justifyContent: 'center',
    marginBottom: 16,
  },
  otpErrorText: {
    color: theme.colors.error,
    marginHorizontal: 'auto',
    marginVertical: 8,
    maxWidth: {
      xs: 240,
      sm: undefined,
    },
    textAlign: 'center',
  },
  otpText: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
  pageContent: {
    width: '100%',
  },
  pagerContainer: {
    minHeight: 280,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
}))

export default Login
