import { BottomSheetView } from '@gorhom/bottom-sheet'
import { View } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown'
import { Button, SegmentedButtons } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../enums/species'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import GridFilterSimpleSwitch from './GridFilterBasicSwitch'

const GridFilterView = () => {
  const { styles, theme } = useStyles(stylesheet)

  const basicFilters = useAppStore.use.basicFilters()
  const resetBasicFilters = useAppStore.use.resetBasicFilters()
  const setBasicFilters = useAppStore.use.setBasicFilters()

  const advancedFilters = useAppStore.use.advancedFilters()
  const setAdvancedFilters = useAppStore.use.setAdvancedFilters()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const users = trpc.user.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
      includeDeactivated: true,
    },
    {
      enabled: !!currentOrganization,
    }
  )

  const volunteerList =
    users.data?.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })) || []

  return (
    <BottomSheetView style={styles.root}>
      <View style={styles.resetButtonRow}>
        <Button
          disabled={!basicFilters.isDirty}
          icon="restore"
          onPress={() => resetBasicFilters()}
        >
          Reset Filters
        </Button>
        <Button
          icon="filter-variant"
          onPress={() => {
            setBasicFilters({
              ...basicFilters,
              isActive: false,
            })
            setAdvancedFilters({
              ...advancedFilters,
              isActive: true,
            })
          }}
        >
          Advanced Filters
        </Button>
      </View>
      <>
        <SegmentedButtons
          buttons={[
            {
              icon: 'account',
              value: 'mine',
              label: 'My Fosters',
              testID: 'my-fosters-button',
            },
            {
              icon: 'account-group',
              value: 'all',
              label: 'All Fosters',
              testID: 'all-fosters-button',
            },
          ]}
          onValueChange={(value) => {
            setBasicFilters({
              ...basicFilters,
              filters: {
                ...basicFilters.filters,
                viewAll: value === 'all',
              },
            })
          }}
          style={styles.segmentedButtons}
          value={basicFilters.filters.viewAll ? 'all' : 'mine'}
        />
        {basicFilters.filters.viewAll && (
          <Dropdown
            activeColor={theme.colors.primaryContainer}
            containerStyle={{
              borderColor: theme.colors.background,
              backgroundColor: theme.colors.background,
            }}
            data={[
              { label: 'All Volunteers', value: undefined },
              ...volunteerList,
            ]}
            itemTextStyle={styles.text}
            labelField={'label'}
            onChange={(selected) => {
              setBasicFilters({
                ...basicFilters,
                filters: {
                  ...basicFilters.filters,
                  volunteerId: selected.value,
                },
              })
            }}
            placeholder="Filter by Volunteer"
            placeholderStyle={styles.text}
            search
            searchField="label"
            searchPlaceholder="Search for a volunteer"
            selectedTextStyle={[styles.text, styles.selectedText]}
            style={styles.filterContainer}
            value={basicFilters.filters.volunteerId}
            valueField={'value'}
          />
        )}
        <Dropdown
          activeColor={theme.colors.primaryContainer}
          containerStyle={{
            borderColor: theme.colors.background,
            backgroundColor: theme.colors.background,
          }}
          data={[
            { label: 'All Species', value: undefined },
            { label: 'Dogs', value: Species.Dog },
            { label: 'Cats', value: Species.Cat },
          ]}
          itemTextStyle={styles.text}
          labelField={'label'}
          onChange={(selected) => {
            setBasicFilters({
              ...basicFilters,
              filters: {
                ...basicFilters.filters,
                species: selected.value,
              },
            })
          }}
          placeholder="Species"
          placeholderStyle={styles.text}
          selectedTextStyle={[styles.text, styles.selectedText]}
          style={styles.filterContainer}
          value={basicFilters.filters.species}
          valueField={'value'}
        />
        <GridFilterSimpleSwitch
          filter="currentlyFostered"
          label="Currently Fostered"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
        <GridFilterSimpleSwitch
          filter="readyForAdoption"
          label="Ready for Adoption"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
        <GridFilterSimpleSwitch
          filter="includeDeceased"
          label="Include Deceased"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
      </>
    </BottomSheetView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    filterContainer: {
      alignItems: 'center',
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: theme.tokens.spacing[4],
    },
    resetButtonRow: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    root: {
      backgroundColor: theme.colors.surface,
      paddingBottom: theme.tokens.spacing[6],
      paddingHorizontal: theme.tokens.spacing[6],
    },
    segmentedButtons: {
      marginBottom: theme.tokens.spacing[3],
      marginTop: theme.tokens.spacing[4],
    },
    selectedText: {
      backgroundColor: theme.colors.surface,
    },
    text: {
      color: theme.colors.onBackground,
      fontSize: 16,
    },
  }
})

export default GridFilterView
