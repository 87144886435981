import { useRouter } from 'expo-router'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Page from '../../components/shared/Page'

const Setup = () => {
  const router = useRouter()
  const { styles } = useStyles(stylesheet)

  return (
    <Page requiresSubscription={false} viewId="welcome-setup">
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerContainer}>
            <Text variant="headlineMedium">Let's set up your rescue</Text>
            <Text style={styles.subtitle} variant="titleMedium">
              We'll help you create your rescue's profile and get everything set
              up to start managing your animals and volunteers
            </Text>
          </View>

          <View style={styles.pricingContainer}>
            <Text style={styles.pricingTitle} variant="titleMedium">
              Start for free
            </Text>
            <Text style={styles.tierDescription} variant="bodyMedium">
              Manage up to 5 new fosters each month at no cost. Need more? Paid
              plans starting at $9.99/month can be selected from your
              organization settings after setup.
            </Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle} variant="titleMedium">
              Quick setup - here's what you'll need:
            </Text>
            <View style={styles.listContainer}>
              <Text style={styles.listItem} variant="bodyMedium">
                • Your rescue organization's official name
              </Text>
              <Text style={styles.listItem} variant="bodyMedium">
                • Email addresses for team members you'd like to invite
                (optional)
              </Text>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <Button
              contentStyle={styles.buttonContent}
              mode="contained"
              onPress={() => router.push('/getting-started/create')}
            >
              Start setup
            </Button>

            <Button
              contentStyle={styles.buttonContent}
              mode="outlined"
              onPress={() => router.back()}
            >
              Go back
            </Button>
          </View>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  buttonContainer: {
    alignItems: 'stretch',
    gap: theme.tokens.spacing[3],
    width: '100%',
  },
  buttonContent: {
    paddingVertical: theme.tokens.spacing[3],
  },
  container: {
    flex: 1,
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    gap: theme.tokens.spacing[6],
  },
  headerContainer: {
    alignItems: 'center',
    gap: theme.tokens.spacing[2],
  },
  infoContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    padding: theme.tokens.spacing[4],
  },
  infoTitle: {
    color: theme.colors.onSurfaceVariant,
    marginBottom: theme.tokens.spacing[2],
  },
  listContainer: {
    gap: theme.tokens.spacing[2],
  },
  listItem: {
    color: theme.colors.onSurfaceVariant,
  },
  pricingContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    padding: theme.tokens.spacing[4],
  },
  pricingTitle: {
    color: theme.colors.onSurfaceVariant,
    marginBottom: theme.tokens.spacing[3],
    textAlign: 'center',
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    textAlign: 'center',
  },
  tierDescription: {
    color: theme.colors.onSurface,
  },
}))

export default Setup
