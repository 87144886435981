import { Checkbox } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Role from '../../../enums/role'
import Skeleton from '../../shared/Skeleton'

// Role.Member is handled through 'deactivate' and 'reactivate' calls
const roles: Role[] = [Role.Admin]

interface Props {
  userRoles: Role[]
  setUserRoles: (roles: Role[]) => void
  isLoading: boolean
  disabled: boolean
}

const UserRoles = ({ userRoles, setUserRoles, isLoading, disabled }: Props) => {
  const { styles } = useStyles(stylesheet)

  function isUserInRole(role: Role) {
    return userRoles?.includes(role)
  }

  function toggleCheckbox(role: Role) {
    if (!role) return

    if (isUserInRole(role)) {
      setUserRoles(userRoles.filter((x) => x !== role))
    } else {
      setUserRoles([...userRoles, role])
    }
  }

  return roles.map((role) => (
    <Skeleton isLoading={isLoading}>
      <Checkbox.Item
        disabled={disabled}
        key={role}
        label={role}
        labelStyle={styles.checkboxLabel}
        onPress={() => toggleCheckbox(role)}
        position="leading"
        status={isUserInRole(role) ? 'checked' : 'unchecked'}
        style={styles.checkboxItem}
        testID={`${role}-checkbox`}
      />
    </Skeleton>
  ))
}

const stylesheet = createStyleSheet((theme) => ({
  checkboxItem: {
    gap: theme.tokens.spacing[2],
    marginBottom: theme.tokens.spacing[1],
    paddingBottom: theme.tokens.spacing[1],
    paddingLeft: theme.tokens.spacing[1],
    paddingRight: theme.tokens.spacing[1],
    paddingTop: theme.tokens.spacing[1],
  },
  checkboxLabel: {
    textAlign: 'left',
  },
  checkboxSkeleton: {
    marginBottom: theme.tokens.spacing[4],
  },
}))

export default UserRoles
