import { FlashList } from '@shopify/flash-list'
import { useNavigation } from 'expo-router'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Text,
  useWindowDimensions,
  View,
  ViewToken,
} from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { AnimatedFAB, Icon } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterGridItem from '../../../../components/fosters/FosterGridItem'
import NewFoster from '../../../../components/fosters/NewFoster'
import FosterGridHeaderContent from '../../../../components/navigation/header/FosterGridHeaderContent'
import BillingMessage from '../../../../components/shared/BillingMessage'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'
import * as datadog from '../../../../utils/datadog/datadog'
import { isAtOrExceedingTierLimit } from '../../../../utils/purchases/billing'
import trpc from '../../../../utils/trpc'

const headerRight = () => <FosterGridHeaderContent />

const EmptyListComponent = memo(
  ({
    basicFilters,
    advancedFilters,
    theme,
  }: {
    basicFilters: { isActive: boolean; isDirty: boolean }
    advancedFilters: { isActive: boolean; isDirty: boolean }
    theme: any
  }) => {
    const { styles } = useStyles(stylesheet)
    const hasActiveFilters =
      (basicFilters.isActive && basicFilters.isDirty) ||
      (advancedFilters.isActive && advancedFilters.isDirty)

    return (
      <View style={styles.emptyContainer}>
        <View style={styles.emptyIconContainer}>
          <Icon
            color={theme.colors.primary}
            size={48}
            source={hasActiveFilters ? 'filter-remove' : 'paw'}
          />
        </View>
        <Text style={styles.emptyText}>
          {hasActiveFilters ? 'No matches found' : 'Start Fostering'}
        </Text>
        {!hasActiveFilters && (
          <Text style={styles.emptySubtext}>
            Keep track of your fosters’ health care, adoption info, and
            documents all in one place. Tap the button below to add your first
            foster.
          </Text>
        )}
        {hasActiveFilters && (
          <Text style={styles.emptySubtext}>
            Try broadening your search criteria or removing some filters to see
            more fosters
          </Text>
        )}
      </View>
    )
  }
)

EmptyListComponent.displayName = 'EmptyListComponent'

const Grid = () => {
  const navigation = useNavigation()
  const { styles, theme } = useStyles(stylesheet)
  const [isRefreshing, setRefreshing] = useState(false)
  const [isFABExtended, setIsFABExtended] = useState(true)
  const [isNewFosterSheetVisible, setIsNewFosterSheetVisible] = useState(false)
  const utils = trpc.useUtils()
  const debounceTimerRef = useRef<NodeJS.Timeout>()
  const { width } = useWindowDimensions()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const bulkSelect = useAppStore.use.bulkSelect()
  const setBulkSelect = useAppStore.use.setBulkSelect()
  const basicFilters = useAppStore.use.basicFilters()
  const advancedFilters = useAppStore.use.advancedFilters()

  const {
    fetchNextPage,
    fosterList,
    refreshFosterList,
    totalFosters,
    isLoading,
  } = FosterService.useFosterList()

  const prefetchFosterDetails = useCallback(
    (fosterId: string) => {
      if (currentOrganization?.id) {
        utils.foster.byId.prefetch(
          {
            id: fosterId,
            organizationId: currentOrganization.id,
          },
          {
            // By default react-query will keep a prefetch request around for 5 minutes before discarding it,
            // so we want to make sure we only prefetch once during this time, and we're ok with stale data. The idea is to make the
            // detail loading experience feel faster, and if tanstack fetches fresher data after that view loads we don't really care
            staleTime: 300 * 1000,
          }
        )
      }
    },
    [currentOrganization?.id, utils.foster.byId]
  )

  type FosterRow = (typeof fosterList)[number]
  type FosterViewToken = ViewToken<FosterRow>

  const debouncedPrefetch = useCallback(
    (viewableItems: FosterViewToken[]) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        viewableItems.forEach((item) => {
          if (item?.item?.id) {
            prefetchFosterDetails(item.item.id)
          }
        })
      }, 50)
    },
    [prefetchFosterDetails]
  )

  useEffect(() => {
    const titleSuffix = totalFosters > 0 ? ` (${totalFosters})` : ''
    navigation.setOptions({
      title: basicFilters.filters.viewAll
        ? `All Fosters${titleSuffix}`
        : `My Fosters${titleSuffix}`,
      headerRight,
    })
  }, [navigation, basicFilters.filters.viewAll, totalFosters])

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)
    await refreshFosterList()
    setRefreshing(false)
  }, [refreshFosterList])

  const onScroll = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      const currentScrollPosition =
        Math.floor(nativeEvent?.contentOffset?.y) ?? 0
      setIsFABExtended(currentScrollPosition <= 500)
    },
    []
  )

  const onSelect = useCallback(
    (id: string) => {
      if (bulkSelect.selection.items.includes(id)) {
        setBulkSelect({
          selection: {
            items: bulkSelect.selection.items.filter((i) => i !== id),
          },
        })
      } else {
        const newSelected = [...bulkSelect.selection.items, id]
        setBulkSelect({
          selection: {
            items: newSelected,
          },
        })
      }
    },
    [bulkSelect.selection.items, setBulkSelect]
  )

  const itemSize = width < 400 ? 145 : 180
  const numColumns = Math.floor(width / itemSize)

  const renderItem = useCallback(
    ({ item }: { item: (typeof fosterList)[number] }) => (
      <View style={styles.gridItem}>
        <FosterGridItem
          foster={item}
          isSelected={bulkSelect.selection.items.includes(item.id)}
          onSelect={onSelect}
        />
      </View>
    ),
    [bulkSelect.selection.items, onSelect, styles.gridItem]
  )

  const fosterCountQuery = trpc.foster.currentMonthFosterCount.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization?.id,
    }
  )

  const currentCount = fosterCountQuery.data?.count ?? 0

  const shouldShowEmptyState = fosterList.length === 0 && !isLoading

  return (
    <View style={styles.root}>
      <BillingMessage />
      {shouldShowEmptyState ? (
        <EmptyListComponent
          advancedFilters={advancedFilters}
          basicFilters={basicFilters}
          theme={theme}
        />
      ) : (
        <FlashList
          contentContainerStyle={styles.gridContent}
          data={fosterList}
          estimatedItemSize={itemSize}
          extraData={bulkSelect.selection}
          keyExtractor={(foster) => foster.id}
          numColumns={numColumns}
          onEndReached={() => fetchNextPage()}
          onEndReachedThreshold={0.5}
          onScroll={onScroll}
          onScrollBeginDrag={() => {
            datadog.startAction({
              type: datadog.RumActionType.SCROLL,
              name: 'fosterGrid',
            })
          }}
          onScrollEndDrag={() => {
            datadog.stopAction({
              type: datadog.RumActionType.SCROLL,
              name: 'fosterGrid',
            })
          }}
          onViewableItemsChanged={({ viewableItems }) => {
            debouncedPrefetch(viewableItems)
          }}
          refreshControl={
            <RefreshControl
              colors={[theme.colors.primary]}
              onRefresh={onRefresh}
              refreshing={isRefreshing}
            />
          }
          renderItem={renderItem}
          testID="foster-grid"
          viewabilityConfig={{
            itemVisiblePercentThreshold: 80,
            minimumViewTime: 150,
          }}
        />
      )}
      <AnimatedFAB
        animateFrom="right"
        disabled={isAtOrExceedingTierLimit(
          currentCount,
          currentOrganization?.subscriptionActive ?? false,
          currentOrganization?.subscriptionTier
        )}
        extended={isFABExtended}
        icon="plus"
        iconMode="dynamic"
        label={Platform.OS === 'ios' ? 'Add a Foster ' : 'Add a Foster'}
        onPress={() => setIsNewFosterSheetVisible(true)}
        style={styles.fabStyle}
      />
      <NewFoster
        onClose={() => setIsNewFosterSheetVisible(false)}
        open={isNewFosterSheetVisible}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  emptyContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: theme.tokens.spacing[4],
    paddingBottom: 100, // Account for FAB space
  },
  emptyIconContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.secondaryContainer,
    borderRadius: 60,
    height: 120,
    justifyContent: 'center',
    marginBottom: theme.tokens.spacing[6],
    width: 120,
  },
  emptySubtext: {
    color: theme.colors.subtitle,
    fontSize: 16,
    marginTop: theme.tokens.spacing[2],
    maxWidth: 300,
    textAlign: 'center',
  },
  emptyText: {
    color: theme.colors.onSurface,
    fontSize: 20,
    fontWeight: '600',
    maxWidth: 300,
    textAlign: 'center',
  },
  fabStyle: {
    borderRadius: 500,
    bottom: 32,
    position: 'absolute',
    right: 24,
  },
  gridContent: {
    flexGrow: 1,
    padding: theme.tokens.spacing[3],
  },
  gridItem: {
    margin: theme.tokens.spacing[2],
  },
  root: {
    backgroundColor: theme.colors.background,
    flex: 1,
  },
}))

export default Grid
