import { Linking, Pressable, View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import AppStoreBadge from '../components/badges/AppStoreBadge.web'
import PlayStoreBadge from '../components/badges/PlayStoreBadge.web'
import Logo from '../components/navigation/Logo'
import Page from '../components/shared/Page'

const APP_STORE_URL = 'https://apps.apple.com/app/rescuebase/id123456789'
const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.rescuebase.app'

const ComingSoon = () => {
  const { styles } = useStyles(stylesheet)

  return (
    <Page viewId="coming-soon">
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.logoContainer}>
            <Logo style={{}} />
          </View>

          <View style={styles.headerContainer}>
            <Text style={styles.title} variant="displaySmall">
              Coming Soon to Web
            </Text>
            <Text style={styles.subtitle} variant="titleLarge">
              The Rescuebase web application is currently under development
            </Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoText} variant="bodyLarge">
              Please download our mobile app to access all features.
            </Text>
            <View style={styles.storeButtons}>
              <Pressable
                accessibilityRole="button"
                onPress={() => Linking.openURL(APP_STORE_URL)}
                style={styles.storeBadge}
              >
                <AppStoreBadge />
              </Pressable>
              <Pressable
                accessibilityRole="button"
                onPress={() => Linking.openURL(PLAY_STORE_URL)}
                style={styles.storeBadge}
              >
                <PlayStoreBadge />
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    alignSelf: 'center',
    gap: theme.tokens.spacing[8],
    maxWidth: 600,
    width: '100%',
  },
  headerContainer: {
    alignItems: 'center',
    gap: theme.tokens.spacing[3],
  },
  infoContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: theme.tokens.containerBorderRadius,
    elevation: 1,
    padding: theme.tokens.spacing[6],
  },
  infoText: {
    color: theme.colors.onSurfaceVariant,
    lineHeight: 24,
    marginBottom: theme.tokens.spacing[4],
    textAlign: 'center',
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: theme.tokens.spacing[4],
  },
  storeBadge: {
    ':hover': {
      opacity: 0.8,
    },
    cursor: 'pointer',
    opacity: 1,
    transition: 'opacity 0.2s ease',
  },
  storeButtons: {
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: theme.tokens.spacing[4],
    justifyContent: 'center',
    marginTop: theme.tokens.spacing[2],
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    maxWidth: 400,
    textAlign: 'center',
  },
  title: {
    color: theme.colors.primary,
    fontWeight: 'bold',
  },
}))

export default ComingSoon
