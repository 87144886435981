import { zodResolver } from '@hookform/resolvers/zod'
import { router, useNavigation } from 'expo-router'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import MedicalInfoType from '../../../../enums/medicalInfoType'
import Species from '../../../../enums/species'
import useAutoSave from '../../../../hooks/useAutoSave'
import OrganizationService from '../../../../services/organizationService'
import trpc from '../../../../utils/trpc'
import FormFieldSelect from '../../../form/FormFieldSelect'
import FormFieldText from '../../../form/FormFieldText'
import FormFieldSpecies from '../../../fosters/cards/formFields/FormFieldSpecies'
import Card from '../../../shared/Card'
import Page from '../../../shared/Page'

const medicalInfoFormSchema = z.object({
  maxAgeWeeks: z.coerce.number().int().nullable(),
  minAgeWeeks: z.coerce.number().int().nullable(),
  species: z.nativeEnum(Species).optional().nullable(),
  text: z.string(),
  type: z.nativeEnum(MedicalInfoType),
})

type MedicalInfoForm = z.infer<typeof medicalInfoFormSchema>

interface Props {
  medicalInfoId: string
}

const OrganizationEditMedicalInfo = ({ medicalInfoId }: Props) => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()
  const utils = trpc.useContext()

  const isNewMedicalInfo = medicalInfoId === 'new'

  const { currentOrganization } = OrganizationService.useCurrentOrganization()

  const medicalInfoQuery = trpc.medicalInfo.byId.useQuery(
    {
      id: medicalInfoId,
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !isNewMedicalInfo || !!currentOrganization,
    }
  )

  const medicalInfoListQuery = trpc.medicalInfo.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  const medicalInfo =
    medicalInfoListQuery.data?.find((m) => m.id === medicalInfoId) ||
    medicalInfoQuery.data

  const createMedicalInfoMutation = trpc.medicalInfo.create.useMutation()
  const updateMedicalInfoMutation = trpc.medicalInfo.update.useMutation()
  const deleteMedicalInfoMutation = trpc.medicalInfo.delete.useMutation()

  const defaultValues = useMemo(() => {
    return {
      maxAgeWeeks: medicalInfo?.maxAgeWeeks || null,
      minAgeWeeks: medicalInfo?.minAgeWeeks || null,
      species: medicalInfo?.species as Species,
      text: medicalInfo?.text || '',
      type: medicalInfo?.type as MedicalInfoType,
    }
  }, [medicalInfo])

  const form = useForm<MedicalInfoForm>({
    defaultValues,
    resolver: zodResolver(medicalInfoFormSchema),
  })

  const { control, formState, handleSubmit, reset } = form

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(() => {
    if (!isNewMedicalInfo && !medicalInfo?.type) {
      return
    }

    navigation.setOptions({
      title: isNewMedicalInfo
        ? 'New Medical Info'
        : `${medicalInfo?.type} Settings` || '',
    })
  })

  const onSave = (data: MedicalInfoForm) => {
    if (!currentOrganization?.id) {
      return null
    }

    if (
      isNewMedicalInfo &&
      (createMedicalInfoMutation.status === 'loading' ||
        createMedicalInfoMutation.status === 'success')
    ) {
      return
    }

    const payload = {
      ...data,
      maxAgeWeeks: data.maxAgeWeeks || null,
      minAgeWeeks: data.minAgeWeeks || null,
      species: data.species || null,
      type: data.type,
      organizationId: currentOrganization.id,
    }

    if (isNewMedicalInfo) {
      createMedicalInfoMutation.mutate(payload, {
        onSuccess: (newMedicalInfo) => {
          utils.medicalInfo.list.invalidate()
          router.replace(
            `/organization/${currentOrganization?.id}/medical-info/${newMedicalInfo.id}`
          )
        },
      })
    } else {
      updateMedicalInfoMutation.mutate({ id: medicalInfoId, ...payload })
    }
  }

  const wrappedOnSave = () => {
    if (isNewMedicalInfo && createMedicalInfoMutation.isLoading) {
      return
    }
    handleSubmit(onSave)()
  }

  useAutoSave({
    form,
    defaultValues,
    onSave: wrappedOnSave,
  })

  if (!medicalInfoListQuery.isSuccess) {
    return null
  }

  const isFormDisabled =
    !currentOrganization?.subscriptionActive ||
    (isNewMedicalInfo && createMedicalInfoMutation.isLoading)

  return (
    <Page viewId="organization-medical-info">
      <Card title="Medical Info">
        <View style={styles.vaccineSection}>
          <Text variant="headlineSmall">Basic Info</Text>
          <View style={styles.vaccineRow}>
            <FormFieldSpecies
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              style={styles.flexContainer}
            />
            <FormFieldSelect
              control={control}
              data={[
                { label: 'Fecal', value: MedicalInfoType.Fecal },
                { label: 'Prevention', value: MedicalInfoType.Prevention },
                {
                  label: 'SpayAndNeuter',
                  value: MedicalInfoType.SpayAndNeuter,
                },
                { label: 'Vaccination', value: MedicalInfoType.Vaccination },
              ]}
              disable={isFormDisabled}
              errors={formState.errors}
              fieldName="type"
              label="Type"
              required={true}
              style={styles.flexContainer}
            />
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="minAgeWeeks"
              label="Min Age (Weeks)"
              required={false}
              style={styles.flexContainer}
            />
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="maxAgeWeeks"
              label="Max Age (Weeks)"
              required={false}
              style={styles.flexContainer}
            />
          </View>
          <View>
            <FormFieldText
              control={control}
              disabled={isFormDisabled}
              errors={formState.errors}
              fieldName="text"
              label="Text"
              multiline={true}
              required
              style={styles.flexContainer}
            />
          </View>
        </View>
        <Button
          disabled={isFormDisabled}
          mode="contained"
          onPress={async () =>
            deleteMedicalInfoMutation.mutate(
              {
                id: medicalInfoId,
                organizationId: currentOrganization?.id || '',
              },
              {
                onSuccess: async () => {
                  await utils.medicalInfo.list.invalidate()
                  router.back()
                },
              }
            )
          }
        >
          Delete
        </Button>
      </Card>
    </Page>
  )
}

export default OrganizationEditMedicalInfo

const stylesheet = createStyleSheet((theme) => {
  return {
    flexContainer: {
      flex: 1,
    },
    vaccineRow: {
      flexDirection: {
        sm: 'column',
        md: 'row',
      },
      gap: theme.tokens.spacing[4],
    },
    vaccineSection: {
      gap: theme.tokens.spacing[4],
      marginBottom: theme.tokens.spacing[4],
    },
  }
})
