import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Divider, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import Role from '../../../enums/role'
import useAutosave from '../../../hooks/useAutoSave'
import UserService from '../../../services/userService'
import UserAvatar from '../../navigation/UserAvatar'
import Card from '../../shared/Card'
import NotFound from '../../shared/NotFound'
import Skeleton from '../../shared/Skeleton'
import UserRoles from './UserRoles'

const UserDetailForm = () => {
  const { styles } = useStyles(stylesheet)

  const { user, isLoading, currentOrganization } = UserService.useUser()

  const defaultValues = { ...user }

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const { reset, setValue } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  const onSubmit = async (data: FormData) => {
    if (!currentOrganization?.id) {
      return
    }

    updateMutation.mutate(
      { ...data, organizationId: currentOrganization.id },
      {
        onSuccess: (updatedData) => {
          reset({ ...updatedData }, { keepDirtyValues: true })
        },
      }
    )
  }

  useAutosave({
    form,
    defaultValues,
    onSave: onSubmit,
  })

  const updateMutation = UserService.useOrgUpdateMutation()
  const disabled = !!user?.deactivatedAt || isLoading

  if (!isLoading && !user) return <NotFound />

  const setUserRoles = (roles: Role[]) => {
    setValue('roles', roles, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  return (
    <View style={styles.cardContainer}>
      <Card style={styles.rolesCard} title="User Info">
        <View style={styles.userHeader}>
          <Skeleton isLoading={isLoading} style={styles.avatarSkeleton}>
            <UserAvatar size={48} testID="user-avatar" user={user} />
          </Skeleton>
          <View style={styles.userInfo}>
            <Skeleton isLoading={isLoading}>
              <Text style={styles.userName} testID="user-name">
                {user?.firstName} {user?.lastName}
              </Text>
              <Text style={styles.userEmail}>{user?.email}</Text>
            </Skeleton>
          </View>
        </View>
        <Divider />
        <Text style={styles.rolesTitle} variant="headlineSmall">
          Roles
        </Text>
        <UserRoles
          disabled={disabled}
          isLoading={isLoading}
          setUserRoles={setUserRoles}
          userRoles={form.getValues().roles as Role[]}
        />
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  avatarSkeleton: {
    borderRadius: 100,
  },
  cardContainer: {
    flexDirection: {
      lg: 'row',
    },
    gap: theme.tokens.spacing[4],
  },
  rolesCard: {
    flex: {
      lg: 1,
    },
  },
  rolesTitle: {
    color: theme.colors.secondary,
    marginBottom: theme.tokens.spacing[4],
    marginTop: theme.tokens.spacing[4],
  },
  userEmail: {
    color: theme.colors.onSurface,
    fontSize: 12,
  },
  userHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.tokens.spacing[4],
    marginBottom: theme.tokens.spacing[4],
  },
  userInfo: {
    flex: 1,
  },
  userName: {
    color: theme.colors.onSurface,
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

const formSchema = z.object({
  id: z.string().uuid(),
  roles: z.array(z.enum(Object.values(Role) as [`${Role}`])),
})

type FormData = z.infer<typeof formSchema>

export default UserDetailForm
