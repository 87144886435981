import { startOfDay } from 'date-fns'
import { router } from 'expo-router'
import { Platform, View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import {
  createStyleSheet,
  UnistylesRuntime,
  useStyles,
} from 'react-native-unistyles'
import Species from '../../enums/species'
import { addAction, RumActionType } from '../../utils/datadog/datadog'
import FosterThumbnail from '../fosters/FosterThumbnail'
import UserAvatar from '../navigation/UserAvatar'
import { DashboardFoster } from './DashboardTypes'

interface Props {
  foster: DashboardFoster
  isOverdue: boolean
  showUserInfo?: boolean
}

const EventCodeMap: Record<string, string> = {
  fecalFollowUp: 'Fecal Follow-Up',
  nextComboMed: 'Combo Med',
  nextFleaTickMed: 'Flea/Tick Prevention',
  nextHeartwormPrevention: 'Heartworm Prevention',
}

const DashboardEventCard = ({ foster, isOverdue, showUserInfo }: Props) => {
  const { styles } = useStyles(stylesheet)

  const getVolunteerName = () => {
    if (['sm', 'md', 'lg', 'xl'].includes(UnistylesRuntime.breakpoint)) {
      return `${foster.user.firstName} ${foster.user.lastName}`
    }
    return `${foster.user.firstName} ${foster.user.lastName[0]}`
  }

  return (
    <TouchableRipple
      accessibilityHint={`Opens the foster profile for ${foster.name}.`}
      accessibilityLabel={`${foster.name} - ${foster.events[0].dueType}`}
      accessibilityRole="button"
      borderless
      onPress={() => {
        addAction({
          type: RumActionType.TAP,
          name: `View - Foster`,
        })
        router.push(`/fosters/${foster.fosterId}`)
      }}
      style={styles.root}
    >
      <View style={styles.cardContainer}>
        <View style={styles.avatarShadowContainer}>
          <FosterThumbnail
            blurhash={foster.blurhash}
            key={`${foster.fosterId}-${startOfDay(foster.events[0].dueDate).getTime()}-image`}
            // @ts-expect-error - species enum matching the API is an ongoing problem
            species={foster.species || Species.Dog}
            style={styles.fosterAvatar}
            thumbUri={foster.thumbUri}
          />
        </View>
        <View style={styles.card}>
          <View style={styles.cardContent}>
            <View style={styles.headerContainer}>
              <View style={styles.nameRow}>
                <Text style={styles.fosterName}>{foster.name}</Text>
                {showUserInfo && (
                  <View style={styles.userContainer}>
                    <Text style={styles.userInfo}>{getVolunteerName()}</Text>
                    <Text style={styles.separator}>•</Text>
                    <View style={styles.userAvatarContainer}>
                      <UserAvatar size={16} user={foster.user} />
                    </View>
                  </View>
                )}
              </View>
            </View>
            {foster.events.map((event) => (
              <View key={`${foster.fosterId}-${event.dueType}`}>
                <Text style={styles.eventName}>
                  {EventCodeMap[event.dueType] || event.dueType}
                  {!isOverdue && ' due'}
                </Text>
                {isOverdue && (
                  <Text style={styles.overdueText}>
                    was due {event.dueDate.toLocaleDateString('en-US')}
                  </Text>
                )}
              </View>
            ))}
          </View>
        </View>
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatarShadowContainer: {
      backgroundColor: theme.colors.surface,
      borderRadius: 100,
      left: -25,
      position: 'absolute',
      top: -2,
    },
    card: {
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.cardBorder,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: 1,
      flex: 1,
      paddingBottom: 10,
      paddingLeft: 50,
      paddingTop: 10,
      zIndex: -1,
    },
    cardContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
      marginLeft: 26,
    },
    cardContent: {
      gap: theme.tokens.spacing[1],
    },
    eventName: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 14,
      paddingTop: theme.tokens.spacing[2],
    },
    fosterAvatar: {
      borderColor: theme.colors.cardBorder,
      borderRadius: 100,
      borderWidth: 2,
      height: 60,
      width: 60,
    },
    fosterName: {
      color: theme.colors.onSurface,
      fontSize: 18,
      ...Platform.select({
        ios: {
          fontWeight: '600',
        },
        android: {
          fontWeight: '700',
        },
      }),
    },
    headerContainer: {
      gap: theme.tokens.spacing[1],
    },
    nameRow: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    overdueText: {
      color: theme.colors.error,
      fontSize: 13,
      ...Platform.select({
        ios: {
          fontWeight: '500',
        },
        android: {
          fontWeight: '600',
        },
      }),
    },
    root: {
      borderRadius: theme.tokens.containerBorderRadius,
      padding: theme.tokens.spacing[2],
    },
    separator: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 13,
      marginHorizontal: theme.tokens.spacing[1],
      opacity: 0.7,
    },
    userAvatarContainer: {
      opacity: 0.7,
    },
    userContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.tokens.spacing[1],
      marginRight: theme.tokens.spacing[4],
    },
    userInfo: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 13,
      opacity: 0.8,
      ...Platform.select({
        ios: {
          fontWeight: '500',
        },
        android: {
          fontWeight: '600',
        },
      }),
    },
  }
})

export default DashboardEventCard
