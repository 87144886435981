import { useRouter } from 'expo-router'
import { useState } from 'react'
import { View } from 'react-native'
import { Button, Chip, Text, TextInput } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Page from '../../components/shared/Page'
import trpc from '../../utils/trpc'

const Create = () => {
  const router = useRouter()
  const { styles } = useStyles(stylesheet)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emails, setEmails] = useState<string[]>([])

  const createOrganizationMutation = trpc.organization.create.useMutation()
  const inviteUserMutation = trpc.user.invite.useMutation()
  const currentUserQuery = trpc.user.current.useQuery({})

  const handleAddEmail = () => {
    if (email && email.includes('@') && !emails.includes(email)) {
      setEmails([...emails, email])
      setEmail('')
    }
  }

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmails(emails.filter((e) => e !== emailToRemove))
  }

  const handleSubmit = async () => {
    let newOrganizationId = ''
    const newOrganization = await createOrganizationMutation.mutateAsync({
      name,
    })
    newOrganizationId = newOrganization.id

    await Promise.all(
      emails.map((e) =>
        inviteUserMutation.mutateAsync({
          email: e,
          organizationId: newOrganizationId,
        })
      )
    )

    currentUserQuery.refetch().then(() => {
      router.dismissTo('/')
    })
  }

  return (
    <Page requiresSubscription={false} viewId="create-rescue">
      <View style={styles.container}>
        <View style={styles.content}>
          <Text variant="headlineMedium">Name your rescue</Text>
          <Text style={styles.subtitle} variant="bodyLarge">
            Enter the official name of your rescue organization
          </Text>

          <TextInput
            accessibilityHint="Enter the official name of your rescue organization"
            accessibilityLabel="Text input field"
            label="Rescue Organization Name"
            mode="outlined"
            onChangeText={setName}
            style={styles.input}
            testID="rescue-name-textInput"
            value={name}
          />

          <View style={styles.inviteSection}>
            <Text variant="titleMedium">Invite your team</Text>
            <Text style={styles.subtitle} variant="bodyMedium">
              Add email addresses of team members you'd like to invite
            </Text>

            <TextInput
              accessibilityHint="Enter the email address of the team member you'd like to invite"
              accessibilityLabel="Text input field"
              autoCapitalize="none"
              keyboardType="email-address"
              label="Email Address"
              mode="outlined"
              onChangeText={setEmail}
              onSubmitEditing={handleAddEmail}
              right={
                <TextInput.Icon
                  disabled={!email.includes('@')}
                  icon="plus"
                  onPress={handleAddEmail}
                />
              }
              style={styles.input}
              testID="invite-email-textInput"
              value={email}
            />

            {emails.length > 0 && (
              <View style={styles.chipContainer}>
                {emails.map((emailAddress) => (
                  <Chip
                    key={emailAddress}
                    onClose={() => handleRemoveEmail(emailAddress)}
                    style={styles.chip}
                  >
                    {emailAddress}
                  </Chip>
                ))}
              </View>
            )}
          </View>

          <Text style={styles.helperText} variant="bodyMedium">
            You'll be able to add your organization's logo and payment
            information after creating your rescue.
          </Text>
          <Button
            contentStyle={styles.buttonContent}
            disabled={!name.trim()}
            loading={
              createOrganizationMutation.isLoading ||
              inviteUserMutation.isLoading ||
              currentUserQuery.isLoading
            }
            mode="contained"
            onPress={handleSubmit}
          >
            Create Rescue
          </Button>
        </View>
      </View>
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  buttonContent: {
    paddingVertical: theme.tokens.spacing[2],
  },
  chip: {
    marginVertical: theme.tokens.spacing[1],
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.tokens.spacing[2],
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    padding: theme.tokens.spacing[4],
    paddingBottom: theme.tokens.spacing[8],
  },
  content: {
    gap: theme.tokens.spacing[4],
  },
  helperText: {
    color: theme.colors.onSurfaceVariant,
    marginLeft: theme.tokens.helperTextIndent,
  },
  input: {
    backgroundColor: 'transparent',
    marginTop: theme.tokens.spacing[2],
  },
  inviteSection: {
    gap: theme.tokens.spacing[3],
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
  },
}))

export default Create
