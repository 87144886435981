import { View, ViewStyle } from 'react-native'
import { Avatar } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  user?: {
    emailHash?: string | null
    firstName: string
    lastName: string
  }
  size: number
  style?: ViewStyle
  testID?: string
}

const UserAvatar = (props: Props) => {
  const { styles } = useStyles(stylesheet)

  // create a gravatar avatar link and test for a 404
  // if it fails, use the initials avatar
  const gravatarUrl = `https://www.gravatar.com/avatar/${props.user?.emailHash}?d=retro&size=${props.size * 2}`

  return (
    <View style={props.style} testID={props.testID}>
      <Avatar.Image
        size={props.size}
        source={{
          uri: gravatarUrl,
        }}
        style={[styles.avatar, { borderWidth: props.size / 2 + 1 }]}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderColor: theme.colors.cardBorder,
      borderRadius: 100,
      justifyContent: 'center',
    },
  }
})

export default UserAvatar
