import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import {
  useGateValue,
  useStatsigClient,
  useStatsigUser,
} from '@statsig/expo-bindings'
import * as QuickActions from 'expo-quick-actions'
import { RouterAction, useQuickActionRouting } from 'expo-quick-actions/router'
import { Redirect, Stack } from 'expo-router'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import { useStyles } from 'react-native-unistyles'
import { Feature } from '../../../constants'
import Header from '../../components/navigation/header/Header'
import useAccessToken from '../../hooks/useAccessToken'
import useRegisterPushNotifications from '../../hooks/useRegisterPushNotifications'
import OrganizationService from '../../services/organizationService'
import UserService from '../../services/userService'
import { useAppStore } from '../../store/useAppStore'
import * as datadog from '../../utils/datadog/datadog'
import purchases from '../../utils/purchases/purchases'

SplashScreen.preventAutoHideAsync()

export const unstable_settings = {
  initialRouteName: '(tabs)',
}

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

const InnerAppLayout = () => {
  // Enable linking to the `href` param when a quick action is used.
  useQuickActionRouting()

  const { theme } = useStyles()

  const auth = useAppStore.use.auth()
  const { user: statsigUser, updateUserSync } = useStatsigUser()
  const hasDatadogRUM = useGateValue(Feature.DatadogRUM)
  const hasWebApp = useGateValue(Feature.WebApp)

  const accessToken = useAccessToken()
  const setAuth = useAppStore.use.setAuth()

  useEffect(() => {
    if (accessToken && accessToken !== auth.accessToken) {
      setAuth({ accessToken, isLoggedIn: auth.isLoggedIn })
    }
  }, [accessToken, auth.accessToken, auth.isLoggedIn, setAuth])

  const { currentOrganization } = OrganizationService.useCurrentOrganization()

  const { user: currentUser } = UserService.useCurrentUser()

  const { client: statsigClient } = useStatsigClient()

  useRegisterPushNotifications()

  useMemo(async () => {
    if (hasDatadogRUM) {
      await datadog.initialize()

      // await datadog.enableSessionReplay()
    } else {
      datadog.stop()
    }

    statsigClient.on('gate_evaluation', (event) => {
      datadog.evaluateFeature(event.gate.name, event.gate.value)
    })
  }, [hasDatadogRUM, statsigClient])

  useEffect(() => {
    // Now you can configure your quick actions to link places (including externally):
    QuickActions.setItems<RouterAction>([
      {
        title: 'Search',
        icon: Platform.OS === 'ios' ? 'search' : 'shortcut_search',
        id: '0',
        params: { href: '/search' },
      },
    ])
  }, [])

  useEffect(() => {
    async function initialize() {
      if (!currentOrganization) {
        return
      }

      purchases.configure(currentOrganization.id, currentOrganization.name)
    }

    initialize()
  }, [currentOrganization, currentOrganization?.id, currentOrganization?.name])

  useEffect(() => {
    if (!currentUser || !currentOrganization) {
      return
    }

    async function login() {
      if (!currentOrganization) {
        return
      }

      if (!currentUser) {
        return
      }

      datadog.setUser({
        id: currentUser.id,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      })

      // Only update statsig user if the values have changed
      if (
        statsigUser.userID !== currentUser.id ||
        statsigUser.email !== currentUser.email ||
        statsigUser.custom?.organizationId !== currentOrganization.id
      ) {
        updateUserSync({
          ...statsigUser,
          custom: {
            ...statsigUser.custom,
            organizationId: currentOrganization.id || '',
          },
          userID: currentUser.id,
          email: currentUser.email,
        })
      }

      if (await purchases.isConfigured()) {
        await purchases.logIn(currentOrganization.id)
      }
    }

    login()
  }, [
    currentOrganization?.id,
    currentUser?.id,
    currentUser?.email,
    statsigUser,
    currentUser,
    currentOrganization,
    updateUserSync,
  ])

  if (auth.isLoggedIn === undefined) {
    return null
  }

  if (
    (Platform.OS === 'web' && auth.isLoggedIn === false) ||
    (Platform.OS === 'web' &&
      auth.isLoggedIn === true &&
      statsigUser.email &&
      !hasWebApp)
  ) {
    return <Redirect href="/coming-soon" />
  }

  if (auth.isLoggedIn === false) {
    return <Redirect href="/login" />
  }

  if (currentUser?.isFirstRunRequired) {
    return <Redirect href="/getting-started/first-run" />
  }

  if (currentUser?.organizations.length === 0) {
    return <Redirect href="/getting-started" />
  }

  return (
    <Stack
      screenOptions={{
        headerShown: true,
        header: HeaderComponent,
        contentStyle: {
          backgroundColor: theme.colors.background,
        },
      }}
    >
      <Stack.Screen name="(tabs)" />
    </Stack>
  )
}

export default InnerAppLayout
