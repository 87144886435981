import { router } from 'expo-router'
import { Alert, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import OrganizationService from '../../services/organizationService'
import trpc from '../../utils/trpc'
import SaveStatus from '../shared/SaveStatus'

const OrganizationHeaderRight = () => {
  const { theme } = useStyles()
  const isSaving = OrganizationService.useIsSaving()
  const { organizationQueryData, refreshOrganization } =
    OrganizationService.useCurrentOrganization()
  const deleteOrganizationMutation = trpc.organization.delete.useMutation()

  if (!organizationQueryData) {
    return null
  }

  const handleDeleteOrganization = () => {
    Alert.alert(
      'Delete Organization',
      'Are you sure you want to delete this organization? This action is permanent and irreversible. All data associated with this organization will be permanently deleted.',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () => {
            deleteOrganizationMutation.mutate(
              {
                organizationId: organizationQueryData?.id,
              },
              {
                onSuccess: () => {
                  refreshOrganization().then(() => {
                    router.dismissTo('/')
                  })
                },
              }
            )
          },
        },
      ]
    )
  }

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <IconButton
        icon="delete"
        iconColor={theme.colors.error}
        mode="contained"
        onPress={handleDeleteOrganization}
        testID="delete-organization-button"
      />
      <SaveStatus
        isSaving={isSaving}
        updatedAt={organizationQueryData?.updatedAt}
        // updatedBy={`${foster?.updatedBy.firstName} ${foster?.updatedBy.lastName}`}
      />
    </View>
  )
}

export default OrganizationHeaderRight
