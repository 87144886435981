import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { router, Stack } from 'expo-router'
import { IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import Header from '../../components/navigation/header/Header'
import { useAppStore } from '../../store/useAppStore'
import { signOut } from '../../utils/auth/auth'

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

const WelcomeLayout = () => {
  const { theme } = useStyles()
  const setAuth = useAppStore.use.setAuth()

  return (
    <Stack
      screenOptions={{
        header: HeaderComponent,
        title: 'Getting Started',
        contentStyle: {
          backgroundColor: theme.colors.background,
        },
        headerRight: () => (
          <IconButton
            icon="logout"
            iconColor={theme.colors.primary}
            onPress={async () => {
              await signOut()
              setAuth({ accessToken: undefined, isLoggedIn: false })
              router.replace('/login')
            }}
            testID="sign-out-button"
          />
        ),
      }}
    />
  )
}

export default WelcomeLayout
