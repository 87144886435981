import FosterDocumentsCard from '../../../../components/fosters/cards/FosterDocumentsCard'
import FosterTab from '../../../../components/fosters/FosterTab'
import useFosterOwner from '../../../../hooks/useFosterOwner'
import FosterService from '../../../../services/fosterService'
import { useAppStore } from '../../../../store/useAppStore'

const FosterDocumentsTab = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const isFosterOwner = useFosterOwner(fosterId)
  const currentOrganization = useAppStore.use.currentOrganization().organization
  const isDisabled = !currentOrganization?.subscriptionActive || !isFosterOwner

  return (
    <FosterTab viewId="foster-documents-tab">
      <FosterDocumentsCard disabled={isDisabled} />
    </FosterTab>
  )
}

export default FosterDocumentsTab
