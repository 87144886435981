import { format } from 'date-fns'
import * as Linking from 'expo-linking'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button, Divider, Text, Tooltip } from 'react-native-paper'
import { CustomerInfo } from 'react-native-purchases'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../../store/useAppStore'
import {
  getTierLimitText,
  getTierName,
  isWithinTierLimit,
} from '../../../utils/purchases/billing'
import purchases from '../../../utils/purchases/purchases'
import trpc from '../../../utils/trpc'
import Card from '../../shared/Card'

const OrganizationSubscriptionCard = () => {
  const { styles } = useStyles(stylesheet)
  const currentOrganization = useAppStore.use.currentOrganization().organization
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null)

  const fosterCountQuery = trpc.foster.currentMonthFosterCount.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization?.id,
    }
  )

  useEffect(() => {
    if (!purchases.isConfigured()) {
      return
    }

    async function preload() {
      const ci = await purchases.getCustomerInfo()
      if (ci) {
        setCustomerInfo(ci)
      }
    }

    preload()
  }, [])

  const fosterCount = fosterCountQuery.data?.count ?? 0
  const currentTier = currentOrganization?.subscriptionTier
  const statusColor = isWithinTierLimit(fosterCount, currentTier)
    ? 'successColor'
    : 'errorColor'

  const entitlements = customerInfo?.entitlements.active
  const isSubscribed = !!(
    entitlements?.['small-rescue']?.isActive ||
    entitlements?.['medium-rescue']?.isActive ||
    entitlements?.['large-rescue']?.isActive
  )
  const isComped = isSubscribed && !customerInfo?.managementURL
  const managementURL = customerInfo?.managementURL || ''

  // Get subscription dates from the active entitlement
  const activeEntitlement = Object.values(entitlements || {}).find(
    (e) => e.isActive
  )
  const subscriptionStartDate = activeEntitlement?.originalPurchaseDate
    ? format(new Date(activeEntitlement.originalPurchaseDate), 'MMM d, yyyy')
    : null
  const nextBillingDate = activeEntitlement?.expirationDate
    ? format(new Date(activeEntitlement.expirationDate), 'MMM d, yyyy')
    : null

  const handleManageSubscription = () => {
    if (managementURL) {
      Linking.openURL(managementURL)
    }
  }

  return (
    <Card title="Subscription Status">
      <View style={styles.statsContainer}>
        <View style={styles.stat}>
          <Text style={styles.statLabel}>Current Plan</Text>
          <Text style={styles.statValue}>{getTierName(currentTier)}</Text>
          <Text style={styles.statDetail}>{getTierLimitText(currentTier)}</Text>
        </View>
        <View style={styles.stat}>
          <Text style={styles.statLabel}>Current Usage</Text>
          <Text style={[styles.statValue, styles[statusColor]]}>
            {fosterCount} fosters
          </Text>
          <Text style={styles.statDetail}>This month</Text>
        </View>
      </View>

      <Divider style={styles.divider} />

      <View style={styles.statusContainer}>
        <Text style={styles.sectionTitle}>Status</Text>
        {currentOrganization?.subscriptionActive ? (
          <>
            <Text style={[styles.statusMessage, styles.successColor]}>
              Your subscription is active and in good standing
            </Text>
            {subscriptionStartDate && (
              <Text style={styles.statusDetail}>
                Member since: {subscriptionStartDate}
              </Text>
            )}
            {nextBillingDate && (
              <Text style={styles.statusDetail}>
                Next billing date: {nextBillingDate}
              </Text>
            )}
          </>
        ) : (
          <Text style={[styles.statusMessage, styles.errorColor]}>
            Your organization has exceeded the free tier limit. Please upgrade
            your subscription to continue.
          </Text>
        )}
      </View>

      {isSubscribed && !isComped ? (
        managementURL ? (
          <Button
            icon="cog"
            mode="contained"
            onPress={handleManageSubscription}
            style={styles.button}
          >
            Manage Subscription
          </Button>
        ) : null
      ) : !isSubscribed ? (
        <Button
          icon="star"
          mode="contained"
          onPress={() => purchases.showPaywall()}
          style={styles.button}
        >
          Subscribe
        </Button>
      ) : (
        <Tooltip title="This is a complimentary subscription that cannot be managed">
          <View>
            <Button disabled icon="cog" mode="contained" style={styles.button}>
              Manage Subscription
            </Button>
          </View>
        </Tooltip>
      )}
    </Card>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  button: {
    marginTop: theme.tokens.spacing[2],
  },
  divider: {
    marginVertical: theme.tokens.spacing[4],
  },
  errorColor: {
    color: theme.colors.error,
  },
  sectionTitle: {
    color: theme.colors.onSurface,
    fontWeight: '600',
    marginBottom: theme.tokens.spacing[2],
    opacity: 0.8,
  },
  stat: {
    flex: 1,
  },
  statDetail: {
    color: theme.colors.onSurfaceVariant,
    fontSize: 13,
    marginTop: 2,
    opacity: 0.7,
  },
  statLabel: {
    color: theme.colors.onSurfaceVariant,
    fontSize: 13,
    fontWeight: '600',
    marginBottom: theme.tokens.spacing[1],
    textTransform: 'uppercase',
  },
  statValue: {
    fontSize: 20,
    fontWeight: '600',
  },
  statsContainer: {
    flexDirection: 'row',
    gap: theme.tokens.spacing[4],
    marginBottom: theme.tokens.spacing[4],
  },
  statusContainer: {
    marginBottom: theme.tokens.spacing[4],
  },
  statusDetail: {
    color: theme.colors.onSurfaceVariant,
    fontSize: 13,
    marginTop: theme.tokens.spacing[1],
  },
  statusMessage: {
    fontSize: 15,
    lineHeight: 20,
  },
  successColor: {
    color: theme.colors.primary,
  },
}))

export default OrganizationSubscriptionCard
