import { isSameMonth, startOfDay } from 'date-fns'
import * as Notifications from 'expo-notifications'
import { useNavigation } from 'expo-router'
import { memo, useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Platform,
  View,
  VirtualizedList,
} from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { Divider, Icon, SegmentedButtons, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import useRefetchOnFocus from '../../hooks/useRefetchOnFocus'
import useUserRoles from '../../hooks/useUserRoles'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import BillingMessage from '../shared/BillingMessage'
import DashboardEventCard from './DashboardEventCard'
import DashboardTimeline from './DashboardTimeline'
import { DashboardDay } from './DashboardTypes'

const DayItem = memo(
  ({
    day,
    dayIndex,
    isCurrentMonth,
    showUserInfo,
    eventsByDayAndFoster,
  }: {
    day: DashboardDay
    dayIndex: number
    isCurrentMonth: (date: Date) => boolean
    showUserInfo: boolean
    eventsByDayAndFoster: DashboardDay[]
  }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
      <View style={styles.dayContainer}>
        {dayIndex > 0 &&
          !isSameMonth(
            day.dueDate,
            eventsByDayAndFoster[dayIndex - 1].dueDate
          ) && (
            <View style={styles.rowContainer}>
              <Icon
                color={theme.colors.secondary}
                size={20}
                source="calendar-month"
              />
              <View style={styles.monthLabelContainer}>
                <Text style={styles.monthLabel}>
                  {day.dueDate.toLocaleDateString('en-US', {
                    month: 'long',
                  })}
                </Text>
                {isCurrentMonth(day.dueDate) && (
                  <Text style={styles.currentMonthLabel}>(Current)</Text>
                )}
              </View>
              <Divider style={styles.monthDivider} />
            </View>
          )}
        <View style={styles.eventsContainer}>
          <DashboardTimeline day={day} />
          <View style={styles.cardsContainer}>
            {dayIndex === 0 && !day.fosters.length && (
              <View style={styles.zeroStateToday}>
                <Text style={styles.zeroStateTodayText}>
                  All caught up for today! 🎉
                </Text>
                <Text style={styles.zeroStateSubText}>
                  Check back later for upcoming tasks
                </Text>
              </View>
            )}
            {day.fosters.map((foster) => (
              <DashboardEventCard
                foster={foster}
                isOverdue={day.isOverdue}
                key={`${foster.fosterId}-${startOfDay(day.dueDate).getTime()}`}
                showUserInfo={showUserInfo}
              />
            ))}
          </View>
        </View>
      </View>
    )
  }
)

DayItem.displayName = 'DayItem'

const DashboardTab = () => {
  const { styles, theme } = useStyles(stylesheet)
  const [includeEveryone, setIncludeEveryone] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const { isUserAdmin } = useUserRoles()

  Notifications.setBadgeCountAsync(-1)

  const navigation = useNavigation()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const currentUserQuery = trpc.user.current.useQuery(
    {
      organizationId: currentOrganization?.id,
    },
    {
      enabled: !!currentOrganization,
    }
  )

  const events = trpc.dashboard.events.useQuery(
    {
      organizationId: currentOrganization?.id || '',
      includeEveryone,
    },
    {
      enabled: !!currentOrganization,
    }
  )

  useRefetchOnFocus(events.refetch)

  const eventsByDayAndFoster = events.data

  useEffect(() => {
    if (!currentOrganization) {
      return
    }

    navigation.setOptions({
      title: currentOrganization.name,
    })
  }, [navigation, currentOrganization])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)
    await events.refetch()
    setRefreshing(false)
  }, [events])

  const isCurrentMonth = useCallback(
    (date: Date) => isSameMonth(date, new Date()),
    []
  )

  const getItem = useCallback(
    (data: DashboardDay[], index: number) => data[index],
    []
  )
  const getItemCount = useCallback(
    (data: DashboardDay[]) => data?.length || 0,
    []
  )
  const keyExtractor = useCallback(
    (item: DashboardDay) =>
      `${item.isOverdue}-${startOfDay(item.dueDate).getTime()}`,
    []
  )

  const renderItem = useCallback(
    ({ item: day, index }: { item: DashboardDay; index: number }) => (
      <DayItem
        day={day}
        dayIndex={index}
        eventsByDayAndFoster={eventsByDayAndFoster || []}
        isCurrentMonth={isCurrentMonth}
        showUserInfo={includeEveryone}
      />
    ),
    [isCurrentMonth, includeEveryone, eventsByDayAndFoster]
  )

  const renderScrollComponent = useCallback(
    (props: any) => (
      <KeyboardAwareScrollView
        {...props}
        contentInsetAdjustmentBehavior="automatic"
        refreshControl={
          <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
        }
      />
    ),
    [refreshing, onRefresh]
  )

  return (
    <View style={styles.container}>
      {events.isLoading || currentUserQuery.isLoading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={theme.colors.primary} size="large" />
        </View>
      ) : (
        <>
          <BillingMessage requiresSubscription={true} />
          {isUserAdmin && (
            <SegmentedButtons
              buttons={[
                {
                  icon: 'account',
                  value: 'justme',
                  label: 'My Fosters',
                },
                {
                  icon: 'account-group',
                  value: 'everyone',
                  label: 'All Fosters',
                },
              ]}
              onValueChange={(value) => {
                setIncludeEveryone(value === 'everyone')
              }}
              style={styles.segmentedButtons}
              value={includeEveryone ? 'everyone' : 'justme'}
            />
          )}
          <VirtualizedList
            data={eventsByDayAndFoster}
            getItem={getItem}
            getItemCount={getItemCount}
            initialNumToRender={5}
            keyExtractor={keyExtractor}
            maxToRenderPerBatch={5}
            renderItem={renderItem}
            renderScrollComponent={renderScrollComponent}
            style={styles.list}
            windowSize={4}
          />
        </>
      )}
    </View>
  )
}

DashboardTab.displayName = 'DashboardTab'

export default DashboardTab

const stylesheet = createStyleSheet((theme) => {
  return {
    cardsContainer: {
      flex: 1,
      gap: theme.tokens.spacing[3],
      paddingRight: theme.tokens.spacing[2],
    },
    container: {
      flex: 1,
      width: '100%',
    },
    currentMonthLabel: {
      color: theme.colors.primary,
      fontSize: 12,
      fontWeight: '500',
      marginLeft: theme.tokens.spacing[2],
    },
    dayContainer: {
      marginBottom: theme.tokens.spacing[4],
      width: '100%',
    },
    eventsContainer: {
      flexDirection: 'row',
      flex: 1,
      paddingHorizontal: theme.tokens.spacing[2],
    },
    list: {
      flex: 1,
      marginTop: theme.tokens.spacing[4],
      paddingLeft: theme.tokens.spacing[2],
      paddingRight: theme.tokens.spacing[2],
      width: '100%',
    },
    loadingContainer: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    monthDivider: {
      backgroundColor: theme.colors.secondary,
      flex: 1,
      height: 1,
      opacity: 0.3,
    },
    monthLabel: {
      color: theme.colors.secondary,
      fontSize: 16,
      fontWeight: Platform.select({
        ios: '600',
        android: '700',
      }),
      textTransform: 'uppercase',
    },
    monthLabelContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      gap: theme.tokens.spacing[4],
      justifyContent: 'flex-start',
      marginBottom: theme.tokens.spacing[4],
      marginTop: theme.tokens.spacing[4],
      paddingHorizontal: theme.tokens.spacing[4],
    },
    segmentedButtons: {
      alignSelf: 'flex-end',
      backgroundColor: theme.colors.surface,
      borderRadius: 20,
      marginBottom: theme.tokens.spacing[3],
      marginRight: theme.tokens.spacing[4],
      marginTop: theme.tokens.spacing[4],
      width: 250,
    },
    zeroStateSubText: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 14,
      opacity: 0.8,
    },
    zeroStateToday: {
      alignItems: 'center',
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.cardBorder,
      borderRadius: theme.tokens.containerBorderRadius,
      borderWidth: 1,
      flexDirection: 'column',
      gap: theme.tokens.spacing[2],
      justifyContent: 'center',
      marginLeft: theme.tokens.spacing[3],
      marginRight: theme.tokens.spacing[1],
      padding: theme.tokens.spacing[4],
    },
    zeroStateTodayText: {
      color: theme.colors.onSurfaceVariant,
      fontSize: 16,
      fontWeight: '500',
    },
  }
})
