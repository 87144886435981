import { Image, ImageStyle } from 'expo-image'
import { memo } from 'react'
import { StyleProp } from 'react-native'
import Species from '../../enums/species'

interface Props {
  blurhash: string | null | undefined
  thumbUri: string | null | undefined
  species: Species
  style: StyleProp<ImageStyle>
  transition?: number
}

const FosterThumbnail = ({
  blurhash,
  thumbUri,
  species,
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transition,
}: Props) => {
  const thumbnail = blurhash
    ? thumbUri
    : species === 'Cat'
      ? require('../../../assets/cat.png')
      : require('../../../assets/dog.png')

  return (
    <Image
      accessibilityIgnoresInvertColors={true}
      cachePolicy={'disk'}
      contentFit="cover"
      key={thumbUri}
      placeholder={{ blurhash: blurhash || null }}
      placeholderContentFit="cover"
      recyclingKey={thumbUri}
      source={thumbnail}
      style={style}
      testID="foster-image"
      // disabled for now due to a bug that blanks out images during a transition. will re-enable when fixed.
      // transition={transition || 0}
    />
  )
}

export default memo(FosterThumbnail)
