import { zodResolver } from '@hookform/resolvers/zod'
import * as Notifications from 'expo-notifications'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Alert,
  Linking,
  Pressable,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'
import { Button, ButtonProps, Switch, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import UserService from '../../../../services/userService'
import logger from '../../../../utils/datadog/log/log'
import trpc from '../../../../utils/trpc'
import NotFound from '../../../shared/NotFound'

interface Props {
  buttonStyle?: StyleProp<ViewStyle>
  buttonMode?: ButtonProps['mode']
  formContainerStyle: StyleProp<ViewStyle>
  titleStyle: StyleProp<TextStyle>
}

const UserNotifications = ({
  buttonStyle,
  buttonMode,
  formContainerStyle,
  titleStyle,
}: Props) => {
  const { styles } = useStyles(stylesheet)
  const { user, isLoading } = UserService.useCurrentUser()
  const updateSettingsMutation = trpc.user.updateSettings.useMutation()
  const [hasPermission, setHasPermission] = useState<boolean>(false)

  const defaultValues = { ...user }

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = form

  useEffect(() => {
    reset(user)
  }, [reset, user])

  useEffect(() => {
    const checkNotificationPermissions = async () => {
      const { status } = await Notifications.getPermissionsAsync()
      setHasPermission(status === 'granted')
    }
    checkNotificationPermissions()
  }, [])

  const onSubmit = async (data: FormData) => {
    updateSettingsMutation.mutate(
      {
        ...data,
        userId: user?.id || '',
      },
      {
        onSuccess: (updatedData) => {
          reset({ ...updatedData })
        },
      }
    )
  }

  const handlePushNotificationToggle = async (value: boolean) => {
    if (value && !hasPermission) {
      const { status } = await Notifications.requestPermissionsAsync()
      if (status !== 'granted') {
        logger.error('UserNotifications', {
          message:
            'Push notification permission denied, attempting to redirect user to app settings',
        })
        Alert.alert(
          'Permission Required',
          'Push notifications are disabled. Would you like to open settings to enable them?',
          [
            {
              text: 'Cancel',
              style: 'cancel',
            },
            {
              text: 'Open Settings',
              onPress: Linking.openSettings,
            },
          ]
        )
        return
      }
      setHasPermission(true)
    }
    setValue('isPushNotificationsEnabled', value, { shouldDirty: true })
  }

  if (!isLoading && !user) return <NotFound />

  return (
    <View>
      <Text style={titleStyle} variant="titleLarge">
        Notification Preferences
      </Text>
      <Text style={styles.description} variant="bodyMedium">
        Stay informed about your fosters' health needs including vaccination
        schedules, medication reminders, and medical test results.
      </Text>
      <View style={[formContainerStyle, styles.formContainer]}>
        <Pressable
          accessibilityRole="button"
          disabled={isLoading}
          onPress={() =>
            setValue(
              'isEmailNotificationsEnabled',
              !watch('isEmailNotificationsEnabled'),
              { shouldDirty: true }
            )
          }
          style={styles.settingContainer}
        >
          <View style={styles.settingTextContainer}>
            <Text variant="bodyLarge">Email Notifications</Text>
          </View>
          <Switch
            disabled={isLoading}
            onValueChange={(value) =>
              setValue('isEmailNotificationsEnabled', value, {
                shouldDirty: true,
              })
            }
            value={watch('isEmailNotificationsEnabled')}
          />
        </Pressable>
        <Pressable
          accessibilityRole="button"
          disabled={isLoading}
          onPress={() =>
            handlePushNotificationToggle(!watch('isPushNotificationsEnabled'))
          }
          style={styles.settingContainer}
        >
          <View style={styles.settingTextContainer}>
            <Text variant="bodyLarge">Push Notifications</Text>
          </View>
          <Switch
            disabled={isLoading}
            onValueChange={handlePushNotificationToggle}
            value={watch('isPushNotificationsEnabled') && hasPermission}
          />
        </Pressable>
        <Pressable
          accessibilityRole="button"
          disabled={isLoading}
          onPress={() =>
            setValue(
              'isAdoptionEmailEnabled',
              !watch('isAdoptionEmailEnabled'),
              { shouldDirty: true }
            )
          }
          style={styles.settingContainer}
        >
          <View style={styles.settingTextContainer}>
            <Text variant="bodyLarge">Weekly Adoption Reports</Text>
            <Text style={styles.helperText} variant="bodySmall">
              Available exclusively for organizations where you have admin
              privileges
            </Text>
          </View>
          <Switch
            disabled={isLoading}
            onValueChange={(value) =>
              setValue('isAdoptionEmailEnabled', value, {
                shouldDirty: true,
              })
            }
            value={watch('isAdoptionEmailEnabled')}
          />
        </Pressable>
      </View>
      <Button
        disabled={isSubmitting || !isDirty}
        mode={buttonMode}
        onPress={handleSubmit(onSubmit)}
        style={buttonStyle}
      >
        Save
      </Button>
    </View>
  )
}

const formSchema = z.object({
  id: z.string().uuid(),
  isAdoptionEmailEnabled: z.boolean().default(false),
  isEmailNotificationsEnabled: z.boolean().default(true),
  isPushNotificationsEnabled: z.boolean().default(true),
})

type FormData = z.infer<typeof formSchema>

const stylesheet = createStyleSheet((theme) => ({
  description: {
    color: theme.colors.subtitle,
    marginBottom: theme.tokens.spacing[4],
    marginTop: theme.tokens.spacing[2],
  },
  formContainer: {
    flexDirection: 'column',
  },
  helperText: {
    color: theme.colors.subtitle,
    marginTop: theme.tokens.spacing[1],
  },
  settingContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: theme.tokens.spacing[2],
    paddingVertical: theme.tokens.spacing[2],
  },
  settingTextContainer: {
    paddingRight: theme.tokens.spacing[4],
  },
}))

export default UserNotifications
