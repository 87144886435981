import { zodResolver } from '@hookform/resolvers/zod'
import { router, useNavigation } from 'expo-router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import {
  ActivityIndicator,
  IconButton,
  Surface,
  Text,
} from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { z } from 'zod'
import FormFieldText from '../../../components/form/FormFieldText'
import UserAvatar from '../../../components/navigation/UserAvatar'
import Card from '../../../components/shared/Card'
import Page from '../../../components/shared/Page'
import useAutoSave from '../../../hooks/useAutoSave'
import { useAppStore } from '../../../store/useAppStore'
import trpc from '../../../utils/trpc'

const formSchema = z.object({
  email: z.string().email().toLowerCase(),
})

const UserInvite = () => {
  const { styles } = useStyles(stylesheet)
  const navigation = useNavigation()

  const [email, setEmail] = useState('')
  const [isInvited, setIsInvited] = useState(false)

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const inviteMutation = trpc.user.invite.useMutation()

  useEffect(() => {
    setIsInvited(false)
  }, [email])

  const defaultValues = {
    email: '',
  }

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form

  useAutoSave({
    defaultValues,
    form,
    interval: 250,
    onSave: handleSubmit((data) => {
      setEmail(data.email)
    }),
  })

  useEffect(() => {
    navigation.setOptions({
      title: 'Invite User',
    })
  }, [navigation])

  useEffect(() => {
    if (email === '') {
      return
    }

    reset({
      email: email,
    })
  }, [email, reset])

  const isEnabled =
    email.length > 0 && !errors.email && !!currentOrganization?.id

  const {
    data: user,
    isLoading: isUserLoading,
    isFetched: isUserFetched,
  } = trpc.user.emailSearch.useQuery(
    {
      email,
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: isEnabled,
    }
  )

  const {
    data: pendingInvite,
    isLoading: isInviteLoading,
    isFetched: isInviteFetched,
  } = trpc.user.checkInvite.useQuery(
    {
      email,
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: isEnabled,
    }
  )

  const isInOrganization = user?.organizations.some(
    (x) =>
      x.deactivatedAt === null && x.organizationId === currentOrganization?.id
  )

  const isLoading = isUserLoading || isInviteLoading
  const isFetched = isUserFetched && isInviteFetched

  return (
    <Page viewId="user-invite">
      <Card title="Invite User">
        <Text style={styles.helperText} variant="bodyMedium">
          Enter an email address to invite someone to your organization. If they
          already have a Rescuebase account, they'll be added immediately. If
          not, they'll receive an email invitation to join.
        </Text>
        <FormFieldText
          autoCapitalize="none"
          control={control}
          error={!!errors.email}
          errors={errors}
          fieldName="email"
          inputMode="email"
          label="Email"
          required
        />
      </Card>
      {isLoading && !isFetched && isEnabled && <ActivityIndicator />}
      {!isLoading && user && isInOrganization && (
        <Surface style={styles.resultCard}>
          <View style={styles.resultCardContent}>
            <UserAvatar size={40} user={user} />
            <View style={styles.resultCardTextContainer}>
              <View style={styles.resultCardName}>
                <Text>{user.firstName}</Text>
                <Text>{user.lastName}</Text>
              </View>
              <Text style={styles.resultCardEmail}>{user.email}</Text>
              <Text> </Text>
              <Text>This user is already a member of this organization.</Text>
            </View>
            <IconButton
              icon="arrow-right"
              onPress={() => {
                router.push(`/users/${user.id}`)
              }}
              selected
            />
          </View>
        </Surface>
      )}
      {!isLoading && pendingInvite && (
        <Surface style={styles.resultCard}>
          <View style={styles.resultCardContent}>
            <View style={styles.resultCardTextContainer}>
              <Text>
                This email has already been invited by{' '}
                {pendingInvite.invitedBy.firstName}{' '}
                {pendingInvite.invitedBy.lastName}
              </Text>
              <Text style={styles.subtitle}>
                Invite expires{' '}
                {new Date(pendingInvite.expiresAt).toLocaleDateString()}
              </Text>
            </View>
            <IconButton icon="clock" selected />
          </View>
        </Surface>
      )}
      {!isLoading && user && !isInOrganization && !pendingInvite && (
        <Surface style={styles.resultCard}>
          <View style={styles.resultCardContent}>
            <UserAvatar size={40} user={user} />
            <View style={styles.resultCardTextContainer}>
              <View style={styles.resultCardName}>
                <Text>{user.firstName}</Text>
                <Text>{user.lastName}</Text>
              </View>
              <Text style={styles.resultCardEmail}>{user.email}</Text>
            </View>
            {!isInvited ? (
              inviteMutation.isLoading ? (
                <ActivityIndicator size={24} />
              ) : (
                <IconButton
                  disabled={inviteMutation.isLoading}
                  icon="account-plus"
                  onPress={() => {
                    inviteMutation.mutate(
                      {
                        email: user.email,
                        organizationId: currentOrganization?.id || '',
                        userId: user.id,
                      },
                      {
                        onSuccess: () => {
                          setIsInvited(true)
                        },
                      }
                    )
                  }}
                  selected
                />
              )
            ) : (
              <IconButton icon="check" selected />
            )}
          </View>
        </Surface>
      )}
      {isFetched &&
        !isLoading &&
        !errors.email &&
        !user &&
        !isInOrganization &&
        !pendingInvite && (
          <Surface style={styles.resultCard}>
            <View style={styles.resultCardContent}>
              <View style={styles.resultCardTextContainer}>
                <Text>
                  {isInvited ? 'Invite sent to ' : 'Send an invite to '}
                  <Text style={{ fontWeight: 'bold' }}>
                    {form.getValues('email')}
                  </Text>
                </Text>
              </View>
              {isInvited ? (
                <IconButton icon="check" selected />
              ) : inviteMutation.isLoading ? (
                <ActivityIndicator size={24} />
              ) : (
                <IconButton
                  disabled={inviteMutation.isLoading}
                  icon="send"
                  onPress={() => {
                    inviteMutation.mutate(
                      {
                        email: email,
                        organizationId: currentOrganization?.id || '',
                      },
                      {
                        onSuccess: () => {
                          setIsInvited(true)
                        },
                      }
                    )
                  }}
                  selected
                />
              )}
            </View>
          </Surface>
        )}
    </Page>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  helperText: {
    color: theme.colors.onSurfaceVariant,
    marginBottom: theme.tokens.spacing[4],
  },

  resultCard: {
    backgroundColor: theme.colors.surface,
    borderRadius: theme.tokens.containerBorderRadius,
    flex: 1,
    padding: theme.tokens.spacing[4],
  },
  resultCardContent: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    gap: theme.tokens.spacing[4],
  },
  resultCardEmail: {
    color: theme.colors.subtitle,
  },
  resultCardName: {
    flexDirection: 'row',
    gap: theme.tokens.spacing[2],
  },
  resultCardTextContainer: {
    flex: 1,
  },
  subtitle: {
    color: theme.colors.onSurfaceVariant,
    marginTop: theme.tokens.spacing[1],
  },
}))

export default UserInvite
