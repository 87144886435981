import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  Path,
} from 'react-hook-form'
import { DropdownProps } from 'react-native-element-dropdown/lib/typescript/components/Dropdown/model'
import Species from '../../../../enums/species'
import { useAppStore } from '../../../../store/useAppStore'
import trpc from '../../../../utils/trpc'
import FormFieldSelect from '../../../form/FormFieldSelect'

type data = { label: string; value: string }

interface Props<C extends FieldValues, _E extends FieldErrors> {
  control: Control<C, any>
  disabled: boolean
  formState: FormState<C>
  label?: string
  isLoading?: boolean
  fieldName: Path<C>
  species?: Species
}

const FormFieldOrganizationVaccines = <
  C extends FieldValues,
  E extends FieldErrors,
>({
  control,
  disabled,
  formState,
  isLoading,
  fieldName,
  species,
  ...props
}: Props<C, E> & Partial<DropdownProps<data>>) => {
  const currentOrganization = useAppStore.use.currentOrganization().organization

  const vaccineListQuery = trpc.vaccine.list.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  if (!vaccineListQuery.isSuccess) {
    return null
  }

  const vaccineList =
    vaccineListQuery?.data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.filter((vaccine) => !species || vaccine.species.includes(species))
      ?.map((vaccine) => ({
        label: vaccine.name,
        value: vaccine.id,
      })) || []

  return (
    <FormFieldSelect
      {...props}
      autoScroll
      control={control}
      data={vaccineList}
      disable={disabled}
      errors={formState.errors}
      fieldName={fieldName}
      isLoading={isLoading}
      label={props.label || 'Vaccine'}
      required={false}
      search={true}
      searchField="label"
      searchPlaceholder="Search for a vaccine"
    />
  )
}

export default FormFieldOrganizationVaccines
